/* eslint-disable no-unused-vars */
import { Grid, Typography, Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { StyledFormContainer } from "../../billingPlans/styles/BillingPlansStyles.js";
import PropTypes from "prop-types";
import {
  StyledAddMoreButton,
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
} from "../../../../styledComponents.js";
import { sensorType } from "../../../../utils/constant.js";
import { iconMap } from "../../../../constants/iconsList.js";
import FormField from "../../../common/FormField.js";
import FormSelect from "../../../common/FormSelect.js";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { fetchSensorsByMultipleCriteria } from "../../../../services/sensor.service.js";
import SensorsFormModal from "../../../modals/SensorsFormModal.js";

const UnitsForm4 = ({ unit, onFormValueChange, validationErrors }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [sensors, setSensors] = useState([]);
  const [page] = useState(1);
  const [count, setCount] = useState(0);
  const [limit] = useState(15);
  const [sensorsFormModal, setSensorsFormModal] = useState(false);

  const handleVehicleSensorChange = (fieldName, fieldValue, index) => {
    const updatedSensors = [...unit.vehicle.sensors]; // Create a fresh copy of sensors

    if (fieldName === "vehicle.sensors.sensorType") {
      updatedSensors[index].sensorType = fieldValue;
    } else if (fieldName === "vehicle.sensors.sensorId") {
      updatedSensors[index].sensorId = fieldValue;
    }

    console.log(`Updated sensor at index ${index}:`, updatedSensors[index]); // Log updated sensor

    const updatedUnit = {
      ...unit,
      vehicle: {
        ...unit.vehicle,
        sensors: updatedSensors, // Assign updated sensors array
      },
    };

    onFormValueChange(updatedUnit, fieldName, fieldValue, 4);
  };

  const addSensor = () => {
    let updatedUnit = { ...unit };
    updatedUnit.vehicle.sensors.push({
      sensorType: "",
      sensorId: "",
    });
    onFormValueChange(
      updatedUnit,
      "vehicle.sensors",
      updatedUnit.vehicle.sensors,
      4
    );
  };

  useEffect(() => {
    _fetchSensorsByMultipleCriteria();
  }, [page, limit]);

  const _fetchSensorsByMultipleCriteria = async (
    name = "",
    sensorType = "",
    sensorModel = "",
    installedLocation = "",
    page = 1,
    limit = 15
  ) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      let allSensors = [];
      let currentPage = page;
      let totalFetched = 0;

      let response;
      do {
        response = await fetchSensorsByMultipleCriteria(
          name,
          sensorType,
          sensorModel,
          installedLocation,
          currentPage,
          limit
        );
        allSensors = [...allSensors, ...response.rows];
        totalFetched += response.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < response.count);

      console.log("ravisk", sensors);
      setSensors(allSensors);
    } catch (error) {
      console.error(
        "Error fetching sensors:",
        error?.message || "An unknown error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const removeSensor = (index) => {
    // Create a deep copy of consignment to avoid mutating the original object
    let updatedUnit = JSON.parse(JSON.stringify(unit));

    // Remove the item at the specified index
    updatedUnit.vehicle.sensors.splice(index, 1);

    // Call the onFormValueChange function with the updated values
    onFormValueChange(
      updatedUnit,
      "vehicle.sensors",
      updatedUnit.vehicle.sensors,
      4
    );
  };

  // Handler to open the Sensor form modal
  const handleSensorsFormModalOpen = useCallback(() => {
    setSensorsFormModal(true);
  }, []);

  // Handler to close the Sensor form modal and fetch customers
  const handleSensorsFormModalClose = useCallback(() => {
    setSensorsFormModal(false);
    _fetchSensorsByMultipleCriteria(); // Assuming this is applicable for sensors as well
  }, []);

  return (
    <StyledFormContainer>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Sensors</Typography>
        </StyledSectionLeft>

        <StyledSectionRight>
          {unit.vehicle.sensors.map((sensor, index) => (
            <Grid key={index} sx={{ borderBottom: "1px solid #d8d8df" }}>
              {unit.vehicle.sensors.length > 1 && (
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "end",
                    marginTop: "30px",
                  }}
                >
                  <DeleteIcon
                    onClick={() => removeSensor(index)}
                    sx={{ color: "#e71111" }}
                  />
                </Grid>
              )}
              <FormSelect
                label="Sensor Type"
                required
                value={sensor.sensorType}
                onChange={(selected) =>
                  handleVehicleSensorChange(
                    "vehicle.sensors.sensorType",
                    selected,
                    index
                  )
                }
                options={sensorType.map((item) => ({
                  value: item.value,
                  label: item.name,
                }))}
                error={
                  !!validationErrors[`vehicle.sensors[${index}].sensorType`]
                }
                errorMessage={
                  validationErrors[`vehicle.sensors[${index}].sensorType`]
                }
              />
              <FormSelect
                label="Choose Sensor"
                label2="Add Sensor"
                onLabel2Click={handleSensorsFormModalOpen}
                value={sensor.sensorId} // Corrected value to refer to specific sensor
                onChange={(selected) => {
                  handleVehicleSensorChange(
                    "vehicle.sensors.sensorId",
                    selected,
                    index // Pass index here to update the correct sensor
                  );
                }}
                options={sensors.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                error={!!validationErrors[`vehicle.sensors[${index}].sensorId`]}
                errorMessage={
                  validationErrors[`vehicle.sensors[${index}].sensorId`]
                }
              />
            </Grid>
          ))}
          <StyledAddMoreButton
            variant="outlined"
            sx={{ marginTop: "10px" }}
            onClick={addSensor}
          >
            <img src={iconMap["AddMorePlusIcon"]} alt="PlusIcon" />
            ADD MORE
          </StyledAddMoreButton>
        </StyledSectionRight>
      </StyledFormSection>
      <SensorsFormModal
        open={sensorsFormModal}
        handleSensorsFormModalClose={handleSensorsFormModalClose}
      />
    </StyledFormContainer>
  );
};

UnitsForm4.propTypes = {
  unit: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

export default UnitsForm4;

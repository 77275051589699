/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ListHeaderNew from "../../../common/ListHeaderNew";
import { Grid } from "@mui/material";
import ExportButton from "../../../common/ExportButton";
import ViewModeSwitch from "../../../common/ViewModeSwitch";
import UnitsFilters from "../components/UnitsFilters";
import { fetchUnitsByMultipleCriteria } from "../../../../services/unitNew.service";
export default function UnitsPage() {
  // State hooks for data storage
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [units, setUnits] = useState([]);
  const [limit] = useState(15);
  const [filters, setFilters] = useState({
    unitType: "",
    id: "",
    VIN: "",
    licensePlate: "",
    make: "",
    model: "",
    deviceType: "",
    deviceId: "",
  });
  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  const [isEmpty, setIsEmpty] = useState(false); // Add empty state

  // Location-related logic
  const location = useLocation();
  const shouldShowHeader =
    location.pathname.endsWith("units") ||
    location.pathname.endsWith("card") ||
    location.pathname.endsWith("list");

  useEffect(() => {
    _fetchUnitsByMultipleCriteria(
      filters.accountId,
      filters.unitType,
      filters.id,
      filters.VIN,
      filters.licensePlate,
      filters.make,
      filters.model,
      filters.deviceType,
      filters.deviceId,
      page,
      limit
    );
  }, [page, filters]);

  const _fetchUnitsByMultipleCriteria = async (
    accountId = defaultAccountId,
    unitType,
    id,
    VIN,
    licensePlate,
    make,
    model,
    deviceType,
    deviceId,
    page,
    limit
  ) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      const response = await fetchUnitsByMultipleCriteria(
        accountId,
        unitType,
        id,
        VIN,
        licensePlate,
        make,
        model,
        deviceType,
        deviceId,
        page,
        limit
      );
      setCount(response.count);
      setUnits((prevUnits) => [...prevUnits, ...response.rows]);
      setIsEmpty(response.rows.length === 0); // Set empty state
    } catch (error) {
      console.log(error);
      // const errorMessage = error?.error.message || 'An unknown error occurred'
      // console.error('Error fetching consignments:', errorMessage)
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
    setUnits([]);
    setPage(1);
  };

  const fetchMore = () => {
    if (units.length < count) {
      setPage((prevPage) => prevPage + 1); // Increment page number for search results
    } else {
      console.log("All units have been fetched.");
    }
  };

  return (
    <div className="container">
      {shouldShowHeader && (
        <Grid container flexDirection="column">
          <Grid item xs={12}>
            <ListHeaderNew name="Unit" mode="Create" />
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{
              marginTop: "30px",
              marginBottom: "30px",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12}>
              <UnitsFilters onFiltersChange={handleFiltersChange} />{" "}
            </Grid>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <ViewModeSwitch name="Unit" />
              <ExportButton name="Units" accounts={units}></ExportButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Outlet context={{ units, fetchMore, isLoading, isEmpty }} />
    </div>
  );
}

/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Button,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import EmptyState from "../../../common/EmptyState";
import Loader from "../../../common/Loader";
import {
  formatDate,
  formatTime,
  formatToLocal,
} from "../../../../utils/functions";
import { StyledTableCell, StyledTableHead } from "../../../../styledComponents";
import { reporDrivertTableColumns } from "../../../../utils/constant";
import { useOutletContext } from "react-router-dom";
import debounce from "lodash.debounce";

const DriverRunSheet = ({ data }) => {
  const today = new Date();
  const formattedDateTime = `${formatDate(today)}, ${formatTime(today)}`; // Format as MM/DD/YYYY
  const { driverRunSheetReports, fetchMore, isLoading, hasMoreData } =
    useOutletContext();
  const containerRef = useRef(null);
  const [shouldReload, setShouldReload] = useState(false);

  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  useEffect(() => {
    if (shouldReload) {
      // getAccounts();
      window.location.reload();
    }
  }, [shouldReload]);

  // Attach the scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    // Remove the event listener on cleanup
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [driverRunSheetReports, isLoading, handleScroll]);

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ overflow: "auto", maxWidth: "75rem" }}
      >
        {isLoading && <Loader />}
        <Table aria-label="device reports table">
          <StyledTableHead>
            <TableRow>
              {reporDrivertTableColumns.map((column) => (
                <StyledTableCell key={column}>
                  <Typography variant="tableHeader">{column}</Typography>
                </StyledTableCell>
              ))}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {driverRunSheetReports?.map((consignment) => (
              <TableRow key={consignment.id} sx={{ cursor: "pointer" }}>
                <StyledTableCell component="th" scope="row">
                  <Typography variant="tableValue">
                    {formattedDateTime}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  <Typography variant="tableValue">
                    {consignment.driverName ? consignment.driverName : "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {consignment.unitId ? consignment.unitId : "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {consignment.accountName ? consignment.accountName : "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {consignment.start ? consignment.start : "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {consignment.finish ? consignment.finish : "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {consignment.total ? consignment.total : "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {consignment?.orderNumber ?? "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {consignment?.customerName ?? "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {consignment?.deliveryDetails?.address?.addressId ||
                  consignment?.deliveryDetails?.address?.geofenceName ? (
                    <Typography variant="tableValue">
                      {consignment?.deliveryDetails?.address?.addressId ??
                        consignment?.deliveryDetails?.address?.geofenceName}
                    </Typography>
                  ) : (
                    <>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="tableValue">
                          {consignment?.deliveryDetails?.address?.streetOne ||
                            "--"}
                        </Typography>
                        <Typography variant="tableValue">
                          {[
                            consignment?.deliveryDetails?.address?.city,
                            consignment?.deliveryDetails?.address?.state,
                          ]
                            .filter((part) => part)
                            .join(", ") || "--"}
                        </Typography>
                        <Typography variant="tableValue">
                          {[
                            consignment?.deliveryDetails?.address?.country,
                            consignment?.deliveryDetails?.address?.postalCode,
                          ]
                            .filter((part) => part) // Ensure no null/undefined values are included
                            .join(", ") || "--"}
                        </Typography>
                      </div>
                    </>
                  )}
                </StyledTableCell>

                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {consignment?.pickupDateTime
                      ? `${formatDate(
                          consignment.pickupDateTime
                        )}, ${formatTime(consignment.pickupDateTime)}`
                      : "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {consignment?.loadingCompletedDateTime
                      ? `${formatDate(
                          consignment.loadingCompletedDateTime
                        )}, ${formatTime(consignment.loadingCompletedDateTime)}`
                      : "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {consignment?.deliveredDateTime
                      ? `${formatDate(
                          consignment.deliveredDateTime
                        )}, ${formatTime(consignment.deliveredDateTime)}`
                      : "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {consignment?.tripStatus ?? "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {consignment?.deliveryDetails?.name ?? "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="tableValue">Break 1</Typography>
                    <Typography variant="tableValue">
                      {consignment?.startBreakOne ?? "--"} -{" "}
                      {consignment?.endBreakOne ?? "--"}
                    </Typography>
                    <Typography variant="tableValue">Break 2</Typography>
                    <Typography variant="tableValue">
                      {consignment?.startBreakTwo ?? "--"} -{" "}
                      {consignment?.endBreakOne ?? "--"}
                    </Typography>
                    <Typography variant="tableValue">Break 3</Typography>
                    <Typography variant="tableValue">
                      {consignment?.startBreakThree ?? "--"} -{" "}
                      {consignment?.endBreakOne ?? "--"}
                    </Typography>
                  </div>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

DriverRunSheet.propTypes = {
  data: PropTypes.array.isRequired,
};

// DriverRunSheet.propTypes = {
//   data: PropTypes.array.isRequired,
// };

export default DriverRunSheet;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import SearchFormSelect from "../../../common/SearchFormSelect";
import FilterListIcon from "@mui/icons-material/FilterList";
// import { reportTypes } from "../../../../utils/constant";
import {
  fetchAccountsByMultipleCriteria,
  fetchAllAccounts,
} from "../../../../services/accountNew.service";
import SelectedDateRange from "../../../common/SelectedDateRange";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // if using UTC
import {
  fetchAllDevices,
  fetchDevicesByMultipleCriteria,
} from "../../../../services/deviceNew.services";
import {
  fetchUsersByMultipleCriteria,
  fetchUsersByRoleNameAndAccountId,
} from "../../../../services/userNew.service";
// import FormSelect from "../../../common/FormSelect";
import { useOutletContext } from "react-router-dom";
import { fetchUnitsByMultipleCriteria } from "../../../../services/unitNew.service";
import FormTimepicker from "../../../common/FormTimepicker";
dayjs.extend(utc);

const ReportsFilters = ({
  filters,
  onFiltersChange,
  reportType,
  setReportType,
}) => {
  const [accounts, setAccounts] = useState([]);
  const [devices, setDevices] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [units, setUnits] = useState([]);

  const [pageAccounts, setPageAccounts] = useState(1);
  const [pageDevices, setPageDevices] = useState(1);
  const [pageDrivers, setPageDrivers] = useState(1);
  const [limit] = useState(15);
  const [count, setCount] = useState(0);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const [isLoadingDrivers, setIsLoadingDrivers] = useState(false);
  const [isLoadingUnits, setIsLoadingUnits] = useState(false);

  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  const roleName = "Driver";

  useEffect(() => {
    // Fetch all accounts when the component mounts
    _fetchAccountsByMultipleCriteria();

    // Update filters with the default account ID
    const updatedFilters = { ...filters, accountId: defaultAccountId };
    onFiltersChange(updatedFilters); // Update filters in parent component

    if (defaultAccountId) {
      // Fetch related data based on the default account ID
      _fetchDevicesByMultipleCriteria(defaultAccountId);
      _fetchUnitsByMultipleCriteria(defaultAccountId);
      _fetchUsersByMultipleCriteria(defaultAccountId);
    } else {
      console.warn("No Account ID found. Skipping fetch calls.");
    }
  }, []);

  const _fetchUsersByMultipleCriteria = async (
    accountId = "",
    id,
    roleName = "Driver",
    userName,
    page = 1,
    limit = 15
  ) => {
    if (isLoadingDrivers) return;
    setIsLoadingDrivers(true);

    try {
      const response = await fetchUsersByMultipleCriteria(
        accountId,
        id,
        userName,
        roleName,
        page,
        limit
      );
      setCount(response.count);
      setDrivers((prevUsers) => [...prevUsers, ...response.rows]);
      // setIsEmpty(response.rows.length === 0); // Set empty state
    } catch (error) {
      console.log(error);
      // const errorMessage = error?.error.message || 'An unknown error occurred'
      // console.error('Error fetching consignments:', errorMessage)
    } finally {
      setIsLoadingDrivers(false);
    }
  };

  const _fetchAccountsByMultipleCriteria = async (
    accountId = "",
    billingPlanId = "",
    page = 1,
    limit = 15
  ) => {
    if (isLoadingAccounts) return; // Prevent multiple calls
    setIsLoadingAccounts(true);

    try {
      let allAccounts = [];
      let currentPage = page;
      let totalFetched = 0;

      let response;
      do {
        response = await fetchAccountsByMultipleCriteria(
          accountId,
          billingPlanId,
          currentPage,
          limit
        );
        allAccounts = [...allAccounts, ...response.rows];
        totalFetched += response.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < response.count);

      setAccounts(allAccounts);
    } catch (error) {
      console.error(
        "Error fetching accounts:",
        error?.message || "An unknown error occurred"
      );
    } finally {
      setIsLoadingAccounts(false);
    }
  };

  const _fetchDevicesByMultipleCriteria = async (
    accountId = "",
    deviceType = "",
    id = "",
    make = "",
    model = "",
    page = 1,
    limit = 15
  ) => {
    if (isLoadingDevices) return; // Prevent multiple calls
    setIsLoadingDevices(true);

    try {
      let allDevices = [];
      let currentPage = page;

      let response;
      do {
        response = await fetchDevicesByMultipleCriteria(
          accountId, // Pass accountId here
          deviceType,
          id,
          make,
          model,
          currentPage,
          limit
        );
        allDevices = [...allDevices, ...response.rows];
        currentPage++; // Move to the next page
      } while (response.rows.length > 0);

      setDevices(allDevices);
    } catch (error) {
      console.error(
        "Error fetching devices:",
        error?.message || "An unknown error occurred"
      );
    } finally {
      setIsLoadingDevices(false);
    }
  };

  const handleScrollEndAccounts = () => {
    if (!isLoadingAccounts) {
      setPageAccounts((prev) => prev + 1); // Increment page number for accounts
      _fetchAccountsByMultipleCriteria(); // Fetch more accounts
    }
  };
  const handleScrollEndDevices = () => {
    if (!isLoadingDevices) {
      setPageDevices((prev) => prev + 1); // Increment page number for devices
      _fetchDevicesByMultipleCriteria(); // Fetch more devices
    }
  };
  const handleScrollEndDrivers = () => {
    if (!isLoadingDrivers) {
      setPageDrivers((prev) => prev + 1); // Increment page number for drivers
      _fetchUsersByMultipleCriteria(); // Fetch more drivers
    }
  };

  const _fetchUnitsByMultipleCriteria = async (
    accountId = "",
    unitType = "",
    id = "",
    VIN = "",
    licensePlate = "",
    make = "",
    model = "",
    deviceType = "",
    deviceId = "",
    page = 1,
    limit = 15
  ) => {
    if (isLoadingUnits) return; // Prevent multiple calls
    setIsLoadingUnits(true);

    try {
      let allUnits = [];
      let currentPage = page;

      let response;
      do {
        response = await fetchUnitsByMultipleCriteria(
          accountId,
          unitType,
          id,
          VIN,
          licensePlate,
          make,
          model,
          deviceType,
          deviceId,
          currentPage,
          limit
        );
        allUnits = [...allUnits, ...response.rows];
        currentPage++; // Move to the next page
      } while (response.rows.length > 0);

      setUnits(allUnits);
    } catch (error) {
      console.error(
        "Error fetching units:",
        error?.message || "An unknown error occurred"
      );
    } finally {
      setIsLoadingUnits(false);
    }
  };
  // Filter units based on unitType
  const vehicleUnits = units.filter((item) => item.unitType === "Vehicle");
  const containerUnits = units.filter((item) => item.unitType === "Container");

  // const handleReportTypeChange = (event) => {
  //   const selectedType = event.target.value;
  //   updateReportType(selectedType); // Update context with selected report type
  //   onFiltersChange({ ...filters, reportType: selectedType }); // Pass report type to parent
  // };

  const handleFieldChange = (field, value) => {
    let formattedValue = value;

    if (field === "accountId") {
      onFiltersChange({
        ...filters,
        accountId: formattedValue,
      });

      // Fetch devices, units, and users based on selected account ID
      _fetchDevicesByMultipleCriteria(formattedValue);
      _fetchUnitsByMultipleCriteria(formattedValue);
      _fetchUsersByMultipleCriteria(formattedValue); // Fetch users for the selected account
    } else if (field === "dateRange") {
      const { startDate, endDate } = value;
      onFiltersChange({
        ...filters,
        startDate: startDate ? dayjs(startDate).utc().toISOString() : null,
        endDate: endDate ? dayjs(endDate).utc().toISOString() : null,
      });
    } else if (field === "startTime" || field === "endTime") {
      formattedValue = value ? value.format("HH:mm") : null; // Format as HH:mm

      onFiltersChange({
        ...filters,
        [field]: formattedValue,
      });

      // Combine date and time for filtering only if value is valid
      if (field === "startTime" && filters.startDate) {
        if (value) {
          const combinedStartDateTime = dayjs(filters.startDate)
            .set("hour", value.hour())
            .set("minute", value.minute())
            .toISOString();
          onFiltersChange({
            ...filters,
            startDateTime: combinedStartDateTime,
          });
        } else {
          onFiltersChange({
            ...filters,
            startDateTime: null,
          });
        }
      } else if (field === "endTime" && filters.endDate) {
        if (value) {
          const combinedEndDateTime = dayjs(filters.endDate)
            .set("hour", value.hour())
            .set("minute", value.minute())
            .toISOString();
          onFiltersChange({
            ...filters,
            endDateTime: combinedEndDateTime,
          });
        } else {
          onFiltersChange({
            ...filters,
            endDateTime: null,
          });
        }
      }
    } else {
      onFiltersChange({
        ...filters,
        [field]: formattedValue,
      });
    }
  };

  return (
    <>
      <Grid container spacing={1} sx={{ display: "flex" }}>
        <Grid item xs={12} sm={1} sx={{ display: "flex" }}>
          <Grid
            sx={{ marginTop: "20px", color: "#6C6C81", fontWeight: "600" }}
            mr={1}
          >
            FILTERS
          </Grid>
          <FilterListIcon sx={{ marginTop: "20px" }} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SearchFormSelect
            placeholder="Account"
            value={filters.accountId}
            onChange={(selected) => handleFieldChange("accountId", selected)}
            options={accounts.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            onScrollEnd={handleScrollEndAccounts} // Pass scroll end handler for accounts
          />
        </Grid>
        {reportType === "Driver Run Sheets" && (
          <Grid item xs={12} sm={2}>
            <SearchFormSelect
              placeholder="Driver"
              value={filters.driverId}
              onChange={(selected) => handleFieldChange("driverId", selected)}
              options={drivers.map((item) => ({
                value: item.id,
                label: `${item.firstName} ${item.lastName}`,
              }))}
              onScrollEnd={handleScrollEndDrivers} // Pass scroll end handler for drivers
            />
          </Grid>
        )}

        {(reportType === "Monitoring Report" ||
          reportType === "Events Report" ||
          reportType === "Environmental Report") && (
          <>
            <Grid item xs={12} sm={2}>
              <SearchFormSelect
                placeholder="Device"
                value={filters.deviceId}
                onChange={(selected) =>
                  handleFieldChange("deviceId", String(selected))
                } // Explicitly set as string
                options={devices.map((item) => ({
                  value: item.name,
                  label: item.name,
                }))}
                onScrollEnd={handleScrollEndDevices} // Pass scroll end handler for devices
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <SearchFormSelect
                placeholder="Unit"
                value={filters.unitId}
                onChange={(selected) => {
                  handleFieldChange("unitId", selected);
                }}
                options={units.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={2.3}>
          <SelectedDateRange
            value={[
              filters.startDate ? new Date(filters.startDate) : null,
              filters.endDate ? new Date(filters.endDate) : null,
            ]}
            onChange={(dates) => handleFieldChange("dateRange", dates)}
            error={false}
            errorMessage=""
            placeholder="Date Range"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormTimepicker
            value={filters.startTime ? dayjs(filters.startTime, "HH:mm") : null}
            onChange={(time) => handleFieldChange("startTime", time)}
            // readOnly={!filters.startDate || !filters.endDate}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormTimepicker
            value={filters.endTime ? dayjs(filters.endTime, "HH:mm") : null}
            onChange={(time) => handleFieldChange("endTime", time)}
            // readOnly={!filters.startDate || !filters.endDate}
          />
        </Grid>
      </Grid>
    </>
  );
};

ReportsFilters.propTypes = {
  filters: PropTypes.shape({
    driverId: PropTypes.string,
    accountId: PropTypes.string,
    deviceId: PropTypes.string,
    unitId: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }).isRequired, // Validate filters object structure and require it
  onFiltersChange: PropTypes.func.isRequired, // Validate that onFiltersChange is a required function prop
  reportType: PropTypes.string.isRequired,
  setReportType: PropTypes.func.isRequired,
};

export default ReportsFilters;

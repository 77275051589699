/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import SearchFormSelect from "../../../common/SearchFormSelect";
import {
  fetchUnitsByAccountId,
  fetchUnitsByMultipleCriteria,
} from "../../../../services/unitNew.service";
import FilterListIcon from "@mui/icons-material/FilterList";
import { unitType } from "../../../../utils/constant";
import { fetchDevicesByDeviceType } from "../../../../services/deviceNew.services";

const UnitsFilters = ({ onFiltersChange }) => {
  const [filters, setFilters] = useState({
    unitType: "",
    id: "",
    VIN: "",
    licensePlate: "",
    make: "",
    model: "",
    deviceType: "",
    deviceId: "",
  });
  const [units, setUnits] = useState([]);
  const [devices, setDevices] = useState([]);
  const [page] = useState(1);
  const [limit] = useState(15);
  const [isLoadingUnits, setIsLoadingUnits] = useState(false);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters]);

  useEffect(() => {
    _fetchUnitsByMultipleCriteria();
    _fetchDevicesByDeviceType();
  }, [page, limit]);

  const _fetchUnitsByMultipleCriteria = async (
    accountId = defaultAccountId,
    unitType = "",
    id = "",
    VIN = "",
    licensePlate = "",
    make = "",
    model = "",
    deviceType = "",
    deviceId = "",
    page = 1,
    limit = 15
  ) => {
    if (isLoadingUnits) return; // Prevent multiple calls
    setIsLoadingUnits(true);

    try {
      let allUnits = [];
      let currentPage = page;
      let totalFetched = 0;

      let response;
      do {
        response = await fetchUnitsByMultipleCriteria(
          accountId,
          unitType,
          id,
          VIN,
          licensePlate,
          make,
          model,
          deviceType,
          deviceId,
          currentPage,
          limit
        );
        allUnits = [...allUnits, ...response.rows];
        totalFetched += response.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < response.count);

      setUnits(allUnits);
    } catch (error) {
      console.error(
        "Error fetching units:",
        error?.message || "An unknown error occurred"
      );
    } finally {
      setIsLoadingUnits(false);
    }
  };

  const _fetchDevicesByDeviceType = async (
    deviceType = "",
    page = 1,
    limit = 15
  ) => {
    if (isLoadingDevices) return; // Prevent multiple calls
    setIsLoadingDevices(true);

    try {
      let allDevices = [];
      let currentPage = page;
      let totalFetched = 0;

      let response;
      do {
        response = await fetchDevicesByDeviceType(
          deviceType,
          currentPage,
          limit
        );
        allDevices = [...allDevices, ...response.rows];
        totalFetched += response.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < response.count);

      setDevices(allDevices);
    } catch (error) {
      console.error(
        "Error fetching devices:",
        error?.message || "An unknown error occurred"
      );
    } finally {
      setIsLoadingDevices(false);
    }
  };

  const handleFieldChange = (field, value) => {
    if (field === "unitType") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        unitType: value,
        id: "",
        VIN: "",
        licensePlate: "",
        make: "",
        model: "",
        deviceType: "",
        deviceId: "",
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [field]: value,
      }));

      if (field === "deviceType" && value) {
        _fetchDevicesByDeviceType(value, page, limit);
      }
    }
  };

  // Filter units based on unitType
  const vehicleUnits = units.filter((item) => item.unitType === "Vehicle");
  const containerUnits = units.filter((item) => item.unitType === "Container");

  // const filteredDevices = filters.deviceType
  //   ? devices.filter((device) => device.deviceType === filters.deviceType)
  //   : [];

  return (
    <>
      <Grid container spacing={1} sx={{ display: "flex" }}>
        <Grid item xs={12} sm={1} sx={{ display: "flex" }}>
          <Grid
            sx={{ marginTop: "20px", color: "#6C6C81", fontWeight: "600" }}
            mr={1}
          >
            FILTERS
          </Grid>
          <FilterListIcon sx={{ marginTop: "20px" }} />
        </Grid>

        <Grid item xs={12} sm={2}>
          <SearchFormSelect
            placeholder="Unit Type"
            value={filters.unitType}
            onChange={(selected) => {
              handleFieldChange("unitType", selected);
            }}
            options={unitType.map((item) => ({
              value: item.value,
              label: item.name,
            }))}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <SearchFormSelect
            placeholder="Name"
            value={filters.id}
            onChange={(selected) => {
              handleFieldChange("id", selected);
            }}
            options={units
              .filter(
                (item) =>
                  !filters.unitType || item.unitType === filters.unitType
              )
              .map((item) => ({
                value: item.id,
                label: item.name,
              }))}
          />
        </Grid>

        {/* Conditionally render fields based on unitType */}
        {filters.unitType === "Vehicle" && (
          <>
            {/* VIN Field */}
            {vehicleUnits.some((item) => item.vehicle?.VIN) && ( // Only render if there is at least one valid VIN
              <Grid item xs={12} sm={2}>
                <SearchFormSelect
                  placeholder="VIN"
                  value={filters.VIN}
                  onChange={(selected) => handleFieldChange("VIN", selected)}
                  options={vehicleUnits
                    .filter((item) => item.vehicle?.VIN) // Remove items without valid VIN
                    .map((item) => ({
                      value: item.vehicle?.VIN,
                      label: item.vehicle?.VIN,
                    }))}
                />
              </Grid>
            )}

            {vehicleUnits.some((item) => item.vehicle?.licensePlate) && ( // Only render if there is at least one valid model
              <Grid item xs={12} sm={2}>
                <SearchFormSelect
                  placeholder="License Plate"
                  value={filters.licensePlate}
                  onChange={(selected) =>
                    handleFieldChange("licensePlate", selected)
                  }
                  options={vehicleUnits
                    .filter((item) => item.vehicle?.licensePlate) // Remove items without valid licensePlate
                    .map((item) => ({
                      value: item.vehicle?.licensePlate,
                      label: item.vehicle?.licensePlate,
                    }))}
                />
              </Grid>
            )}

            {/* Make Field */}
            {vehicleUnits.some((item) => item.vehicle?.make) && ( // Only render if there is at least one valid make
              <Grid item xs={12} sm={2}>
                <SearchFormSelect
                  placeholder="Make"
                  value={filters.make}
                  onChange={(selected) => handleFieldChange("make", selected)}
                  options={vehicleUnits
                    .filter((item) => item.vehicle?.make) // Remove items without valid make
                    .map((item) => ({
                      value: item.vehicle?.make,
                      label: item.vehicle?.make,
                    }))}
                />
              </Grid>
            )}

            {/* Model Field */}
            {vehicleUnits.some((item) => item.vehicle?.model) && ( // Only render if there is at least one valid model
              <Grid item xs={12} sm={2}>
                <SearchFormSelect
                  placeholder="Model"
                  value={filters.model}
                  onChange={(selected) => handleFieldChange("model", selected)}
                  options={vehicleUnits
                    .filter((item) => item.vehicle?.model) // Remove items without valid model
                    .map((item) => ({
                      value: item.vehicle?.model,
                      label: item.vehicle?.model,
                    }))}
                />
              </Grid>
            )}
          </>
        )}

        {filters.unitType === "Container" && (
          <>
            <Grid item xs={12} sm={2}>
              <SearchFormSelect
                placeholder="Device Type"
                value={filters.deviceType}
                onChange={(selected) => {
                  handleFieldChange("deviceType", selected);
                }}
                options={[
                  ...new Map(
                    devices.map((item) => [
                      item.deviceType,
                      { value: item.deviceType, label: item.deviceType },
                    ])
                  ).values(),
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <SearchFormSelect
                placeholder="Device"
                value={filters.deviceId}
                onChange={(selected) => handleFieldChange("deviceId", selected)}
                options={devices.map((item) => ({
                  value: item.id,
                  label: item.name || "--",
                }))}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

UnitsFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired,
};

export default UnitsFilters;

import * as yup from "yup";

export const sensorsSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, 'Name should be at least 3 characters.')
    .max(50, "Name cannot exceed 50 characters.")
    .required("Name is required."),
  sensorType: yup
    .string()
    .oneOf(
      [
        "GPS",
        "Fuel Level",
        "Temperature",
        "Speed",
        "Odometer",
        "Alcohol Breathalyser",
        "Alarm Buzzer",
        "Driver ID Tag",
        "Local Sim",
        "Roaming Sim"
      ],
      "Invalid sensor type."
    )
    .default("GPS")
    .required("Sensor type is required."), // Required because `default` is only relevant at runtime
  sensorModel: yup
    .string()
    .trim()
    .max(50, "Sensor model cannot exceed 50 characters.")
    .nullable(), // Optional with a default of `null`
  installedLocation: yup
    .string()
    .trim()
    .max(50, "Installed location cannot exceed 50 characters.")
    .nullable(), // Optional with a default of `null`
  createdBy: yup
    .string()
    .nullable(), // Optional field for the creator's ID
  updatedBy: yup
    .string()
    .nullable(), // Optional field for the updater's ID
});

// /* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import {
  StyledHeader2,
  Title,
  StyledFormContainer,
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
  StyledAddMoreButton,
  NextButton,
} from "../../../../styledComponents";
import FormField from "../../../common/FormField";
import { DeviceModel } from "../../../../model/devices.model";
import { validateForm, validateForm2 } from "../../../../utils/yupValidations";
import { devicesSchema } from "../../../../utils/validation-schemas/devicesSchema";
import FormFieldMultiline from "../../../common/FormFieldMultiline";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { iconMap } from "../../../../constants/iconsList";
import { useNavigate, useParams } from "react-router-dom";
import {
  createDevice,
  getDeviceById,
  updateDevice,
} from "../../../../services/deviceNew.services";
import Loader from "../../../common/Loader";
import { getRoute } from "../../../../constants/routes";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import SuccessMessage from "../../../common/SuccessMessage";
import FormSelect from "../../../common/FormSelect";
import { deviceType } from "../../../../utils/constant";
import { fetchSimCardsByMultipleCriteria } from "../../../../services/simCardNew.service";
import SimCardsFormModal from "../../../modals/SimCardsFormModal";
import PropTypes from "prop-types";

const DevicesForm = ({ isShortcut, onSuccess }) => {
  const { id } = useParams();
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [device, setDevice] = useState(DeviceModel);
  const [simCards, setSimCards] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [limit] = useState(15);
  const [simCardsFormModal, setSimCardsFormModal] = useState(false);
  const [selectedModels, setSelectedModels] = useState([]);
  const accountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  useEffect(() => {
    if (id) {
      _getDeviceById(id);
    } else {
      _fetchSimCardsByMultipleCriteria("create");
    }
  }, [id]);

  const _fetchSimCardsByMultipleCriteria = async (mode, device) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);
    try {
      let allSimcards = [];
      let currentPage = 1;
      let totalFetched = 0;

      let data;
      do {
        data = await fetchSimCardsByMultipleCriteria(
          null,
          null,
          null,
          null,
          accountId,
          "", // Empty status to fetch all
          currentPage,
          limit
        );
        allSimcards = [...allSimcards, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);

      let filteredSimcards;
      if (mode === "create") {
        filteredSimcards = allSimcards.filter(sim => sim.status === "DEALLOCATED");
      } else if (mode === "edit") {
        filteredSimcards = allSimcards.filter(sim => sim.status === "DEALLOCATED" || sim.id === device.simCardId);
      }

      setSimCards(filteredSimcards);
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching sim cards:", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const _getDeviceById = async (id) => {
    setIsLoading(true);
    try {
      const data = await getDeviceById(id);
      // Set selected models based on fetched data
      const models = getModelsByDeviceType(data.deviceType);
      setSelectedModels(models);
      setDevice(data);
      _fetchSimCardsByMultipleCriteria("edit", data);
      handleResponse(data, "Device fetched successfully!", "Get");
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFieldChange = async (name, value, index = null) => {
    try {
      // Update the device state based on the field name
      setDevice((prevDevice) => {
        let updatedDevice = { ...prevDevice };

        if (name === "deviceType") {
          updatedDevice.deviceType = value;
          // Update models based on selected device type
          const models = getModelsByDeviceType(value);
          setSelectedModels(models);
          updatedDevice.make = ""; // Reset make and model
          updatedDevice.model = "";
        } else if (name === "rfids" && index !== null) {
          // Update a specific RFID in the rfids array
          const updatedRfids = [...prevDevice.rfids];
          updatedRfids[index] = value;
          updatedDevice.rfids = updatedRfids;
        } else if (name === "simCardId") {
          updatedDevice[name] = value;
        } else {
          // Update other fields, including simCardId
          updatedDevice[name] = value;
        }

        return updatedDevice;
      });

      // Validate the form field in real-time
      await validateForm2(
        name === "rfids" && index !== null
          ? {
            ...device,
            rfids: [
              ...device.rfids.slice(0, index),
              value,
              ...device.rfids.slice(index + 1),
            ],
          }
          : { ...device, [name]: value },
        name,
        setValidationErrors,
        devicesSchema
      );
    } catch (error) {
      console.error("Error updating field:", error);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Validate the form fields
      const isValid = await validateForm(
        device,
        setValidationErrors,
        devicesSchema
      );
      if (!isValid) {
        console.error("Form validation failed");
        setIsLoading(false);
        return;
      }

      // API call based on whether id is defined or not
      let response;
      if (id === undefined) {
        response = await createDevice(device);
        if (isShortcut == true) {
          handleResponse(response, "Device created successfully!", "");
        } else {
          handleResponse(response, "Device created successfully!", "Card");
        }
      } else {
        response = await updateDevice(device);
        if (isShortcut == true) {
          handleResponse(response, "Device created successfully!", "");
        } else {
          handleResponse(response, "Device updated successfully!", "Card");
        }
      }
    } catch (error) {
      console.log(error);
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addRfidField = () => {
    setDevice((prevDevice) => ({
      ...prevDevice,
      rfids: [...prevDevice.rfids, ""],
    }));
  };

  const removeRfidField = (index) => {
    const updatedRfids = device.rfids.filter((_, i) => i !== index);
    setDevice((prevDevice) => ({ ...prevDevice, rfids: updatedRfids }));
  };
  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
      setTimeout(() => {
        if (mode === "Card") {
          navigate(getRoute("Device", mode));
        } else if (mode === "") {
          onSuccess("Success");
        }
      }, 3000); // Closing parentheses and braces for setTimeout
    }
  };

  const handleError = (error) => {
    window.scrollTo(0, 0);
    if (error.name === "ValidationError") {
      handleValidationErrors(error);
    } else {
      setErrors(error.error);
    }
  };

  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message;
      return acc;
    }, {});
    setValidationErrors(errors);
  };

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSimCardsFormModalOpen = useCallback(() => {
    setSimCardsFormModal(true);
  }, []);
  const handleSimCardsFormModalClose = useCallback(() => {
    setSimCardsFormModal(false);
    _fetchSimCardsByMultipleCriteria();
  }, []);

  // Function to get models by device name
  const getModelsByDeviceType = (deviceTypeName) => {
    console.log("Device Type:", deviceTypeName);
    const device = deviceType.find((device) => device.name === deviceTypeName);
    return device ? device.models : null; // Return models or null if not found
  };

  return (
    <StyledFormContainer>
      <ErrorMessage1 errors={errors} onClose={handleClose} />
      <SuccessMessage message={successMessage} onClose={handleClose} />
      {isLoading && <Loader />}
      <StyledHeader2>
        <Grid item xs={12}>
          <Title>
            <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
              <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
            </IconButton>{" "}
            {id ? "Update" : "Create"} Device
          </Title>
        </Grid>
      </StyledHeader2>

      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">General Parameters</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormSelect
            label="Choose Device Type"
            required
            value={device.deviceType}
            onChange={(e) => handleFieldChange("deviceType", e)}
            options={deviceType.map((item) => ({
              value: item.value,
              label: `${item.name}`,
            }))}
            error={!!validationErrors.deviceType}
            errorMessage={validationErrors.deviceType}
          />
          <FormField
            label="Device Name"
            required
            placeholder="Enter device name"
            value={device.name}
            onChange={(e) => handleFieldChange("name", e.target.value)}
            error={!!validationErrors.name}
            errorMessage={validationErrors.name}
          />
          <FormSelect
            label="Device Make"
            required
            // placeholder="Enter device make"
            value={device.make}
            onChange={(e) => handleFieldChange("make", e)}
            options={selectedModels.map((model) => ({
              value: model.name,
              label: model.name,
            }))}
            error={!!validationErrors.make}
            errorMessage={validationErrors.make}
          />
          <FormSelect
            label="Device Model"
            required
            value={device.model}
            onChange={(e) => handleFieldChange("model", e)}
            options={selectedModels.map((model) => ({
              value: model.name,
              label: model.name,
            }))}
            error={!!validationErrors.model}
            errorMessage={validationErrors.model}
          />
          <FormField
            label="Device SSID"
            placeholder="Enter device SSID"
            value={device.SSID}
            onChange={(e) => handleFieldChange("SSID", e.target.value)}
            error={!!validationErrors.SSID}
            errorMessage={validationErrors.SSID}
          />
          <FormField
            label="Device UID"
            required
            placeholder="Enter device UID"
            value={device.UID}
            onChange={(e) => handleFieldChange("UID", e.target.value)}
            error={!!validationErrors.UID}
            errorMessage={validationErrors.UID}
          />
          <FormFieldMultiline
            label="Server Address"
            placeholder="Enter server address"
            value={device.serverAddress}
            onChange={(e) => handleFieldChange("serverAddress", e.target.value)}
            multiline
            error={!!validationErrors.serverAddress}
            errorMessage={validationErrors.serverAddress}
          />
          <FormSelect
            label="Choose SIM Card"
            label2="Add SIM Card"
            onLabel2Click={handleSimCardsFormModalOpen}
            required
            value={device.simCardId || ""}
            onChange={(selected) => {
              handleFieldChange("simCardId", selected);
            }}
            options={simCards.map((item) => ({
              value: item.id,
              label: `${item.areaCode} ${item.simCardNumber}`,
            }))}
            error={!!validationErrors.simCardId}
            errorMessage={validationErrors.simCardId}
            mode={id ? "edit" : "create"}
          />
        </StyledSectionRight>
      </StyledFormSection>

      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">RFID & Setup Instructions</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          {device.rfids.map((rfid, index) => (
            <Grid
              container
              key={index}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid item xs={11}>
                <FormField
                  label="RFID"
                  placeholder="Enter RFID"
                  value={rfid}
                  onChange={(e) =>
                    handleFieldChange("rfids", e.target.value, index)
                  }
                  error={!!validationErrors.rfid}
                  errorMessage={validationErrors.rfid}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <DeleteIcon
                  onClick={() => removeRfidField(index)}
                  sx={{ color: "#e71111" }}
                />
              </Grid>
            </Grid>
          ))}
          <StyledAddMoreButton
            variant="outlined"
            sx={{
              marginTop: "10px",
            }}
            onClick={addRfidField}
          >
            <img src={iconMap["AddMorePlusIcon"]} alt="PlusIcon" />
            ADD MORE
          </StyledAddMoreButton>
          <FormFieldMultiline
            label="Description"
            placeholder="Enter description"
            value={device.description}
            onChange={(e) => handleFieldChange("description", e.target.value)}
            multiline
            error={!!validationErrors.description}
            errorMessage={validationErrors.description}
          />
          <FormFieldMultiline
            label="Setup Instructions"
            placeholder="Enter setup instructions"
            value={device.setupInstructions}
            onChange={(e) =>
              handleFieldChange("setupInstructions", e.target.value)
            }
            multiline
            error={!!validationErrors.setupInstructions}
            errorMessage={validationErrors.setupInstructions}
          />
        </StyledSectionRight>
      </StyledFormSection>

      <Grid
        sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
      >
        <Grid sx={{ mt: 2 }}>
          <NextButton onClick={handleSubmit}>SAVE</NextButton>
        </Grid>
      </Grid>
      <SimCardsFormModal
        open={simCardsFormModal}
        handleSimCardsFormModalClose={handleSimCardsFormModalClose}
      />
    </StyledFormContainer>
  );
};
DevicesForm.propTypes = {
  isShortcut: PropTypes.bool,
  onSuccess: PropTypes.string,
};
export default DevicesForm;

/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Grid, IconButton, Typography } from "@mui/material";
import {
  StyledFormContainer,
  StyledHeader2,
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
  StyledInputControlContainer,
  NextButton,
} from "../../../../styledComponents";
import { iconMap } from "../../../../constants/iconsList";
import FormField from "../../../common/FormField";
import FormFieldMultiline from "../../../common/FormFieldMultiline";
import { GeofencingModel } from "../../../../model/geofencing.model";
import { validateForm, validateForm2 } from "../../../../utils/yupValidations";
import { geofenceSchema } from "../../../../utils/validation-schemas/geofencesSchema";
import { Title } from "../../../management/units/styles/UnitsStyles";
import ColorPicker from "../../../common/ColorPicker";
import GoogleMaps from "./googleMaps";
import { useNavigate, useParams } from "react-router-dom";
import {
  createGeofence,
  getGeofenceById,
  updateGeofence,
} from "../../../../services/geofencesNew.service";
import FormOptionSelector from "../../../common/FormOptionSelector";
import { geofenceTypes } from "../../../../utils/constant";
import Loader from "../../../common/Loader";
import FormError from "../../../common/FormError";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import SuccessMessage from "../../../common/SuccessMessage";
import { getRoute } from "../../../../constants/routes";
import LocationPermissionModal from "../../../modals/LocationPermissionModal";
// import FormColorPicker from '../../../common/FormColorPicker'

const GeofencesForm = ({ isShortcut, onSuccess }) => {
  const { id } = useParams();
  const [successMessage, setSuccessMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [geofence, setGeofence] = useState(GeofencingModel);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [defaultsSet, setDefaultsSet] = useState(false);
  const [validateCoordinates, setValidateCoordinates] = useState(false);

  const [locationPermissionPopupOpen, setLocationPermissionPopupOpen] =
    useState(false);

  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  useEffect(() => {
    if (id) {
      _getGeofenceById(id);
    } else {
      setDefaults();
    }
  }, [id]);

  useEffect(() => {
    if (validateCoordinates) {
      if (geofence.type === "CIRCLE") {
        validateForm2(geofence, "radius", setValidationErrors, geofenceSchema);
      } else if (geofence.type === "POLYGON") {
        validateForm2(
          geofence,
          "coordinates",
          setValidationErrors,
          geofenceSchema
        );
      }
      setValidateCoordinates(false);
    }
  }, [validateCoordinates]);

  useEffect(() => {
    setGeofence({
      ...geofence,
      radius: null,
      area: null,
      perimeter: null,
      coordinates: [],
    });
  }, [geofence.type]);

  const _getGeofenceById = async (id) => {
    setIsLoading(true);
    try {
      const data = await getGeofenceById(id);
      if (data.type == "POLYGON") {
        data.center = data.coordinates[0];
      }
      setGeofence(data);
      handleResponse(data, "Geofence fetched successfully!", "Get");
      // setSelectedGeofenceType(data.type)
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkLocationAccess = () => {
    if (!navigator.geolocation) {
      console.error("Geolocation is not supported by this browser.");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("Location accessed:", position);
        setLocationPermissionPopupOpen(false); // Reset any previous errors
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          setLocationPermissionPopupOpen(true); // Show popup if permission is denied
        } else {
          console.error("Unable to retrieve location:", error.message);
        }
      }
    );
  };

  const setDefaults = () => {
    const updatedGeofence = {
      ...geofence,
      accountId: defaultAccountId,
    };
    setGeofence(updatedGeofence);
    setDefaultsSet(true);
  };

  useEffect(() => {
    setGeofence((prevGeofence) => {
      const isCircle = prevGeofence.type === "CIRCLE";
      return {
        ...prevGeofence,
        radius: isCircle ? prevGeofence.radius : null,
        area: null,
        perimeter: null,
        coordinates: isCircle ? prevGeofence.coordinates : [],
      };
    });
  }, [geofence.type]);

  useEffect(() => {
    checkLocationAccess(); // Check location access when component mounts
  }, []);

  const handleClosePopup = () => {
    setLocationPermissionPopupOpen(false);
  };

  const handleFieldChange = async (name, value) => {
    try {
      const updatedGeofence = { ...geofence, [name]: value };
      await validateForm2(
        updatedGeofence,
        name,
        setValidationErrors,
        geofenceSchema
      );
      setGeofence((prevGeofence) => ({
        ...prevGeofence,
        [name]: value,
        ...(name === "type" && { type: value }),
        ...(prevGeofence.type === "CIRCLE" && { radius: prevGeofence.radius }), // Preserve radius
      }));
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  const handleColorChange = (newColor) => {
    setGeofence((prevState) => ({
      ...prevState,
      color: newColor,
    }));
  };

  const calculateCenter = (coordinates) => {
    if (!coordinates || coordinates.length === 0) return null;

    const latSum = coordinates.reduce((sum, coord) => sum + coord.lat, 0);
    const lngSum = coordinates.reduce((sum, coord) => sum + coord.lng, 0);

    return {
      lat: latSum / coordinates.length,
      lng: lngSum / coordinates.length,
    };
  };

  const handleMapChange = (value, type) => {
    if (type === "CIRCLE") {
      setGeofence((prevState) => ({
        ...prevState,
        type,
        radius: value.radius !== undefined ? value.radius : prevState.radius,
        center: value.center,
        coordinates: [], // No coordinates for CIRCLE type
      }));
    } else if (type === "LINE") {
      // For LINE type, set coordinates and calculate center
      const newCoordinates = value; // Assuming value is an array of coordinates
      const newCenter = calculateCenter(newCoordinates);

      setGeofence((prevState) => ({
        ...prevState,
        type,
        coordinates: newCoordinates,
        center: newCenter, // Set calculated center
      }));
    } else {
      // POLYGON or other types
      setGeofence((prevState) => {
        if (!Array.isArray(value) || value.length === 0) {
          return prevState;
        }
        const firstItem = value[0];
        const newCoordinates = [...value, firstItem]; // Close polygon

        return {
          ...prevState,
          type,
          coordinates: newCoordinates,
          center: calculateCenter(newCoordinates), // Set calculated center for polygons
        };
      });
    }
    setValidateCoordinates(true);
  };

  // useEffect(() => {
  //   console.log("Updated Geofence State:", geofence);
  // }, [geofence]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const isValid = await validateForm(
        geofence,
        setValidationErrors,
        geofenceSchema
      );

      // Check if the form is valid and if the radius is required based on geofence type
      const isCircleType = geofence.type === "CIRCLE";
      if (
        !isValid ||
        (isCircleType && (geofence.radius == null || geofence.radius <= 0))
      ) {
        console.error("Form validation failed or invalid radius");
        setIsLoading(false);
        return; // Prevent submission if invalid
      }

      let response;
      if (id === undefined) {
        response = await createGeofence(geofence);
        if (isShortcut == true) {
          handleResponse(response, "Geofence created successfully!", "");
        } else {
          handleResponse(response, "Geofence created successfully!", "Card");
        }
      } else {
        response = await updateGeofence(geofence);
        if (isShortcut == true) {
          handleResponse(response, "Geofence updated successfully!", "");
        } else {
          handleResponse(response, "Geofence updated successfully!", "Card");
        }
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
      setTimeout(() => {
        if (mode === "Card") {
          navigate(getRoute("Geofence", mode));
        } else if (mode === "") {
          onSuccess("Success");
        }
      }, 3000); // Closing parentheses and braces for setTimeout
    }
  };
  const handleError = (error) => {
    window.scrollTo(0, 0);
    if (error.name === "ValidationError") {
      handleValidationErrors(error);
    } else {
      setErrors(error.error);
    }
  };

  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message;
      return acc;
    }, {});
    setValidationErrors(errors);
  };

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  // Check if id is undefined and defaultSet is true
  if (id === undefined && !defaultsSet) {
    return null; // or any other fallback UI you want to show
  }

  return (
    <StyledFormContainer>
      <LocationPermissionModal
        open={locationPermissionPopupOpen}
        onClose={handleClosePopup}
      />
      <ErrorMessage1 errors={errors} onClose={handleClose} />
      <SuccessMessage message={successMessage} onClose={handleClose} />
      {isLoading && <Loader />}
      <StyledHeader2>
        <Grid item xs={12}>
          <Title item>
            <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
              <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
            </IconButton>
            {id ? "Update" : "Create"} Geofence
          </Title>
        </Grid>
      </StyledHeader2>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">General parameters</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormField
            label="Name"
            required
            placeholder="Enter name"
            value={geofence.name}
            onChange={(e) => handleFieldChange("name", e.target.value)}
            error={!!validationErrors["name"]}
            errorMessage={validationErrors["name"]}
          />

          <FormFieldMultiline
            label="Description"
            required
            placeholder="Enter description"
            value={geofence.description}
            onChange={(e) => handleFieldChange("description", e.target.value)}
            multiline
            error={!!validationErrors["description"]}
            errorMessage={validationErrors["description"]}
          />
          <StyledInputControlContainer>
            <Typography item variant="label">
              Pick Any Color in Hex Value *
            </Typography>
            {/* <FormColorPicker
            label="Pick Any Color in Hex Value"
            required
            defaultColor={geofence.color}
            onFormValueChange={handleColorChange}
            error={!!validationErrors.color}
            errorMessage={validationErrors.color} 
          /> */}
            <ColorPicker
              defaultColor={geofence.color}
              onFormValueChange={handleColorChange}
            />
            {validationErrors?.color && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                }}
              >
                <img
                  src={iconMap["ALERTICON"]}
                  alt="alertIcon"
                  style={{
                    marginRight: "5px",
                    width: "25px",
                    height: "25px",
                  }}
                />
                <Typography sx={{ fontSize: "16px", color: "#e71111" }}>
                  {validationErrors.color}
                </Typography>
              </div>
            )}
          </StyledInputControlContainer>
          <FormOptionSelector
            label="Geofence Type"
            required
            options={geofenceTypes}
            selectedOption={geofence.type}
            handleOptionChange={(value) => handleFieldChange("type", value)}
            error={!!validationErrors.type}
            errorMessage={validationErrors.type}
          />
        </StyledSectionRight>
      </StyledFormSection>
      <Grid>
        <StyledSectionLeft>
          <Typography variant="heading">Geofence</Typography>
        </StyledSectionLeft>
        {validationErrors.coordinates && (
          <FormError errorMessage={validationErrors.coordinates} />
        )}
        {validationErrors.radius && (
          <FormError errorMessage={validationErrors.radius} />
        )}
        <GoogleMaps
          onChange={handleMapChange}
          coordinates={geofence.coordinates}
          type={geofence.type}
          radius={geofence.radius}
          center={geofence.center}
          id={id}
          polylinePath={geofence.coordinates}
        />
      </Grid>
      <Grid
        sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
      >
        <Grid sx={{ mt: 2 }}>
          <NextButton onClick={handleSubmit}>SAVE</NextButton>
        </Grid>
      </Grid>
    </StyledFormContainer>
  );
};
GeofencesForm.propTypes = {
  isShortcut: PropTypes.bool,
  onSuccess: PropTypes.string,
};

export default GeofencesForm;

import axios from "axios";
import { simCardApi } from "../utils/constant";

const api = axios.create({
  baseURL: simCardApi,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const handleApiError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error("API Error:", error.response.data);
    throw error.response.data;
  } else if (error.request) {
    // The request was made but no response was received
    console.error("No response received:", error.request);
    throw new Error("No response received from server.");
  } else {
    // Something happened in setting up the request that triggered an error
    console.error("Request setup error:", error.message);
    throw new Error("Request setup error.");
  }
};

// Fetch all SIM cards with optional query parameters
export const fetchAllSimCards = async (page, limit) => {
  try {
    const response = await api.get("/", {
      params: { page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Get a SIM card by its ID
export const getSimCardById = async (simCardId) => {
  try {
    const response = await api.get(`/${simCardId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Create a new Sim card
export const createSimCard = async (simCardData) => {
  try {
    const response = await api.post("/", simCardData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const updateSimCard = async (data) => {
  try {
    const response = await api.put(`/${data.id}`, data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Delete a Sim card by its ID
export const deleteSimCard = async (id) => {
  try {
    const response = await api.delete(`/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch sim  cards by account name with pagination
export const fetchSimCardsByAccountName = async (accountName, page, limit) => {
  try {
    const response = await api.get("/fetchSimCardsByAccountName", {
      params: { accountName, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch SIM cards by SIM number with pagination
export const fetchSimCardsBySimNumber = async (simCardNumber, page, limit) => {
  try {
    const response = await api.get("/fetchSimCardsBySimNumber", {
      params: { simCardNumber, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch SIM cards by imeiNumber with pagination
export const fetchSimCardsByIMEINumber = async (imeiNumber, page, limit) => {
  try {
    const response = await api.get("/fetchSimCardsByIMEINumber", {
      params: { imeiNumber, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch SIM cards by iccdNumber with pagination
export const fetchSimCardsByICCDNumber = async (iccdNumber, page, limit) => {
  try {
    const response = await api.get("/fetchSimCardsByICCDNumber", {
      params: { iccdNumber, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch SIM cards by mssidnNumber with pagination
export const fetchSimCardsByMSSIDNNumber = async (
  mssidnNumber,
  page,
  limit
) => {
  try {
    const response = await api.get("/fetchSimCardsByMSSIDNNumber", {
      params: { mssidnNumber, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchSimCardsByMultipleCriteria = async (
  simCardNumber,
  imeiNumber,
  iccdNumber,
  mssidnNumber,
  status,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchSimCardsByMultipleCriteria", {
      params: {
        simCardNumber,
        imeiNumber,
        iccdNumber,
        mssidnNumber,
        accountId,
        status,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

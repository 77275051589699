const defaultAccountId =
  localStorage.getItem("defaultAccountId") !== ""
    ? JSON.parse(localStorage.getItem("defaultAccountId"))
    : "";
export const UnitsModel = {
  accountId: defaultAccountId,
  unitType: "Container",
  vehicle: {
    VIN: "",
    licensePlate: "",
    make: "",
    model: "",
    year: "",
    vehicleTypeId: null,
    color: "",
    engineParameters: {
      engineModel: null,
      enginePower: null,
      engineDisplacement: null,
      fuelType: "Diesel",
      averageCO2emission: null,
    },
    cargoParameters: {
      cargoType: null,
      carryingCapacity: null,
      width: null,
      height: null,
      depth: null,
      usefulVolume: null,
      grossVehicleWeight: null,
      numberOfAxles: null,
    },
    sensors: [
      {
        sensorType: "GPS",
        sensorModel: "",
        installedLocation: "",
        // lastReading: "",
        // lastReadingTimestamp: null,
      },
    ],
  },
  status: "ACTIVATED",
  deviceType: "",
  deviceId: null,
  createdBy: "",
  updatedBy: "",
};

/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Grid, Menu, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import debounce from "lodash.debounce";

import {
  StyledListCard,
  StyledListCardHeader,
  StyledIconButton,
  FlexContainerSB,
  StyledListCardContent,
  StyledMenuItem,
  StyledListCardContainer,
} from "../../../../styledComponents";
import { getDevicesStatusColor } from "../../../../utils/functions";

import DeleteModal from "../../../modals/DeleteModal";
import Loader from "../../../common/Loader";
import EmptyState from "../../../common/EmptyState";
import {
  deleteDevice,
  updateDevice,
} from "../../../../services/deviceNew.services";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import {
  cancelGeoFencing,
  checkDeviceParameters,
  checkIpIdAndApn,
  inquireGeoFencing,
  requestOneTimeTrackOnDemand,
  setApn,
  setGeoFencing,
  setIpAndPort,
} from "../../../../services/aws.services";
import ProvisionDeviceModal from "../../../modals/ProvisionDeviceModel";
import UnprovisionDeviceModal from "../../../modals/UnprovisionDeviceModel";

const DevicesListCard = () => {
  const [errors, setErrors] = useState([]);
  const [openMenuDeviceId, setOpenMenuDeviceId] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activateOpen, setActivateOpen] = useState(false);
  const [deactivateOpen, setDeactivateOpen] = useState(false);

  const navigate = useNavigate();
  const { devices, fetchMore, isLoading, isEmpty } = useOutletContext();
  const containerRef = useRef(null);
  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [devices, isLoading, handleScroll]);

  // Function that calls the API to perform updateDevice
  const handleUpdateDevice = useCallback(
    async (updatedDevice) => {
      try {
        const response = await updateDevice(updatedDevice);
        if (response) {
          setSuccessMessage("Device updated successfully.");
          setErrorMessage("");
          // Set the state to reload the page after successful update
          setShouldReload(true);
        }
      } catch (error) {
        setErrorMessage(error.message || "An unexpected error occurred");
      } finally {
        // setIsLoading(false);
      }
    },
    [setSuccessMessage, setErrorMessage, setShouldReload, selectedItem]
  );

  // Function to open the menu on click of 3 dots in the list cards/tables
  // Callbacks wrapped with useCallback for performance optimization
  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    setOpenMenuDeviceId(selectedItem.id); // Set the ID of the account whose menu should be open
    setSelectedItem(selectedItem);
  }, []);

  // Function to close the menu on selecting any option from the menu
  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuDeviceId(null); // Set the ID of the account whose menu should be open
  }, []);

  const handleActivateModalOpen = useCallback(
    (event, deviceId) => {
      handleActionsMenuClose();
      setSelectedItem(deviceId);
      setActivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setActivateOpen]
  );

  const handleActivateModalClose = useCallback(() => {
    setActivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setActivateOpen]);

  const provisionDevice = useCallback(() => {
    const status = "PROVISIONED";

    // Update the selectedItem with the new subscriptions array
    const updatedDevice = {
      ...selectedItem,
      status: status,
    };

    setSelectedItem(updatedDevice);
    handleUpdateDevice(updatedDevice);

    // Close the modal
    setActivateOpen(false);
    setSelectedItem(null);
  }, [selectedItem, handleUpdateDevice, setActivateOpen, setSelectedItem]);

  const handleDeactivateModalOpen = useCallback(
    (event, deviceId) => {
      handleActionsMenuClose();
      setSelectedItem(deviceId);
      setDeactivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setDeactivateOpen]
  );

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeactivateModalClose = useCallback(() => {
    setDeactivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setDeactivateOpen]);

  const unProvisionDevice = useCallback(() => {
    const status = "UNPROVISIONED";

    // Update the selectedItem with the new subscriptions array
    const updatedDevice = {
      ...selectedItem,
      status: status,
    };

    setSelectedItem(updatedDevice);
    handleUpdateDevice(updatedDevice);

    // Close the modal
    setDeactivateOpen(false);
    setSelectedItem(null);
  }, [selectedItem, handleUpdateDevice, setDeactivateOpen, setSelectedItem]);

  //Delete Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleDeleteModalOpen = useCallback(
    (event, deviceId) => {
      handleActionsMenuClose();
      setSelectedItem(deviceId);
      setOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setOpen]
  );

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setOpen]);

  // Function that calls the API to perform deleteAccount
  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
    //   const response = await deleteDevice(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, 'Device deleted successfully.')
    //     handleError([])
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error)
    // } finally {
    //   setOpen(false);
    //   setSelectedItem(null);
    // }
  };
  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };

  //Function to navigate to the edit screen on click of the card
  const navigateToEditScreen = useCallback(
    (deviceId) => {
      // const tab = "details";
      navigate(`/management/devices/edit/${deviceId}`);
      // Your navigation logic here
    },
    [navigate]
  );
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    handleMenuClose();
    if (action === "Set IP and Port") {
      _setIpAndPort(selectedItem);
    } else if (action === "Check IP ID and APN") {
      _checkIpIdAndApn(selectedItem);
    } else if (action === "Set APN") {
      _setApn(selectedItem);
    } else if (action === "One Time Track on Demand") {
      _oneTimeTrackOnDemand(selectedItem);
    } else if (action === "Cancel Geo-Fencing") {
      _cancelGeoFencing(selectedItem);
    } else if (action === "Inquiry Geo-Fencing") {
      _inquireGeoFencing(selectedItem);
    } else if (action === "Set Geo-Fencing") {
      _setGeoFencing(selectedItem);
    } else if (action === "Check Device Parameters") {
      _checkDeviceParameters(selectedItem);
    }
  };

  const _checkDeviceParameters = async (device) => {
    try {
      const response = await checkDeviceParameters(device); // Await the asynchronous call
      console.log("Check Device Parameters response:", response);
    } catch (error) {
      console.error("Error checking device parameters:", error);
    }
  };

  const _checkIpIdAndApn = async (device) => {
    try {
      const response = await checkIpIdAndApn(device); // Await the asynchronous call
      console.log("Check IP, ID, and APN response:", response);
    } catch (error) {
      console.error("Error checking IP, ID, and APN:", error);
    }
  };
  const _setApn = async (device) => {
    try {
      const response = await setApn(device); // Await the asynchronous call
      console.log("Set APN response:", response);
    } catch (error) {
      console.error("Error setting APN:", error);
    }
  };

  const _setIpAndPort = async (device) => {
    try {
      const response = await setIpAndPort(device);
      console.log("IP and Port set successfully:", response);
    } catch (error) {
      console.error("Error setting IP and Port:", error);
    }
  };

  const _oneTimeTrackOnDemand = async (device) => {
    try {
      const response = await requestOneTimeTrackOnDemand(device); // Await the asynchronous call
      console.log("One Time Track on Demand response:", response);
    } catch (error) {
      console.error("Error requesting One Time Track on Demand:", error);
    }
  };

  const _cancelGeoFencing = async (device) => {
    try {
      const response = await cancelGeoFencing(device); // Await the asynchronous call
      console.log("Cancel Geo-Fencing response:", response);
    } catch (error) {
      console.error("Error canceling Geo-Fencing:", error);
    }
  };

  const _inquireGeoFencing = async (device) => {
    try {
      const response = await inquireGeoFencing(device);
      console.log("Inquiry Geo-Fencing response:", response);
    } catch (error) {
      console.error("Error inquiring Geo-Fencing:", error);
    }
  };

  const _setGeoFencing = async (device) => {
    try {
      const response = await setGeoFencing(device);
      console.log("Set Geo-Fencing response:", response);
    } catch (error) {
      console.error("Error setting Geo-Fencing:", error);
    }
  };

  // Menu items stored in an array for easier mapping
  const menuItems = [
    { label: "Set IP and Port", action: "Set IP and Port" },
    { label: "Set APN", action: "Set APN" },
    { label: "Check IP ID and APN", action: "Check IP ID and APN" },
    { label: "One Time Track on Demand", action: "One Time Track on Demand" },
    { label: "Cancel Geo-Fencing", action: "Cancel Geo-Fencing" },
    { label: "Inquiry Geo-Fencing", action: "Inquiry Geo-Fencing" },
    { label: "Set Geo-Fencing", action: "Set Geo-Fencing" },
    { label: "Check Device Parameters", action: "Check Device Parameters" },
  ];

  return (
    <>
      <Grid sx={{ marginTop: "50px" }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledListCardContainer container ref={containerRef} spacing={2}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="DEVICES"
              message="Oops! You have no devices for your search criteria"
              info="Please feel free to create a new device by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {devices.map((device) => (
          <Grid item xs={12} sm={6} md={4} key={device.id}>
            <StyledListCard>
              <StyledListCardHeader
                title={
                  <Grid container sx={{ justifyContent: "space-between" }}>
                    <Typography variant="cardTitle1">{device.name}</Typography>
                    <Typography
                      variant="cardStatus"
                      sx={{
                        color: getDevicesStatusColor(device.status),
                      }}
                    >
                      &bull; &nbsp;
                      {device.status}
                    </Typography>
                  </Grid>
                }
                action={
                  <Grid>
                    <StyledIconButton
                      id={`menu-button-${device.id}`}
                      onClick={(event) => handleActionsMenuOpen(event, device)}
                    >
                      <MoreVertIcon />
                    </StyledIconButton>
                    <Menu
                      anchorEl={document.getElementById(
                        `menu-button-${device.id}`
                      )}
                      open={openMenuDeviceId === device.id} // The menu is open if the openMenuAccountId
                      onClose={(event) => handleActionsMenuClose(event)}
                    >
                      {/* {device && device.status === "PROVISIONED" ? (
                        <StyledMenuItem
                          onClick={(e) => handleDeactivateModalOpen(e, device)}
                        >
                          <BlockOutlinedIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          Unprovision Device
                        </StyledMenuItem>
                      ) : (
                        <StyledMenuItem
                          onClick={(e) => handleActivateModalOpen(e, device)}
                        >
                          <VerifiedUserOutlinedIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          Provision Device
                        </StyledMenuItem>
                      )} */}
                      <StyledMenuItem
                        onClick={(e) => handleDeleteModalOpen(e, device)}
                      >
                        <Grid sx={{ display: "flex", alignItems: "block" }}>
                          <DeleteOutlineIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          <Typography> Delete Device</Typography>
                        </Grid>
                      </StyledMenuItem>
                      {menuItems.map((item) => (
                        <StyledMenuItem
                          key={item.action}
                          onClick={() => handleMenuItemClick(item.action)}
                        >
                          <Grid sx={{ display: "flex", alignItems: "center" }}>
                            <Typography>{item.label}</Typography>
                          </Grid>
                        </StyledMenuItem>
                      ))}
                    </Menu>
                  </Grid>
                }
              />
              <StyledListCardContent
                onClick={() => navigateToEditScreen(device.id)}
              >
                <Grid>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">UNIT</Typography>
                    <Typography variant="cardValue">
                      {device?.unitDetails?.name
                        ? device?.unitDetails?.name
                        : "--"}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Device Type</Typography>
                    <Typography variant="cardValue">
                      {device?.deviceType ? device?.deviceType : "--"}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">UID</Typography>
                    <Typography variant="cardValue">
                      {device?.UID ? device?.UID : "--"}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Make</Typography>
                    <Typography variant="cardValue">
                      {device?.make ? device?.make : "--"}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Model</Typography>
                    <Typography variant="cardValue">
                      {device?.model ? device?.model : "--"}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">SIM Card</Typography>
                    <Typography variant="cardValue">
                      {device.simCardId
                        ? `${device?.simCardDetails?.areaCode} ${device?.simCardDetails?.simCardNumber}`
                        : "--"}
                    </Typography>
                  </FlexContainerSB>
                </Grid>
              </StyledListCardContent>
            </StyledListCard>
          </Grid>
        ))}
      </StyledListCardContainer>

      <ProvisionDeviceModal
        activateOpen={activateOpen}
        handleActivateModalClose={handleActivateModalClose}
        selectedItem={selectedItem}
        activateItem={provisionDevice}
      />
      <UnprovisionDeviceModal
        deactivateOpen={deactivateOpen}
        handleDeactivateModalClose={handleDeactivateModalClose}
        selectedItem={selectedItem}
        deactivateItem={unProvisionDevice}
      />

      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default DevicesListCard;

import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledModal2 } from '../../styledComponents';
// import { StyledModal, StyledModalContent, StyledModalHr, StyledDeleteButton, StyledCancelButton } from '../../styledComponents'; 
import ConsigneesForm from '../monitoring/customers/components/ConsigneesForm';

const ConsigneesFormModal = ({ open, handleConsigneesFormModalClose }) => {

  // Successfully saved or errored the form
  const handleSuccess = () => {
    handleConsigneesFormModalClose();
  };

  return (
    <Modal
      open={open}
      // onSuccess={handleConsigneesFormModalClose}
      // onClose={(e) => handleConsigneesFormModalClose(e)}
      onClose={handleSuccess}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description">
      <StyledModal2>
        <IconButton
          onClick={handleSuccess}
          style={{ alignSelf: 'flex-end' }}
        >
          <CloseIcon />
        </IconButton>
        <ConsigneesForm isShortcut={true} onSuccess={handleSuccess}
        />
      </StyledModal2>
    </Modal>
  );
};

// PropTypes validation
ConsigneesFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleConsigneesFormModalClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default ConsigneesFormModal;

import axios from "axios";
import { sensorApi } from "../utils/constant";

const api = axios.create({
  baseURL: sensorApi,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to attach the access token
api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
  if (error.response) {
    console.error("API Error:", error.response.data);
    throw error.response.data;
  } else if (error.request) {
    console.error("No response received:", error.request);
    throw new Error("No response received from server.");
  } else {
    console.error("Request setup error:", error.message);
    throw new Error("Request setup error.");
  }
};

// Retrieve a sensor by ID
export const getSensorById = async (id) => {
  try {
    const response = await api.get(`/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Create a new sensor
export const createSensor = async (sensorData) => {
  try {
    const response = await api.post("/", sensorData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Update an existing sensor by ID
export const updateSensor = async (sensorData) => {
  try {
    const response = await api.put(`/${sensorData.id}`, sensorData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Function to fetch sensors by multiple criteria
export const fetchSensorsByMultipleCriteria = async (
  name,
  sensorType,
  sensorModel,
  accountId,
  page,
  limit
) => {
  try {
    const response = await api.get("/fetchSensorsByMultipleCriteria", {
      params: {
        name,
        sensorType,
        sensorModel,
        accountId,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

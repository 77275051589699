/* eslint-disable no-unused-vars */
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import FormField from "../../../common/FormField.js";
import {
  StyledFormContainer,
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
} from "../../../../styledComponents.js";
import FormPhone from "../../../common/FormPhone.js";
import FormPassword from "../../../common/FormPassword.js";
import FormOptionSelector from "../../../common/FormOptionSelector.js";
import { userNameTypes } from "../../../../utils/constant.js";
import { fetchAllRoles } from "../../../../services/roleNew.service.js";
import FormSelect from "../../../common/FormSelect.js";
import { getAccountById } from "../../../../services/accountNew.service.js";

const UsersForm1 = ({
  user,
  onFormValueChange,
  validationErrors,
  setValidationErrors,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserNameSource, setSelectedUserNameSource] = useState("email"); // Default source for userName
  const [roles, setRoles] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [accountDetails, setAccountDetails] = useState();
  const { id } = useParams();
  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  useEffect(() => {
    _fetchAllRoles();
    if (id) {
      //Setting Role Id
      setSelectedRoleId(user?.accessRights[0]?.roleId);
    }
  }, [id, user.accessRights]);

  useEffect(() => {
    if (defaultAccountId !== null) {
      getAccountId();
    }
  }, []);

  const _fetchAllRoles = async () => {
    try {
      const data = await fetchAllRoles();
      setRoles(data.rows);
    } catch (error) {
      console.error(error.response?.data?.message || error.message);
    }
  };

  const setDefaultAccessRights = async (roleId) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const updatedAccessRights = [];
      roles.forEach((role) => {
        if (role.id === roleId) {
          const permissions = role.accessRights;
          updatedAccessRights.push({
            accountId: defaultAccountId,
            roleId: roleId,
            permissions: permissions,
          });
        }
      });
      return updatedAccessRights;
    } catch (error) {
      console.error(
        "Error in setDefaultAccessRights: ",
        error?.message || "An unknown error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleFieldChange = async (name, value) => {
    try {
      let updatedUser = { ...user };
      if (name === "phone") {
        const phoneNumberParts = value.split(" ");
        updatedUser.areaCode = phoneNumberParts[0]; // +255
        updatedUser.phone = phoneNumberParts.slice(1).join(" "); // "21 233 44"
      } else if (name === "role") {
        updatedUser.accessRights = [];
        setSelectedRoleId(value);
        let updatedAccessRights = await setDefaultAccessRights(value);
        updatedUser.accessRights = updatedAccessRights; // Update accessRights here
      } else {
        updatedUser[name] = value;
      }
      // Update userName dynamically based on selected source
      if (selectedUserNameSource === "phone" && name === "phone") {
        updatedUser.userName = value;
      } else if (selectedUserNameSource === "email" && name === "email") {
        updatedUser.userName = value;
      }
      onFormValueChange(updatedUser, name, value, 1); // Pass step index here
    } catch (error) {
      console.error("Error in handleFieldChange:", error);
    }
  };

  const handleUserNameSourceChange = (value) => {
    setSelectedUserNameSource(value);

    // Set the userName based on the selected source
    if (value === "Phone") {
      // Assuming `user.phone` is the phone number and `user.areaCode` is the area code
      handleFieldChange("userName", `${user.phone}`);
    } else if (value === "Email") {
      handleFieldChange("userName", user.email); // Assuming `user.email` exists
    }
  };

  const getAccountId = async () => {
    try {
      const res = await getAccountById(defaultAccountId);
      setAccountDetails(res);

      // Update consignment's billing information with the countryCode
      if (res?.countryCode !== undefined) {
        // Assuming countryCode is a string like "+255"
        handleFieldChange("areaCode", res.countryCode);
      }
    } catch (error) {
      console.error("Error fetching account details:", error);
      // Handle the error, such as displaying an error message to the user
    }
  };

  return (
    <StyledFormContainer>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Basic Details</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <Box>
            <FormField
              label="First Name"
              required
              placeholder="Enter first name"
              value={user.firstName}
              onChange={(e) => handleFieldChange("firstName", e.target.value)}
              error={!!validationErrors.firstName}
              errorMessage={validationErrors.firstName}
            />
            <FormField
              label="Last Name"
              required
              placeholder="Enter last name"
              value={user.lastName}
              onChange={(e) => handleFieldChange("lastName", e.target.value)}
              error={!!validationErrors.lastName}
              errorMessage={validationErrors.lastName}
            />
            <FormField
              label="Email"
              placeholder="Enter Email"
              value={user.email}
              onChange={(e) => handleFieldChange("email", e.target.value)}
              type="email"
              error={!!validationErrors.email}
              errorMessage={validationErrors.email}
            />
            <FormPhone
              label="Phone"
              value={user.areaCode + " " + user.phone}
              onChange={(newValue) => handleFieldChange("phone", newValue)}
              error={!!validationErrors.phone}
              errorMessage={validationErrors.phone}
              required
            />
          </Box>
        </StyledSectionRight>
      </StyledFormSection>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Role</Typography>
        </StyledSectionLeft>

        <StyledSectionRight>
          <FormSelect
            label="Choose Role"
            required
            value={selectedRoleId}
            disabled={id}
            onChange={(selected) => {
              handleFieldChange("role", selected);
            }}
            options={roles
              .filter(
                (item) =>
                  item.name !== "System Administrator" && item.name !== "Driver"
              )
              .map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            error={!!validationErrors["accessRights[0].roleId"]}
            errorMessage={validationErrors["accessRights[0].roleId"]}
            sx={{ width: "100%" }}
          />
          {/* <FormOptionSelector
                  label="Set Measurement System"
                  required
                  options={measurementType}
                  selectedOption={user.settings.measurementSystem}
                  handleOptionChange={(value) =>
                    handleFieldChange('measurementSystem', value)
                  }
                /> */}
        </StyledSectionRight>
      </StyledFormSection>

      <StyledFormSection noBorderBottom={true}>
        <StyledSectionLeft>
          <Typography variant="heading">Credentials</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormOptionSelector
            label="Username"
            options={userNameTypes}
            selectedOption={selectedUserNameSource}
            value={selectedUserNameSource}
            handleOptionChange={(value) => handleUserNameSourceChange(value)}
          />

          <FormField
            value={user.userName}
            onChange={(e) => handleFieldChange("userName", e.target.value)}
            error={!!validationErrors.userName}
            errorMessage={validationErrors.userName}
            readOnly={true}
            disabled="true"
          />
          {id === undefined && (
            <>
              <FormPassword
                label="Password"
                value={user.password}
                onChange={(e) => handleFieldChange("password", e.target.value)}
                error={!!validationErrors.password}
                errorMessage={validationErrors.password}
                required
              />
              <FormPassword
                label="Confirm Password"
                value={user.confirmPassword}
                onChange={(e) =>
                  handleFieldChange("confirmPassword", e.target.value)
                }
                error={!!validationErrors.confirmPassword}
                errorMessage={validationErrors.confirmPassword}
                required
              />
            </>
          )}
        </StyledSectionRight>
      </StyledFormSection>
    </StyledFormContainer>
  );
};

UsersForm1.propTypes = {
  user: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  setValidationErrors: PropTypes.func.isRequired,
};

export default UsersForm1;

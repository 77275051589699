const defaultAccountId =
  localStorage.getItem("defaultAccountId") !== ""
    ? JSON.parse(localStorage.getItem("defaultAccountId"))
    : "";
export const SimCardModel = {
  accountId: defaultAccountId,
  simCardNumber: "",
  imeiNumber: "",
  iccdNumber: "",
  mssidnNumber: "",
  createdBy: "",
  updatedBy: "",
  status: "DEALLOCATE", // Default value set to 'DEALLOCATE'
};

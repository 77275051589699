/* eslint-disable react/prop-types */
import { CardContent, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes for type-checking
import { iconMap } from "../../../../constants/iconsList";
import {
  StatusTypography,
  UnitMonitoringLable,
  UnitMonitoringUnitCard,
} from "../../../../styledComponents";
// import UnitStatus from "./UnitStatusComponent";
import SelectedUnitDetails from "./SelectedUnitDetailsComponent";
import { requestOneTimeTrackOnDemand } from "../../../../services/aws.services";
import { mapStatus } from "../../../../utils/constant";
import { formatDuration } from "../../../../utils/functions";

// Helper function to determine motion status
const getMotionStatus = (speed, accStatus) => {
  // Convert speed to a number in case it's a string
  const numericSpeed = Number(speed);

  // Handle undefined or null values for accStatus
  if (accStatus === undefined || isNaN(numericSpeed)) {
    return "--"; // Return a default message if any data is missing
  }

  // If speed is 0, check accStatus
  if (numericSpeed === 0) {
    if (accStatus === "ACC OFF") {
      return "Stationary (Ignition Off)";
    } else if (accStatus === "ACC ON") {
      return "Idle"; // Vehicle is stationary but ignition is on (idling)
    }
  }
  // If speed is greater than 0, vehicle is moving
  else if (numericSpeed > 0) {
    return "Moving";
  }

  // Default case: stationary but with ignition on, not necessarily idling
  return "Stationary (Ignition On)";
};

const Units = ({ unitdata, selectedUnit, handleUnitClick  }) => {
  const [hoveredUnit, setHoveredUnit] = useState(null); // State to track hovered unit
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [stationaryDuration, setStationaryDuration] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const tooltipRef = useRef(null);

  const handleMouseEnter = (event) => {
    setHoveredUnit(unitdata);
    // Set tooltip position below the cursor
    setTooltipPosition({ top: event.clientY + 10, left: event.clientX });
    setTooltipVisible(true); // Show the tooltip
  };

  const handleMouseLeave = () => {
    // Hide tooltip only if mouse leaves both unit and tooltip area
    if (tooltipRef.current && !tooltipRef.current.contains(event.relatedTarget)) {
      setHoveredUnit(null);
      setTooltipVisible(false); // Hide the tooltip
    }
  };

  // Check if unitdata is present
  // console.log("unitdata",unitdata)


  // Handler for the LOCATIONSEARCHING icon click
  const handleLocationSearch = async () => {
    const uid = unitdata.deviceDetails;

    if (!uid) {
      console.error("UID is missing for the unit");
      return;
    }

    console.log("Requesting one-time track on demand for UID:", uid);

    try {
      const response = await requestOneTimeTrackOnDemand(uid);
      console.log("requestOneTimeTrackOnDemand Response:", response);
    } catch (error) {
      console.error(
        "Failed to request one-time track on demand:",
        error.message
      );
    }
  };

  // const accStatus = unitdata.eLockDetails?.decodedResponse?.locationInfo?.status?.accStatus || {};
  const accStatus = mapStatus(
    "accStatus",
    unitdata.eLockDetails?.decodedResponse?.locationInfo?.status?.accStatus || unitdata?.latestMonitoringData?.ignitionStatus
  );
  const speed =
    unitdata.eLockDetails?.decodedResponse?.locationInfo?.speed || unitdata?.latestMonitoringData?.speed || {};

  // Determine motion status based on speed and accStatus
  const motionStatus = getMotionStatus(speed, accStatus);
  // Determine if this unit is selected
  const isSelected = selectedUnit && selectedUnit.unitId === unitdata.unitId;

  // Assign the correct status icon based on the unit's status
  const statusIcon =
    mapStatus(
      "lockStatus",
      unitdata.eLockDetails?.decodedResponse?.locationInfo?.status?.lockStatus || unitdata?.latestMonitoringData?.lockStatus
    ) === "Lock Open"
      ? iconMap["UNITONLINEICON"]
      : iconMap["UNITONLINEICON"];

      useEffect(() => {
        if (motionStatus.startsWith("Stationary")) {
          if (!lastUpdateTime) {
            setLastUpdateTime(Date.now());
            setStationaryDuration(0); // Reset when stationary starts.
          }
    
          const intervalId = setInterval(() => {
            const currentTime = Date.now();
            const duration = Math.floor((currentTime - lastUpdateTime) / 60000); // Calculate in minutes.
            setStationaryDuration(duration);
          }, 60000); // Update every minute.
    
          return () => clearInterval(intervalId); // Cleanup interval on unmount or status change.
        } else {
          setLastUpdateTime(null);
          setStationaryDuration(null);
        }
      }, [motionStatus, lastUpdateTime]);

  return (
    <Grid container style={{ padding: "0px 20px", position: "relative" }}
    onClick={() => handleUnitClick(unitdata)}> 
      <Grid item xs={12}>
        <UnitMonitoringUnitCard selected={isSelected}>
          <CardContent>
            <Grid container spacing={2}>
              {/* Left Section - Apply hover handlers here */}
              <Grid
                item
                xs={12}
                md={8}
                display="flex"
                alignItems="center"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  src={statusIcon}
                  alt="unit status icon"
                  style={{ marginRight: "8px", width: "30px" }}
                />
                <Grid>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "#0A2C47",
                    }}
                  >
                    {unitdata.name}
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <UnitMonitoringLable>Unique ID: </UnitMonitoringLable>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginLeft: "4px",
                        color: "#6C6C81",
                      }}
                    >
                      {unitdata.deviceDetails.UID}
                    </Typography>
                  </div>
                </Grid>
              </Grid>

              {/* Right Section - No hover handlers here */}
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {/* location_searching */}
                <Tooltip title={unitdata.hasResponse ? "Track unit on map" : "Unit is offline"}>
                  <Typography
                    onClick={handleLocationSearch}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={iconMap["LOCATIONSEARCHING"]}
                      alt="location_searching"
                      style={{ width: "12px" }}
                    />
                  </Typography>
                </Tooltip>
{/* Lock Status */}

<Tooltip
  title={mapStatus(
    "lockStatus",
    unitdata.eLockDetails?.decodedResponse?.locationInfo?.status?.lockStatus ??
    unitdata?.latestMonitoringData?.lockStatus
  )}
>
  <Typography
    sx={{
      color: (() => {
        const lockStatus = mapStatus(
          "lockStatus",
          unitdata.eLockDetails?.decodedResponse?.locationInfo?.status?.lockStatus ??
          unitdata?.latestMonitoringData?.lockStatus
        );
        return lockStatus === "Lock Open"
          ? "red"
          : lockStatus === "Lock On"
          ? "#26861D"
          : "gray";
      })(),
      fontSize: "10px",
      fontWeight: 600,
    }}
  >
    {(() => {
      const lockStatus =
        unitdata.eLockDetails?.decodedResponse?.locationInfo?.status?.lockStatus ??
        unitdata?.latestMonitoringData?.lockStatus;

      if (lockStatus) {
        const mappedStatus = mapStatus("lockStatus", lockStatus);
        const iconSrc =
          mappedStatus === "Lock On" ? iconMap["LOCKICON"] : iconMap["LOCKOPENICON"];

        return (
          <img
            src={iconSrc}
            alt="lock status icon"
            style={{ marginRight: "4px", width: "12px" }}
          />
        );
      } else {
        return "--"; // Fallback if no lock status is available
      }
    })()}
  </Typography>
</Tooltip>

                {/* Motion Status */}
                <Tooltip
  title={
    motionStatus.startsWith("Stationary")
      ? `Stationary for ${stationaryDuration !== null ? formatDuration(stationaryDuration) : "0 min"}`
      : motionStatus
  }
>
  <StatusTypography status={motionStatus}>
    <img
      src={
        motionStatus === "Moving"
          ? iconMap["MOVINGICON"]
          : iconMap["STATIONERYICON"]
      }
      alt="motion icon"
      style={{ width: "12px", verticalAlign: "middle" }}
    />
  </StatusTypography>
</Tooltip>

                {/* Online Status */}
                <Typography
  sx={{
    fontSize: "12px",
    color: (unitdata.eLockDetails?.decodedResponse?.status || unitdata.latestMonitoringData?.status) === "Online" ? "green" : "red",
  }}
>
  { (unitdata.eLockDetails?.decodedResponse?.status || unitdata.latestMonitoringData?.status)?.toUpperCase() || "--" }
</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </UnitMonitoringUnitCard>
      </Grid>

      {/* Tooltip on Hover */}
      {tooltipVisible && hoveredUnit && (
        <div
                  ref={tooltipRef}
                  style={{
                    position: "fixed",
                    zIndex: 9999,
                    top: tooltipPosition.top,
                    left: tooltipPosition.left,
                  }}
                  onMouseEnter={() => { /* Keep hovered unit when entering tooltip */ }}
                  onMouseLeave={() => { 
                    // Hide only when leaving both unit and tooltip area 
                    setHoveredUnit(null); 
                    setTooltipVisible(false); 
                  }}
                >
                  <SelectedUnitDetails
                    unit={hoveredUnit}
                    expanded={true}
                    handleClose={() => { 
                      setHoveredUnit(null); 
                      setTooltipVisible(false); 
                    }}
                    handleToggleExpand={() => {}}
                    eLockDetails={hoveredUnit.eLockDetails}
                  />
                </div>
      )}
    </Grid>
  );
};

// Prop-types validation for Units component
Units.propTypes = {
  unitdata: PropTypes.shape({
    unitId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    unitStatus: PropTypes.string.isRequired,
    motionStatus: PropTypes.string,
  }).isRequired,
  handleUnitClick: PropTypes.func.isRequired,
  selectedUnit: PropTypes.shape({
    unitId: PropTypes.string,
  }),
};

export default Units;

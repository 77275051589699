/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { iconMap } from "../../../../constants/iconsList";
import {
  UnitMonitoringLable,
  UnitMonitoringStyledButton,
  UnitMonitoringStyledGridItem,
  UnitMonitoringValue,
} from "../../../../styledComponents";
import CloseIcon from "@mui/icons-material/Close";
import { mapStatus } from "../../../../utils/constant";
import { formatDateTime } from "../../../../utils/functions";

const SelectedUnitDetails = ({
  unit,
  expanded,
  handleClose,
  handleToggleExpand,
  eLockDetails,
}) => {
  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: "8px",
        overflowY: "auto", // Allow scrolling for overflow content
        height: "400px",
        width: "600px",
      }}
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              color: "#0A2C47",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={
                iconMap[
                  mapStatus(
                    "lockStatus",
                    unit.eLockDetails?.decodedResponse?.locationInfo
                      ?.lockStatus || unit?.latestMonitoringData?.lockStatus // Fallback to latestMonitoringData
                  ) === "Lock Open"
                    ? "UNITOFFLINEICON"
                    : "UNITONLINEICON"
                ]
              }
              alt="unitStatusIcon"
              style={{ marginRight: "4px", width: "30px" }}
            />
            {unit.name || unit?.unitDetails?.name}
          </Typography>
          <Grid item>
            <UnitMonitoringLable>Last update:</UnitMonitoringLable>
            <Typography sx={{ fontSize: "14px", color: "#272560" }}>
              {unit.eLockDetails?.decodedResponse?.locationInfo?.dateTime
                ? formatDateTime(
                    unit.eLockDetails?.decodedResponse?.locationInfo?.dateTime
                  )
                : unit?.latestMonitoringData?.dateTime
                ? formatDateTime(unit?.latestMonitoringData?.dateTime)
                : "--"}
            </Typography>
          </Grid>
          {/* <UnitMonitoringValue
          border={'1px solid red'}>Last update: {unit.eLockDetails?.decodedResponse?.locationInfo?.dateTime ?? "--"}</UnitMonitoringValue>  */}
          <Box display="flex" alignItems="center">
            {/* <UnitMonitoringStyledButton>Track</UnitMonitoringStyledButton> */}
            {/* <UnitMonitoringStyledButton onClick={handleToggleExpand}>
              <img
                src={iconMap[expanded ? "EXPANDLESSICON" : "EXPANDMOREICON"]}
                alt="expandIcon"
                style={{ marginRight: "5px", width: "14px" }}
              />
              {expanded ? "Collapse" : "Expand"}
            </UnitMonitoringStyledButton> */}
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        {expanded && (
          <Grid mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <UnitMonitoringLable>Speed</UnitMonitoringLable>
                <Typography sx={{ fontSize: "14px", color: "#272560" }}>
                  {`${
                    eLockDetails?.decodedResponse?.locationInfo?.speed ||
                    unit?.latestMonitoringData?.speed ||
                    "--"
                  } km/hr`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <UnitMonitoringLable>Coordinates</UnitMonitoringLable>
                <Typography sx={{ fontSize: "14px", color: "#272560" }}>
                  {`${
                    eLockDetails?.decodedResponse?.locationInfo?.latitude ||
                    unit?.latestMonitoringData?.location.lat ||
                    "--"
                  } ${
                    eLockDetails?.decodedResponse?.locationInfo?.longitude ||
                    unit?.latestMonitoringData?.location.lng ||
                    "--"
                  }`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <UnitMonitoringLable>Current location</UnitMonitoringLable>
                <Typography sx={{ fontSize: "14px", color: "#272560" }}>
                  {`${
                    eLockDetails?.decodedResponse?.locationInfo?.address ||
                    unit?.latestMonitoringData?.location?.address ||
                    "--"
                  }`}
                </Typography>
              </Grid>
            </Grid>
            <hr style={{ height: "0px", color: "#D8D8DF" }} />
            <Grid container direction="row">
              <Grid item xs={12} md={9}>
                <Typography
                  sx={{
                    mt: 2,
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#0F446B",
                  }}
                >
                  Sensor Values
                </Typography>
                <Grid container spacing={0}>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <Typography
                      sx={{
                        color:
                          eLockDetails?.decodedResponse?.locationInfo?.status
                            ?.lockStatus ||
                          unit?.latestMonitoringData?.lockStatus
                            ? mapStatus(
                                "lockStatus",
                                eLockDetails?.decodedResponse?.locationInfo
                                  ?.status?.lockStatus ||
                                  unit?.latestMonitoringData?.lockStatus
                              ) === "Lock Open"
                              ? "red"
                              : "#26861D"
                            : "#0a2c47",
                        fontSize: "14px",
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center", // Ensures text and icon are aligned
                      }}
                    >
                      {(eLockDetails?.decodedResponse?.locationInfo?.status
                        ?.lockStatus ||
                        unit?.latestMonitoringData?.lockStatus) && (
                        <img
                          src={
                            iconMap[
                              mapStatus(
                                "lockStatus",
                                eLockDetails?.decodedResponse?.locationInfo
                                  ?.status?.lockStatus ||
                                  unit?.latestMonitoringData?.lockStatus
                              ) === "Lock Open"
                                ? "LOCKOPENICON"
                                : "LOCKICON"
                            ]
                          }
                          alt="lock status icon"
                          style={{ marginRight: "4px", width: "12px" }}
                        />
                      )}
                      {mapStatus(
                        "lockStatus",
                        eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.lockStatus || unit?.latestMonitoringData?.lockStatus
                      ) ?? "--"}
                    </Typography>

                    <UnitMonitoringLable>Lock Status</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>

                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue
                      sx={{
                        color:
                          eLockDetails?.decodedResponse?.locationInfo?.status
                            ?.accStatus ||
                          unit?.latestMonitoringData?.ignitionStatus
                            ? mapStatus(
                                "accStatus",
                                eLockDetails?.decodedResponse?.locationInfo
                                  ?.status?.accStatus ||
                                  unit?.latestMonitoringData?.ignitionStatus
                              ) === "ACC ON"
                              ? "green"
                              : "red" // Conditional color for ignition status
                            : "#0a2c47", // Conditional color for acc status
                      }}
                    >
                      {mapStatus(
                        "accStatus",
                        eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.accStatus ||
                          unit?.latestMonitoringData?.ignitionStatus
                      ) ?? "--"}
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Ignition</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {eLockDetails?.decodedResponse?.locationInfo?.mileage !=
                      null
                        ? eLockDetails.decodedResponse.locationInfo.mileage
                        : unit?.latestMonitoringData?.mileage != null
                        ? unit?.latestMonitoringData?.mileage
                        : "--"}{" "}
                      Km
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Mileage</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {eLockDetails?.decodedResponse?.peripheralData?.[
                        "Battery Power Percent"
                      ] ||
                        unit?.latestMonitoringData?.batteryPower ||
                        "--"}
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Battery Power</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  {/* <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>hardcoded</UnitMonitoringValue>
                    <UnitMonitoringLable>RFID Card</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem> */}
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {mapStatus(
                        "lockWireCut",
                        eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.lockWireCut ||
                          unit?.latestMonitoringData?.lockWireStatus
                      ) ?? "--"}
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Rope Wire Cut</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {mapStatus(
                        "stringStatus",
                        eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.lockWireCut ||
                          unit?.latestMonitoringData?.lockWireStatus
                      ) ?? "--"}
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>String Status</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {mapStatus(
                        "lowPowerAlarm",
                        eLockDetails?.decodedResponse?.alarmData?.alarmStatus
                          ?.lowPowerAlarm ||
                          unit?.latestMonitoringData?.lowBatteryAlarm
                      ) ?? "--"}
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Low Battery Alarm</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {mapStatus(
                        "motorStuck",
                        eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.motorStuck ||
                          unit?.latestMonitoringData?.motorStatus
                      ) ?? "--"}
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Motor Fault Alarm</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {mapStatus(
                        "simTamper",
                        eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.simTamper || unit?.latestMonitoringData?.simTamper
                      ) ?? "--"}
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Sim Tamper</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {mapStatus(
                        "vibrationStatus",
                        eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.vibrationStatus ||
                          unit?.latestMonitoringData?.shakeVibrateAlarm
                      ) ?? "--"}
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Vibration Alarm</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                  <UnitMonitoringStyledGridItem item xs={12} md={3}>
                    <UnitMonitoringValue>
                      {mapStatus(
                        "backCoverStatus",
                        eLockDetails?.decodedResponse?.locationInfo?.status
                          ?.simTamper || unit?.latestMonitoringData?.simTamper
                      ) ?? "--"}
                    </UnitMonitoringValue>
                    <UnitMonitoringLable>Back Cover Status</UnitMonitoringLable>
                  </UnitMonitoringStyledGridItem>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Grid container>
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "#0F446B",
                    }}
                  >
                    Connectivity Settings
                  </Typography>
                  <Grid container spacing={0}>
                    <UnitMonitoringStyledGridItem item xs={12} md={6}>
                      <UnitMonitoringValue>
                        {unit.deviceType}
                      </UnitMonitoringValue>
                      <UnitMonitoringLable>Device Type</UnitMonitoringLable>
                    </UnitMonitoringStyledGridItem>
                    <UnitMonitoringStyledGridItem item xs={12} md={6}>
                      <UnitMonitoringValue>
                        {unit.deviceDetails.UID}
                      </UnitMonitoringValue>
                      <UnitMonitoringLable>Unique ID</UnitMonitoringLable>
                    </UnitMonitoringStyledGridItem>
                  </Grid>
                </Grid>

                {/* <Grid container>
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "#0F446B",
                    }}
                  >
                    Custom Fields
                  </Typography>
                  <Grid container spacing={0}>
                    <UnitMonitoringStyledGridItem item xs={12} md={6}>
                      <UnitMonitoringValue>Bookings</UnitMonitoringValue>
                      <UnitMonitoringLable>hardcoded</UnitMonitoringLable>
                    </UnitMonitoringStyledGridItem>
                    <UnitMonitoringStyledGridItem item xs={12} md={6}>
                      <UnitMonitoringValue>Tracking ID</UnitMonitoringValue>
                      <UnitMonitoringLable>hardcoded</UnitMonitoringLable>
                    </UnitMonitoringStyledGridItem>
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default SelectedUnitDetails;

export const ConsignmentModel = {
  orderNumber: "",
  customerId: "",
  accountId: "",
  unitId: null,
  driverId: null,
  status: "NOT ASSIGNED",
  pickupDetails: {
    address: {
      addressType: null,
      geofenceId: "",
      streetOne: "",
      streetTwo: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    },
    name: "",
    areaCode: "",
    phone: "",
  },
  deliveryDetails: {
    addressId: null,
    address: {
      addressType: null,
      geofenceId: null,
      streetOne: "",
      streetTwo: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    },
    name: "",
    areaCode: "",
    phone: "",
  },
  cargoDetails: {
    totalWeight:  0,
    items: [
      {
        cargoType: {
          name:  "",
          info:  "",
        },
        quantity: 0,
        weight: 0,
        length: 0,
        breadth: 0,
        height: 0,
        cubicMetres: 0,
        description:  "",
        status:  "",
        reasons: [],
      },
    ],
  },
  deliverySchedule: {
    expectedPickupDate:  "",
    expectedDeliveryDate:  "",
    checkpoints: [],
    pickupTime: {
      from:  "",
      to:  "",
    },
    deliveryTime: {
      from:  "",
      to:  "",
    },
  },

  palletDetails: {
    expectedReturnDate:  "",
    returnPallets: false,
    returnAddress: {
      addressType:  null,
      geofenceId:  null,
      streetOne:  "",
      streetTwo:  "",
      city:  "",
      state:  "",
      postalCode:  "",
      country:  "",
    },
  },
  insuranceAndCustomsDocuments: [],
  otherDocuments: [],
  pickupDetailDocument: [],
  deliveryDetailDocument: [],
  pickupSignature: {},
  deliverySignature: {},
  additionalInformation: {
    transportationMode: "Truck",
    specialInstructions:  "",
  },
  billingInformation: {
    paymentTerm: "Payment in advance",
    paymentTermValue:  1,
    rateFinalized: false,
    amount: 0.00,
    creditDays:0,
    currency:  "",
    VAT:  0,
    notes:  "",
  },
  tripStatus: "RECEIVED",
  assignedBy:  null,
  receivedDateTime:  "",
  unitAllocationDateTime:  "",
  driverAllocationDateTime:  "",
  driverAcceptedDateTime:  "",
  driverDeclinedDateTime:  "",
  startDateTime:  "",
  pickupDateTime:  "",
  loadingCompletedDateTime:  "",
  enrouteDateTime:  "",
  arrivedDateTime:  "",
  deliveredDateTime:  "",
};

import React, { useEffect, useRef, useCallback } from "react";
import { debounce } from "lodash";
import {
  StyledInputControlContainer,
  StyledSearchFormSelectTextField,
} from "../../styledComponents";
import { Autocomplete, Typography, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import FormError from "./FormError";

const SearchFormSelect = ({
  label,
  value,
  onChange,
  options,
  error,
  errorMessage,
  required = false,
  disabled,
  placeholder,
  readOnly = false,
  isLoading = false,
  onScrollEnd,
}) => {
  const listboxRef = useRef(null);

  const handleScroll = useCallback(
    debounce(() => {
      const container = listboxRef.current;
      if (container) {
        const isAtBottom =
          container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50;

        if (isAtBottom && onScrollEnd && !isLoading) {
          onScrollEnd();
        }
      }
    }, 200),
    [onScrollEnd, isLoading]
  );

  useEffect(() => {
    const attachScrollListener = () => {
      const listbox = document.querySelector(".MuiAutocomplete-listbox");
      if (listbox) {
        listboxRef.current = listbox;
        listbox.addEventListener("scroll", handleScroll);
      }
    };

    const observer = new MutationObserver(() => {
      attachScrollListener();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      if (listboxRef.current) {
        listboxRef.current.removeEventListener("scroll", handleScroll);
      }
      observer.disconnect();
    };
  }, [handleScroll]);

  return (
    <StyledInputControlContainer>
      <Typography variant="label">
        {label}
        {required && " *"}
      </Typography>
      <Autocomplete
        value={options.find((option) => option.value === value) || null}
        onChange={(event, newValue) => {
          if (newValue) {
            onChange(newValue.value);
          } else {
            onChange("");
          }
        }}
        options={options}
        readOnly={readOnly}
        getOptionLabel={(option) => option.label || ""}
        renderInput={(params) => (
          <StyledSearchFormSelectTextField
            {...params}
            placeholder={placeholder}
            error={!!error}
            required={required}
            disabled={disabled || isLoading}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.value === value}
        disabled={disabled}
      />
      {error && <FormError errorMessage={errorMessage} />}
    </StyledInputControlContainer>
  );
};

SearchFormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  onScrollEnd: PropTypes.func.isRequired,
};

export default SearchFormSelect;

import axios from "axios";
import { consignmentApi } from "../utils/constant";

const api = axios.create({
  baseURL: consignmentApi,
  headers: {
    "Content-Type": "application/json",
  },
});

// Intercept requests to add the authorization token
api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
  if (error.response) {
    console.error("API Error:", error.response.data);
    throw error.response.data;
  } else if (error.request) {
    console.error("No response received:", error.request);
    throw new Error("No response received from server.");
  } else {
    console.error("Request setup error:", error.message);
    throw new Error("Request setup error.");
  }
};

// Fetch all consignments with optional query parameters
export const fetchAllConsignments = async (queryParams) => {
  try {
    const response = await api.get("/", { params: queryParams });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Get a consignment by its ID
export const getConsignmentById = async (consignmentId) => {
  try {
    const response = await api.get(`/${consignmentId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
const appendFormData2 = (formData, data, parentKey = "") => {
  if (data && typeof data === "object" && !(data instanceof File)) {
    if (Array.isArray(data)) {
      data.forEach((item, index) => {
        console.log(`Appending array item: ${parentKey}[${index}]`);
        appendFormData2(formData, item, `${parentKey}[${index}]`);
      });
    } else {
      Object.keys(data).forEach((key) => {
        console.log(
          `Appending object key: ${parentKey ? `${parentKey}.${key}` : key}`
        );
        appendFormData2(
          formData,
          data[key],
          parentKey ? `${parentKey}.${key}` : key
        );
      });
    }
  } else {
    console.log(`Appending value: ${parentKey} = ${data}`);
    formData.append(parentKey, data);
  }
};
// Create a new consignment
export const createConsignment = async (consignmentData) => {
  try {
    const formData = new FormData();

    appendFormData2(formData, consignmentData);

    for (const pair of formData.entries()) {
      console.log(`${pair[0]}:`, pair[1]);
    }

    const response = await api.post("/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const updateConsignment = async (consignmentData) => {
  try {
    const formData = new FormData();

    // Append existing consignment data to formData
    appendFormData2(formData, consignmentData);

    // Extract the `id` from `formData`
    let id;
    for (const pair of formData.entries()) {
      console.log(`${pair[0]}:`, pair[1]);
      if (pair[0] === "id") {
        id = pair[1];
      }
    }

    // Ensure the `id` is found
    if (!id) {
      throw new Error("ID not found in formData");
    }

    const response = await api.put(`/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Update an existing consignment by its ID
export const updateConsignmentOld = async (data) => {
  try {
    const response = await api.put(`/${data.get("id")}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Delete a consignment by its ID
export const deleteConsignment = async (id) => {
  try {
    const response = await api.delete(`/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch units by consignment id with pagination
export const fetchConsignmentsByAccountId = async (page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchConsignmentsByAccountId", {
      params: { accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch units by consignment id with pagination
export const fetchConsignmentsByDriverId = async (driverId, page, limit) => {
  try {
    const response = await api.get("/fetchConsignmentsByDriverId", {
      params: { driverId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch consignment by customer name and account ID with pagination
export const fetchConsignmentsByCustomerNameAndAccountId = async (
  customerName,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get(
      "/fetchConsignmentsByCustomerNameAndAccountId",
      {
        params: { customerName, accountId, page, limit },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch consignment by customer name and account ID with pagination
export const fetchConsignmentsByDriverIdAndAccountIdAndCustomerName = async (
  driverId,
  customerName,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get(
      "/fetchConsignmentsByDriverIdAndAccountIdAndCustomerName",
      {
        params: { driverId, accountId, customerName, page, limit },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch consignment by unit name and account ID with pagination
export const fetchConsignmentsByUnitNameAndAccountId = async (
  unitName,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchConsignmentsByUnitNameAndAccountId", {
      params: { unitName, accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch consignment by unit name and account ID with pagination
export const fetchConsignmentsByDriverIdAndAccountIdAndUnitName = async (
  driverId,
  unitName,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get(
      "/fetchConsignmentsByDriverIdAndAccountIdAndUnitName",
      {
        params: { driverId, accountId, unitName, page, limit },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch consignment by driver name and account ID with pagination
export const fetchConsignmentsByDriverNameAndAccountId = async (
  driverName,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get(
      "/fetchConsignmentsByDriverNameAndAccountId",
      {
        params: { driverName, accountId, page, limit },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch consignment by trip status and account ID with pagination
export const fetchConsignmentsByAccountIdAndTripStatus = async (
  tripStatus,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get(
      "/fetchConsignmentsByAccountIdAndTripStatus",
      {
        params: { accountId, tripStatus, page, limit },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch consignment by trip status and account ID with pagination
export const fetchConsignmentsByDriverIdAndAccountIdAndTripStatus = async (
  driverId,
  tripStatus,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get(
      "/fetchConsignmentsByDriverIdAndAccountIdAndTripStatus",
      {
        params: { driverId, accountId, tripStatus, page, limit },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchDuplicateConsignment = async (consignmentId) => {
  try {
    const response = await api.get(
      `/fetchDuplicateConsignment/${consignmentId}`
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchConsignmentsByMultipleCriteria = async (
  accountId,
  customerId,
  unitId,
  driverId,
  tripStatus,
  startDate,
  endDate,
  page,
  limit
) => {
  try {
    const response = await api.get("/fetchConsignmentsByMultipleCriteria", {
      params: {
        accountId,
        customerId,
        unitId,
        driverId,
        tripStatus,
        startDate,
        endDate,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

/* eslint-disable no-unused-vars */
import { Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
  StyledSwitch2,
  StyledTable2,
  StyledTableBody2,
  StyledTableCell2,
  StyledTableHead2,
  StyledTableRow2,
  StyledUploadContainer,
  StyledUploadIconContainer,
  StyledUploadText,
} from "../../../../styledComponents.js";

import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { iconMap } from "../../../../constants/iconsList.js";
import { StyledFormContainer } from "../../../management/billingPlans/styles/BillingPlansStyles.js";
import { documentColumns } from "../../../../utils/constant.js";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import FormField from "../../../common/FormField.js";
import FormDatepicker from "../../../common/FormDatepicker.js";
import FileLink from "../../../common/FileLink.js";
import FormSelect from "../../../common/FormSelect.js";
dayjs.extend(utc);

const getDocumentTypeOptions = (isFront) => {
  return isFront ? ["Passport Front"] : ["Passport Back"];
};

const DriversForm3 = ({
  driver,
  onFormValueChange,
  validationErrors,
  setValidationErrors,
}) => {
  const today = dayjs();
  const { id } = useParams();
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const [selectedDate, setSelectedDate] = useState(
    driver?.passportExpiryDate ? dayjs(driver.passportExpiryDate) : today
  );

  // Set default passport expiry date
  useEffect(() => {
    onFormValueChange({
      ...driver,
      passportExpiryDate: today,
    });
  }, []);

    // Handle file removal by filtering out the deleted file
    const handleRemoveFile = (index) => {
      if (index >= 0 && index < driver.documentsURL.length) {
        driver.documentsURL.splice(index, 1);
      }
  
      // const updatedDocuments = driver[docType].filter((_, i) => i !== index);
      // const updatedDriver = { ...driver, [docType]: updatedDocuments };
      onFormValueChange(driver, "", "", 3);
    };

  const handleFieldChange = async (name, value) => {
    try {
      let updatedDriver = { ...driver };
      updatedDriver[name] = value;
      onFormValueChange(updatedDriver, name, value, 4); // Pass step index here
    } catch (error) {
      console.error("Error in handleFieldChange:", error);
    }
  };

  const onDrop = (documentType) => (files) => {
    // Check if there are any files to process
    if (files.length === 0) return;

  //   // Create a FileReader to read the file
  //   const reader = new FileReader();
  //   reader.onload = (event) => {
    
  //  if(documentType == "Driver's License Front") {
  //   licenseFrontURL = event.target.result;
  //  }

  //  else if(documentType == "Driver's License Back") {
  //   licenseBackURL = event.target.result;
  //  }
  // }
    
    // Create an object for the new file with the necessary properties
    const updatedFile = {
    fileURL: "",
    file: files[0], // Only allow one file to be uploaded
    isDelete: false,
    documentType,
    driverView: false,
    customerView: false,
    };
    
    // Filter out any existing documents of the same type and add the new file
    const newDocuments = [
    ...driver.documentsURL.filter((doc) => doc.documentType !== documentType),
    updatedFile,
    ];
    
    // Create a new driver object with the updated documents
    const updatedDriver = {
    ...driver,
    documentsURL: newDocuments,
    };
    
    // Call the onFormValueChange function with the updated driver object
    onFormValueChange(updatedDriver, "", "", 4);
    };

  const handleDocumentsChange = (index, key, value) => {
    const updatedDocuments = [...driver.documentsURL];
    updatedDocuments[index] = {
      ...updatedDocuments[index],
      [key]: value,
    };
    const updatedDriver = {
      ...driver,
      documentsURL: updatedDocuments,
    };
    onFormValueChange(updatedDriver, name, value, 3);
  };

  const handlePickupDateChange = (date) => {
    const formattedDate = date
      ? dayjs(date).startOf("day").add(18, "hours").toISOString()
      : dayjs().startOf("day").add(18, "hours").toISOString();

    setSelectedDate(date || dayjs().startOf("day").add(18, "hours"));
    handleFieldChange("passportExpiryDate", formattedDate);
  };

    const renderDocuments = (type, isFront) => {
      const documentTypeOptions = getDocumentTypeOptions(isFront);
  
      // Filter the documents based on the document type
      const filteredDocuments = driver?.documentsURL?.filter(
        (doc) => doc.documentType === documentTypeOptions[0]
      );
  
      // Only render the table if there are filtered documents
      if (filteredDocuments?.length === 0) {
        return null; // No documents to display
      }
  
      return (
        <StyledTable2>
          <StyledTableHead2>
            <StyledTableRow2>
              {documentColumns.map((column, index) => (
                <StyledTableCell2 key={index}>{column.label}</StyledTableCell2>
              ))}
            </StyledTableRow2>
          </StyledTableHead2>
          <StyledTableBody2>
            {filteredDocuments?.map((item) => {
              const actualIndex = driver.documentsURL.findIndex(
                (doc) => doc._id === item._id
              );
  
              return item.fileURL || (item.file && !item.isDelete) ? (
                <StyledTableRow2 key={actualIndex}>
                  <StyledTableCell2>
                    <FileLink
                      href={item.fileURL || item.file.name}
                      fileName={item.fileURL || item.file.name}
                    />
                  </StyledTableCell2>
                  <StyledTableCell2>
                    <FormSelect
                      sx={{
                        marginBottom: "0px",
                        marginTop: "-5px",
                        borderRadius: "16px",
                      }}
                      value={item.documentType || documentTypeOptions[0]}
                      // onChange={(e) =>
                      //   handleDocumentTypeChange(type, actualIndex, "documentType", e)
                      // }
                      options={documentTypeOptions.map((option) => ({
                        value: option,
                        label: option,
                      }))}
                    />
                  </StyledTableCell2>
                  <StyledTableCell2 align="center">
                    <StyledSwitch2
                      checked={item.driverView}
                      onChange={(e) =>
                        handleDocumentsChange(actualIndex, "driverView", e.target.checked)
                      }
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "#0772B8",
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                          backgroundColor: "#0772B8",
                        },
                      }}
                    />
                  </StyledTableCell2>
                  <StyledTableCell2 align="center">
                    <StyledSwitch2
                      checked={item.customerView}
                      onChange={(e) =>
                        handleDocumentsChange(actualIndex, "customerView", e.target.checked)
                      }
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "#0772B8",
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                          backgroundColor: "#0772B8",
                        },
                      }}
                    />
                  </StyledTableCell2>
                  <StyledTableCell2 align="center">
                    <IconButton
                      onClick={() => handleRemoveFile(actualIndex)}
                      sx={{ color: "red" }}
                    >
                      <img src={iconMap["DeleteIcon"]} alt="DeleteIcon" />
                    </IconButton>
                  </StyledTableCell2>
                </StyledTableRow2>
              ) : null;
            })}
          </StyledTableBody2>
        </StyledTable2>
      );
    };

  const {
    getRootProps: getRootPropsPassportBack,
    getInputProps: getInputPropsPassportBack,
  } = useDropzone({
    onDrop: onDrop("Passport Back"),
    accept: "image/*,.pdf,.doc,.xls,.xlsx,.ppt,.pptx",
    multiple: true,
  });
  // Dropzone for Passport Front
  const {
    getRootProps: getRootPropsPassportFront,
    getInputProps: getInputPropsPassportFront,
  } = useDropzone({
    onDrop: onDrop("Passport Front"),
    accept: "image/*,.pdf,.doc,.xls,.xlsx,.ppt,.pptx",
    multiple: true,
  });

  return (
    <StyledFormContainer>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Passport Details</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormField
            label="Passport Number"
            placeholder="Enter Passport number"
            value={driver.passportNumber}
            onChange={(e) =>
              handleFieldChange("passportNumber", e.target.value)
            }
            error={!!validationErrors.passportNumber}
            errorMessage={validationErrors.passportNumber}
          />
          <FormDatepicker
            label="Passport Expiry Date"
            value={selectedDate}
            onChange={handlePickupDateChange}
            open={openDatePicker}
            onOpen={() => setOpenDatePicker(true)}
            onClose={() => setOpenDatePicker(false)}
            error={!!validationErrors.passportExpiryDate}
            errorMessage={validationErrors.passportExpiryDate}
          />
          <div>
            {/* Upload Passport Front */}
            <Typography variant="label">Upload Passport Front</Typography>
            <Grid {...getRootPropsPassportFront()}>
              <input {...getInputPropsPassportFront()} type="file" />
              <StyledUploadContainer>
                <StyledUploadIconContainer>
                  <IconButton>
                    <img src={iconMap["DocUploadIcon"]} alt="DocUploadIcon" />
                  </IconButton>
                </StyledUploadIconContainer>
                <StyledUploadText>
                  Drag and Drop or Browse files
                </StyledUploadText>
              </StyledUploadContainer>
            </Grid>
            <div>
              {renderDocuments("documentsURL", true)}{" "}
              {/* For Driver's Passport Front */}
            </div>

            {/* Upload Passport Back */}
            <Typography variant="label" sx={{ marginTop: "10px" }}>
              Upload Passport Back
            </Typography>
            <Grid {...getRootPropsPassportBack()}>
              <input {...getInputPropsPassportBack()} type="file" />
              <StyledUploadContainer>
                <StyledUploadIconContainer>
                  <IconButton>
                    <img src={iconMap["DocUploadIcon"]} alt="DocUploadIcon" />
                  </IconButton>
                </StyledUploadIconContainer>
                <StyledUploadText>
                  Drag and Drop or Browse files
                </StyledUploadText>
              </StyledUploadContainer>
            </Grid>
            <div>
              {renderDocuments("documentsURL", false)}{" "}
              {/* For Driver's Passport Back */}
            </div>
          </div>
        </StyledSectionRight>
      </StyledFormSection>
    </StyledFormContainer>
  );
};

DriversForm3.propTypes = {
  driver: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  setValidationErrors: PropTypes.func.isRequired,
};

export default DriversForm3;

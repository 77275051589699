/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import SearchFormSelect from "../../../common/SearchFormSelect";
import {
  fetchAllDevices,
  fetchDevicesByMultipleCriteria,
} from "../../../../services/deviceNew.services";
import FilterListIcon from "@mui/icons-material/FilterList";

const DevicesFilters = ({ onFiltersChange }) => {
  const [filters, setFilters] = useState({
    accountId : JSON.parse(localStorage.getItem('defaultAccountId')),
    name: "",
    deviceType: "",
    make: "",
    model: "",
    page : 1,
    limit : 15,
  });

  const [devices, setDevices] = useState([]);
  const [page] = useState(1);
  const [limit] = useState(15);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const accountId =
  localStorage.getItem("defaultAccountId") !== ""
    ? JSON.parse(localStorage.getItem("defaultAccountId"))
    : "";

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters]);

  useEffect(() => {
    _fetchDevicesByMultipleCriteria();
  }, [page, limit]);

  const _fetchDevicesByMultipleCriteria = async (
) => {
    if (isLoadingDevices) return; // Prevent multiple calls
    setIsLoadingDevices(true);

    try {
      let allDevices = [];
      let currentPage = page;
      let totalFetched = 0;
      let name="";
      let deviceType = "";
      let make = "";
      let model = "";
      let page = currentPage;
      // let limit = 15;

      let response;
      let accountId = JSON.parse(localStorage.getItem('defaultAccountId'));
      do {
        response = await fetchDevicesByMultipleCriteria(
          accountId,
          name,
          deviceType,
          make,
          model,
          page,
          limit
        );
        allDevices = [...allDevices, ...response.rows];
        totalFetched += response.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < response.count);

      setDevices(allDevices);
    } catch (error) {
      console.error(
        "Error fetching devices:",
        error?.message || "An unknown error occurred"
      );
    } finally {
      setIsLoadingDevices(false);
    }
  };

  const handleFieldChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  return (
    <>
      <Grid container spacing={1} sx={{ display: "flex" }}>
        <Grid item xs={12} sm={1} sx={{ display: "flex" }}>
          <Grid
            sx={{ marginTop: "20px", color: "#6C6C81", fontWeight: "600" }}
            mr={1}
          >
            FILTERS
          </Grid>
          <FilterListIcon sx={{ marginTop: "20px" }} />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Device Type"
            value={filters.deviceType}
            onChange={(selected) => {
              handleFieldChange("deviceType", selected);
            }}
            options={[
              ...new Map(
                devices.map((item) => [
                  item.deviceType,
                  { value: item.deviceType, label: item.deviceType },
                ])
              ).values(),
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Name"
            value={filters.name}
            onChange={(selected) => {
              handleFieldChange("name", selected);
            }}
            options={devices.map((item) => ({
              value: item.name,
              label: item.name,
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Make"
            value={filters.make}
            onChange={(selected) => {
              handleFieldChange("make", selected);
            }}
            options={[
              ...new Map(
                devices.map((item) => [
                  item.make,
                  { value: item.make, label: item.make },
                ])
              ).values(),
            ]}
          />
        </Grid>

        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Model"
            value={filters.model}
            onChange={(selected) => {
              handleFieldChange("model", selected);
            }}
            options={[
              ...new Map(
                devices.map((item) => [
                  item.model,
                  { value: item.model, label: item.model },
                ])
              ).values(),
            ]}
          />
        </Grid>

        {/* <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Model"
            value={filters.model}
            onChange={(selected) => {
              handleFieldChange("model", selected);
            }}
            options={devices.map((item) => ({
              value: item.model,
              label: item.model,
            }))}
          />
        </Grid> */}
      </Grid>
    </>
  );
};

DevicesFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired, // Validate that onFiltersChange is a required function prop
};

export default DevicesFilters;

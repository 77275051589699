/* eslint-disable no-unused-vars */
// / eslint-disable no-unused-vars /
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ListHeaderNew from "../../../common/ListHeaderNew";
import { Grid } from "@mui/material";
import ReportsFilters from "../components/ReportsFilters";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  fetchEnvironmentalReportsByMultipleCriteria,
  fetchEventsReportsByMultipleCriteria,
  fetchUnitsMonitoringReportsByMultipleCriteria,
} from "../../../../services/unitsMonitoring.service";
import FormSelect from "../../../common/FormSelect";
import { reportTypes } from "../../../../utils/constant";
import Loader from "../../../common/Loader";
import { filter } from "lodash";
import { formatToLocal } from "../../../../utils/functions";
import { fetchConsignmentsByMultipleCriteria } from "../../../../services/consignmentNew.service";

dayjs.extend(utc);

export default function ReportsPage() {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [envReports, setEnvReports] = useState([]);
  const [eventsReports, setEventsReports] = useState([]);
  const [driverRunSheetReports, setDriverRunSheetReports] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [limit] = useState(15);

  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  const [filters, setFilters] = useState({
    driverId: "",
    reportTypes: "",
    accountId: defaultAccountId || "",
    deviceId: "",
    unitId: "",
    startDate: null,
    endDate: null,
    startTime: "",
    endTime: "",
  });

  useEffect(() => {
    if (filters.accountId) {
      _fetchUnitsMonitoringReportsByMultipleCriteria();
    }
  }, [filters, page]);

  const [isEmpty, setIsEmpty] = useState(false);
  const [reportType, setReportType] = useState("Monitoring Report");

  const location = useLocation();
  const shouldShowHeader =
    location.pathname.endsWith("reports") ||
    location.pathname.endsWith("card") ||
    location.pathname.endsWith("list");

  const formatDateRange = (range) => {
    if (!range || range.length !== 2) return { startDate: null, endDate: null };

    const [startDate, endDate] = range;

    const formattedStartDate =
      startDate && dayjs(startDate).isValid()
        ? dayjs(startDate).startOf("day").utc().toISOString()
        : null;

    const formattedEndDate =
      endDate && dayjs(endDate).isValid()
        ? dayjs(endDate).endOf("day").utc().toISOString()
        : null;

    return {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
  };

  useEffect(() => {
    let { startDate, endDate } = formatDateRange([
      filters.startDate,
      filters.endDate,
    ]);

    const startDateValue = filters.startDateTime || startDate;
    const endDateValue = filters.endDateTime || endDate;
    // Fetch reports based on selected report type
    if (reportType === "Monitoring Report") {
      _fetchUnitsMonitoringReportsByMultipleCriteria(
        filters.reportTypes,
        filters.accountId,
        filters.deviceId,
        filters.unitId,
        startDateValue,
        endDateValue,
        page,
        limit
      );
    } else if (reportType === "Environmental Report") {
      _fetchEnvironmentalReportsByMultipleCriteria(
        filters.reportTypes,
        filters.accountId,
        filters.deviceId,
        filters.unitId,
        startDateValue,
        endDateValue,
        page,
        limit
      );
    } else if (reportType === "Events Report") {
      _fetchEventsReportsByMultipleCriteria(
        filters.reportTypes,
        filters.accountId,
        filters.deviceId,
        filters.unitId,
        startDateValue,
        endDateValue,
        page,
        limit
      );
    } else if (reportType === "Driver Run Sheets") {
      _fetchConsignmentsByMultipleCriteria(
        filters.accountId,
        filters.customerId,
        filters.unitId,
        filters.driverId,
        filters.tripStatus,
        startDateValue,
        endDateValue,
        page,
        limit
      );
    }
  }, [page, filters, reportType]);

  const _fetchUnitsMonitoringReportsByMultipleCriteria = async (
    reportTypes,
    accountId,
    deviceId,
    unitId,
    startDate,
    endDate,
    // startTime,
    // endTime,
    page,
    limit
  ) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const response = await fetchUnitsMonitoringReportsByMultipleCriteria(
        reportTypes,
        filters.accountId,
        filters.deviceId,
        filters.unitId,
        filters.startDate,
        filters.endDate,
        // startTime,
        // endTime,
        page,
        limit
      );
      setCount(response.count);
      setReports((prevReports) => [...prevReports, ...response.rows]);
      setIsEmpty(response.rows.length === 0);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const _fetchEnvironmentalReportsByMultipleCriteria = async (
    reportTypes,
    accountId,
    deviceId,
    unitId,
    startDate,
    endDate,
    page,
    limit
  ) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const response = await fetchEnvironmentalReportsByMultipleCriteria(
        reportTypes,
        accountId,
        deviceId,
        unitId,
        startDate,
        endDate,
        page,
        limit
      );
      setCount(response.count);
      setEnvReports((prevReports) => [...prevReports, ...response.rows]);
      setIsEmpty(response.rows.length === 0);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const _fetchEventsReportsByMultipleCriteria = async (
    reportTypes,
    accountId,
    deviceId,
    unitId,
    startDate,
    endDate,
    page,
    limit
  ) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const response = await fetchEventsReportsByMultipleCriteria(
        reportTypes,
        accountId,
        deviceId,
        unitId,
        startDate,
        endDate,
        page,
        limit
      );
      setCount(response.count);
      setEventsReports((prevReports) => [...prevReports, ...response.rows]);
      setIsEmpty(response.rows.length === 0);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const _fetchConsignmentsByMultipleCriteria = async (
    accountId = "",
    customerId = "",
    unitId = "",
    driverId = "",
    tripStatus = "",
    startDate,
    endDate,
    page,
    limit
  ) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      const response = await fetchConsignmentsByMultipleCriteria(
        accountId,
        customerId,
        unitId,
        driverId,
        tripStatus,
        startDate,
        endDate,
        page,
        limit
      );

      // Filter the data by driverId if it exists
      const filteredRows = driverId
        ? response.rows.filter((row) => row.driverId === driverId)
        : response.rows;

      setCount(filteredRows.length);
      setDriverRunSheetReports((prevReports) => [
        ...prevReports,
        ...filteredRows,
      ]);
      setIsEmpty(filteredRows.length === 0);
    } catch (error) {
      console.error("Error fetching consignments:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltersChange = (newFilters) => {
    console.log("newfilters", newFilters);
    setFilters(newFilters);
    setReports([]); // Clear previous monitoring reports
    setEnvReports([]); // Clear previous environmental reports
    setEventsReports([]); // Clear previous events reports
    setDriverRunSheetReports([]); // Clear previous driver run sheet reports
    setPage(1); // Reset page to 1 when filters change
  };

  const fetchMore = () => {
    if (reports.length < count) {
      setPage((prevPage) => prevPage + 1);
    } else {
      console.log("All reports have been fetched.");
    }
  };

  return (
    <div className="container">
      {shouldShowHeader && (
        <Grid container flexDirection="column">
          <Grid item xs={12}>
            <ListHeaderNew name="Report" mode="Create" />
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{
              marginTop: "30px",
              marginBottom: "30px",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={2}>
              <FormSelect
                label="Choose Report Type"
                value={reportType}
                onChange={(e) => setReportType(e)} // Update state with selected value
                options={reportTypes.map((item) => ({
                  value: item.value,
                  label: `${item.name}`,
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              <ReportsFilters
                filters={filters}
                onFiltersChange={handleFiltersChange}
                reportType={reportType}
                setReportType={setReportType}
              />
            </Grid>
            {isLoading && <Loader />}
          </Grid>
        </Grid>
      )}
      <Outlet
        context={{
          reports,
          setReports,
          envReports,
          eventsReports,
          fetchMore,
          isLoading,
          isEmpty,
          reportType,
          setReportType,
          driverRunSheetReports,
          setDriverRunSheetReports,
          filters,
          setFilters,
          hasMoreData,
        }}
      />
    </div>
  );
}

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Modal,
  Grid
} from '@mui/material'
import { modules } from '../../../utils/constant' // Import the module with navigation modules
import logo1 from '../../../../src/assets/logo/logoLight.png'
// import { useSelector } from 'react-redux';
import styled from '@emotion/styled'
import {
  // CancelButton,
  // DeleteButton,
  ModalContent,
  // ModalFooter,
  // ModalHeader,
  StyledModal
} from '../../../styledComponents'
import { iconMap } from '../../../constants/iconsList'
import {
  fetchAllAccounts,
  getAccountById
} from '../../../services/accountNew.service'
// import { Notifications } from '@mui/icons-material'
import NotificationBell from '../NotificationBell'
// import { messaging, onMessage } from '../../../services/firebase/firebase'
import {
  // createNotification,
  // fetchNotification
} from '../../../services/notification.services'
// import { useLogMessages } from '../../common/WebsocketContext'
// import LeftNavBar from './LeftNavBar'

const AppBarStyled = styled(AppBar)`
  background-image: linear-gradient(91deg, #1a1946 61%, #0772b8 135%);
  height: 72px;
`

const ToolbarStyled = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: isMobile ? '0px' : '12px 40px',
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

const LogoImage = styled.img`
  height: 38px;
  width: auto;
`

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`

const NavigationBar = ({ shouldShowNavBars, LeftNavBar }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  // const [anchorNotificationEl, setAnchorNotificationEl] = useState(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const defaultAccountId =
    localStorage.getItem('defaultAccountId') != ''
      ? JSON.parse(localStorage.getItem('defaultAccountId'))
      : ''
  const [openModal, setOpenModal] = useState(false)
  const [switchAccount, setSwitchAccount] = useState('')
  const [allAccounts, setAllAccounts] = useState()
  const navigate = useNavigate()
  const [accountDetails, setAccountDetails] = useState()
  const [notifications, setNotifications] = useState([])
  const [unreadCount, setUnreadCount] = useState(0)
  // const { logMessages } = useLogMessages()
  useEffect(() => {
    if (defaultAccountId !== null) {
      getAccountId()
    }
  }, [])
  // useEffect(() => {
  //   // if (logMessages.length > 0) {
  //   getNotifications()
  //   // }
  // }, [logMessages])
  // useEffect(() => {
  //   getNotifications()

  //   onMessage(messaging, (payload) => {
  //     setNotifications((prev) => [
  //       { ...payload.notification, read: false },
  //       ...prev
  //     ])
  //     // sendNotification(payload)
  //     setUnreadCount((prev) => prev + 1)
  //   })
  // }, [])
  // const getNotifications = async () => {
  //   try {
  //     const response = await fetchNotification() // Your API endpoint
  //     const fetchedNotifications = response.rows // Adjust based on your API response
  //     setNotifications(fetchedNotifications)
  //     setUnreadCount(
  //       fetchedNotifications?.filter((notif) => !notif.read).length
  //     )
  //   } catch (error) {
  //     console.error('Error fetching notifications:', error)
  //   }
  // }
  // const sendNotification = async (payload) => {
  //   try {
  //     await createNotification(payload)
  //   } catch (err) {
  //     console.log(err.stack)
  //   }
  // }

  const getAccountId = async () => {
    try {
      const res = await getAccountById(defaultAccountId)
      setAccountDetails(res)
    } catch (error) {
      console.error('Error fetching account details:', error)
      // Handle the error, such as displaying an error message to the user
    }
  }
  // const userData = useSelector((state) => state.user.userData);
  var userData = localStorage.getItem('userData')
  if (userData) {
    userData = JSON.parse(userData).user
  } else {
    // Handle the case where 'userData' is null
    console.log("'userData' is null or undefined")
  }

  const location = useLocation()

  // Filter out the "Login" route
  // const filteredRoutes = routes.filter((route) => route.label !== 'Login');
  const [shouldShowMainNavContent, setShouldShowMainNavContent] = useState(true)

  useEffect(() => {
    setShouldShowMainNavContent(!['/home'].includes(location.pathname))
  }, [location])

  useEffect(() => {
    if (userData?.accessAllAccounts === true) {
      getAllAccounts()
    }
  }, [])

  const getAllAccounts = async () => {
    try {
      const res = await fetchAllAccounts()
      setAllAccounts(res.rows)
    } catch (error) {
      console.error('Error fetching all accounts:', error)
      // Handle the error, such as displaying an error message to the user
    }
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  // const handleNotificationOpen = (event) => {
  //   setAnchorNotificationEl(event.currentTarget)
  // }

  // const handleNotificationClose = () => {
  //   setAnchorNotificationEl(null)
  // }

  const handleLogout = () => {
    // Handle logout logic here
    navigate('/')
    // Retrieve the stored user data
    const storedUserData = localStorage.getItem('storedUserData')

    // Iterate through localStorage items
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)

      // Check if the key is not 'storedUserData'
      if (key !== 'storedUserData') {
        // Remove the item from localStorage
        localStorage.removeItem(key)
      }
    }

    // Set back the storedUserData if it exists
    if (storedUserData) {
      localStorage.setItem('storedUserData', storedUserData)
    }
  }

  // const accountSection = (
  //   <div>
  //     <ListItem>
  //       <ListItemIcon>{/* <AccountsIcon /> */}</ListItemIcon>
  //       <ListItemText primary={userData?.accountId} />
  //     </ListItem>
  //     <ListItem>
  //       <ListItemIcon>{/* <UsersIcon /> */}</ListItemIcon>
  //       <ListItemText
  //         primary={userData?.userName}
  //         onClick={handleMenuOpen} // Open menu on click of userName
  //         sx={{ cursor: 'pointer' }}
  //       />
  //       <Menu
  //         id="menu"
  //         anchorEl={anchorEl}
  //         open={Boolean(anchorEl)}
  //         onClose={handleMenuClose}
  //       >
  //         <MenuItem onClick={handleMenuClose}>Change Account</MenuItem>
  //         <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
  //         <MenuItem onClick={handleLogout}>Logout</MenuItem>
  //       </Menu>
  //     </ListItem>
  //   </div>
  // )

  const logo = (
    <LogoContainer>
      <LogoImage src={logo1} alt="Logo" />
    </LogoContainer>
  )

  const handleAccountSwitch = (newAccount) => {
    // Implement logic to switch accounts here
    setSwitchAccount(newAccount)
    localStorage.setItem('accountDetails', JSON.stringify(newAccount));
    handleMenuClose()
    setOpenModal(true)
  }

  useEffect(() => {}, [switchAccount])

  const handleChangeModalClose = () => {
    setOpenModal(false)
  }

  const changeDefaultAccount = () => {
    localStorage.setItem(
      'defaultAccountId',
      JSON.stringify(
        userData.accessAllAccounts == false
          ? switchAccount.accountId
          : switchAccount.id
      )
    )
    // getAccountId();
    setOpenModal(false)
    window.location.reload()
  }

  return (
    <div>
      <AppBarStyled position="static">
        <ToolbarStyled>
          {LeftNavBar && <LeftNavBar />}
          {logo}
          {isDesktop && (
            <MenuContainer>
              {shouldShowMainNavContent &&
                modules.map((module, index) => (
                  <Button
                    key={index}
                    component={Link}
                    to={module.link}
                    sx={{
                      color: '#8e8ea2',
                      fontFamily: 'Archivo',
                      fontWeight: 'medium',
                      fontSize: '14px',
                      lineHeight: '18.9px',
                      '&:hover': {
                        color: 'white'
                      }
                    }}
                  >
                    {module.icon2}
                    {/* <img src={module.icon} alt={module.name} /> */}
                    {module.name}
                  </Button>
                ))}
            </MenuContainer>
          )}
          <div>
            {userData && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <Typography
                  variant="body1"
                  color="inherit"
                  sx={{ marginRight: '10px' }}
                >
                  {userData.accountId}
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <NotificationBell
                    notifications={notifications}
                    unreadCount={unreadCount}
                    setNotifications={setNotifications}
                    setUnreadCount={setUnreadCount}
                  />
                  <Button
                    aria-controls="menu"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    color="inherit"
                    endIcon={
                      <Box sx={{ fontSize: '10px', marginTop: '-3px' }}>
                        &#x25be;
                      </Box>
                    }
                    sx={{
                      fontFamily: 'Archivo',
                      fontWeight: 'bold',
                      fontSize: '11px',
                      lineHeight: '14.9px',
                      letterSpacing: '0.44px',
                      textTransform: 'uppercase',
                      color: '#bce2fb' // Color/Primary/200
                    }}
                  >
                    <Box>
                      <div>{userData.userName}</div>
                      <div>{accountDetails?.name}</div>
                    </Box>
                  </Button>
                  <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    PaperProps={{
                      style: {
                        width: isMobile ? '100%' : '20%', // Adjust width as needed
                        borderRadius: '8px', // Adjust border radius as needed
                        padding: '10px 0',
                        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)' // Example boxShadow
                      }
                    }}
                  >
                    <MenuItem disabled>
                      <Typography variant="subTitle">
                        SWITCH ACCOUNTS
                      </Typography>
                    </MenuItem>
                    {userData.accessAllAccounts == false
                      ? userData.accessRights?.map((account) => (
                          <MenuItem
                            key={account.id}
                            onClick={() => handleAccountSwitch(account)}
                            sx={{ padding: '16px 25px' }}
                          >
                            <Grid
                              container
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <Grid sx={{ display: 'flex' }}>
                                <Grid
                                  sx={{
                                    backgroundColor: '#eeeef0',
                                    marginRight: '10px',
                                    borderRadius: '8px',
                                    width: '40px',
                                    height: '40px'
                                  }}
                                >
                                  <img
                                    src={iconMap['Notifications']}
                                    alt="alertIcon"
                                    style={{
                                      margin: '8px',
                                      width: '25px',
                                      height: '25px'
                                    }}
                                  />
                                </Grid>
                                <Grid>
                                  <Typography variant="cardValue">
                                    {account.accountName}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: '11px',
                                      color: '#6c6c81',
                                      fontWeight: 'bold',
                                      backgroundColor: '#eeeef0',
                                      padding: '0 10px',
                                      marginTop: '3px',
                                      borderRadius: '2px'
                                    }}
                                  >
                                    {account.role}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid>
                                {account.accountId == defaultAccountId && (
                                  <Grid
                                    sx={{
                                      marginLeft: '10px',
                                      width: '40px',
                                      height: '40px'
                                    }}
                                  >
                                    <img
                                      src={iconMap['CHECKMARKICON']}
                                      alt="alertIcon"
                                      style={{
                                        margin: '8px',
                                        width: '25px',
                                        height: '25px'
                                      }}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </MenuItem>
                          // )
                        ))
                      : allAccounts?.map((account) => (
                          <MenuItem
                            key={account.id}
                            onClick={() => handleAccountSwitch(account)}
                            sx={{ padding: '16px 25px' }}
                          >
                            <Grid
                              container
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <Grid sx={{ display: 'flex' }}>
                                <Grid
                                  sx={{
                                    backgroundColor: '#eeeef0',
                                    marginRight: '10px',
                                    borderRadius: '8px',
                                    width: '40px',
                                    height: '40px'
                                  }}
                                >
                                  <img
                                    src={iconMap['Notifications']}
                                    alt="alertIcon"
                                    style={{
                                      margin: '8px',
                                      width: '25px',
                                      height: '25px'
                                    }}
                                  />
                                </Grid>
                                <Grid>
                                  <Typography variant="cardValue">
                                    {account.name}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: '11px',
                                      color: '#6c6c81',
                                      fontWeight: 'bold',
                                      backgroundColor: '#eeeef0',
                                      padding: '0 10px',
                                      marginTop: '3px',
                                      borderRadius: '2px'
                                    }}
                                  >
                                    {userData.accessRights[0].role}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid>
                                {account.id == defaultAccountId && (
                                  <Grid
                                    sx={{
                                      marginLeft: '10px',
                                      width: '40px',
                                      height: '40px'
                                    }}
                                  >
                                    <img
                                      src={iconMap['CHECKMARKICON']}
                                      alt="alertIcon"
                                      style={{
                                        margin: '8px',
                                        width: '25px',
                                        height: '25px'
                                      }}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </MenuItem>
                          // )
                        ))}
                    <hr
                      style={{
                        margin: '2% 6%',
                        borderTop: '1px solid #d0d5dd'
                      }}
                    />
                    <MenuItem disabled>
                      <Typography variant="subTitle">OTHER OPTIONS</Typography>
                    </MenuItem>
                    <MenuItem>
                      <Grid
                        container
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Grid>
                          <img
                            src={iconMap['SETTINGSICON']}
                            alt="alertIcon"
                            style={{
                              margin: '8px',
                              width: '25px',
                              height: '25px'
                            }}
                          />
                        </Grid>
                        <Grid sx={{ marginBottom: '5px' }}>
                          <Typography variant="cardValue">Settings</Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                    <hr
                      style={{
                        margin: '2% 6%',
                        borderTop: '1px solid #d0d5dd'
                      }}
                    />
                    <MenuItem disabled>
                      <Typography variant="subTitle">LOGOUT</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleLogout()}>
                      <Grid
                        container
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Grid>
                          <img
                            src={iconMap['LOGOUTICON']}
                            alt="alertIcon"
                            style={{
                              margin: '8px',
                              width: '25px',
                              height: '25px'
                            }}
                          />
                        </Grid>
                        <Grid sx={{ marginBottom: '5px' }}>
                          <Typography variant="cardValue">
                            Logout from all accounts
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            )}
          </div>
        </ToolbarStyled>
      </AppBarStyled>
      {shouldShowNavBars && <LeftNavBar />}
      <Modal
        open={openModal}
        onClose={(e) => handleChangeModalClose(e)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <StyledModal>
          <Typography
            sx={{
              fontSize: '26px',
              fontWeight: 600,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '10px'
            }}
            id="child-modal-title"
          >
            Switch Account?
          </Typography>
          <ModalContent>
            <Box sx={{ marginBottom: '15px' }}>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: 'center',
                  flexDirection: 'column'
                }}
              >
                Are you sure you want to switch to account{' '}
                <strong>{switchAccount.name}</strong>?
              </Typography>
            </Box>
            <hr />
            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
              <Button
                onClick={(e) => changeDefaultAccount(e)}
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  backgroundColor: 'green',
                  color: '#ffffff',
                  margin: '10px 0px 0px 0px',
                  '&:hover': {
                    backgroundColor: 'green'
                  }
                }}
              >
                YES, SWITCH
              </Button>
              <Button
                onClick={(e) => handleChangeModalClose(e)}
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#6c6c81',
                  border: '1px solid #d8d8df',
                  marginTop: '10px'
                }}
              >
                CANCEL
              </Button>
            </Grid>
          </ModalContent>
        </StyledModal>
      </Modal>
    </div>
  )
}

export default NavigationBar

/* eslint-disable no-unused-vars */
// React core imports
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

// Material-UI imports
import { Grid, Menu, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import debounce from "lodash.debounce";

// Styled components and utility functions
import {
  StyledListCard,
  StyledListCardHeader,
  StyledIconButton,
  FlexContainerSB,
  StyledListCardContent,
  StyledMenuItem,
  StyledListCardContainer,
  CardTitle,
} from "../../../../styledComponents";

// Modal components
import DeleteModal from "../../../modals/DeleteModal";

// Service and common components
import Loader from "../../../common/Loader";
import EmptyState from "../../../common/EmptyState";
import { deleteUnit } from "../../../../services/serviceNew.services";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";

const ServicesListCard = () => {
  const [errors, setErrors] = useState([]);
  // Access the searchResults from the context
  // Used to set state that tracks the currently opened menu by account ID on open and close 3 dots menu option
  const [openMenuServiceId, setOpenMenuServiceId] = useState(null);
  // This is for the delete modal
  const [open, setOpen] = useState(false);
  // const [accounts, setAccounts] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);

  // Hooks for navigation and outlet context
  const navigate = useNavigate();
  const { services, getServices, fetchMore, isLoading, isEmpty } =
    useOutletContext();

  // Ref for the container to attach scroll event
  const containerRef = useRef(null);

  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  // Use effect to listen for shouldReload changes
  useEffect(() => {
    if (shouldReload) {
      // getAccounts();
      window.location.reload();
    }
  }, [shouldReload, getServices]);

  // Attach the scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    // Remove the event listener on cleanup
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [services, isLoading, handleScroll]); // Include dependencies that affect the event listener
  // Function to open the menu on click of 3 dots in the list cards/tables
  // Callbacks wrapped with useCallback for performance optimization

  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    setOpenMenuServiceId(selectedItem.id); // Set the ID of the account whose menu should be open
    setSelectedItem(selectedItem);
  }, []);

  // Function to close the menu on selecting any option from the menu
  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuServiceId(null); // Set the ID of the account whose menu should be open
  }, []);

  //Delete Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleDeleteModalOpen = useCallback(
    (event, accountId) => {
      handleActionsMenuClose();
      setSelectedItem(accountId);
      setOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setOpen]
  );

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setOpen]);

  // Function that calls the API to perform deleteAccount
  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
    //   const response = await deleteUnit(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, 'Unit deleted successfully.')
    //     handleError([])
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error)
    // } finally {
    //   setOpen(false);
    //   setSelectedItem(null);
    // }
  };

  //Function to navigate to the edit screen on click of the card
  const navigateToEditScreen = useCallback(
    (accountId) => {
      // const tab = "details";
      navigate(`/management/services/edit/${accountId}`);
      // Your navigation logic here
    },
    [navigate]
  );

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };
  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
    }
  };
  const handleError = (error) => {
    window.scrollTo(0, 0);
    // if (error.name === 'ValidationError') {
    //   handleValidationErrors(error)
    // } else {
    setErrors(error.error);
    // }
  };

  return (
    <>
      <Grid sx={{ marginTop: "50px" }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledListCardContainer container ref={containerRef} spacing={2}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="SERVICES"
              message="Oops! You have no services for your search criteria"
              info="Please feel free to create a new service by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {services?.map((service) => (
          <Grid item xs={12} sm={6} md={4} key={service.id}>
            <StyledListCard>
              <StyledListCardHeader
                title={
                  <CardTitle
                    variant="cardTitle1"
                    sx={{
                      display: "flex",
                      alignSelf: "center",
                      flexDirection: "column",
                    }}
                  >
                    {service.name}
                    <Typography variant="cardTitle2">
                      {service.accountName}
                    </Typography>
                  </CardTitle>
                }
                action={
                  <Grid>
                    <StyledIconButton
                      id={`menu-button-${service.id}`}
                      onClick={(event) => handleActionsMenuOpen(event, service)}
                    >
                      <MoreVertIcon />
                    </StyledIconButton>
                    <Menu
                      anchorEl={document.getElementById(
                        `menu-button-${service.id}`
                      )}
                      open={openMenuServiceId === service.id} // The menu is open if the openMenuServiceId
                      onClose={(event) => handleActionsMenuClose(event)}
                    >
                      <StyledMenuItem
                        onClick={(e) => handleDeleteModalOpen(e, service)}
                      >
                        <Grid sx={{ display: "flex", alignItems: "block" }}>
                          <DeleteOutlineIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          <Typography> Delete Service</Typography>
                        </Grid>
                      </StyledMenuItem>
                    </Menu>
                  </Grid>
                }
              />
              <StyledListCardContent
                onClick={() => navigateToEditScreen(service.id)}
              >
                <Grid>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Type</Typography>
                    <Typography variant="cardValue">{service.type}</Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Field Type</Typography>
                    <Typography variant="cardValue">
                      {service.fieldType}
                    </Typography>
                  </FlexContainerSB>
                </Grid>
              </StyledListCardContent>
            </StyledListCard>
          </Grid>
        ))}
      </StyledListCardContainer>

      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default ServicesListCard;

import React from 'react';
import {
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { iconMap } from '../../../../constants/iconsList';
import { documentTypeOptions, otherDocumentTypeOptions } from '../../../../utils/constant';
import {
  StyledTable2,
  StyledTableBody2,
  StyledTableHead2,
  StyledTableRow2,
  StyledUploadContainer,
  StyledUploadIconContainer,
  StyledUploadText,
  StyledTableCell2,
  StyledSwitch2,
  StyledFormContainer1,
  StyledFormSection1,
  StyledSectionHeader,
} from '../../../../styledComponents';
import FormSelect from '../../../common/FormSelect';
import FileLink from '../../../common/FileLink';
import { useParams } from "react-router-dom";

const ConsignmentDocumentsForm = ({
  consignment,
  onFormValueChange,
  // eslint-disable-next-line no-unused-vars
  validationErrors,
}) => {

  const { id } = useParams();
  // Handle file drop and add to the appropriate document type
  const handleFileDrop = (documentCategory) => (files) => {
    // Check if there are any files to process
    if (files.length === 0) return;

    const defaultDocumentType = documentCategory === 'otherDocuments' ? 'License' : 'Insurance';

    const newFiles = files.map((file) => {
      return {
        file,
        fileURL: '',
        documentType: defaultDocumentType,
        driverView: false,
        customerView: false,
      };
    });

    const updatedConsignment = {
      ...consignment,
      [documentCategory]: [
        ...(consignment[documentCategory] || []), // Preserve existing files
        ...newFiles, // Add new files
      ],
    };

    onFormValueChange(updatedConsignment, '', '', 2);
    console.log("Updated consignment:", updatedConsignment);
  };

  // Handle file removal by filtering out the deleted file
  const handleRemoveFile = (documentCategory, index) => {
    if (id) {
      if (documentCategory === 'insuranceAndCustomsDocuments') {
        consignment.insuranceAndCustomsDocuments[index].isDelete = true;
      }
      else {
        consignment.otherDocuments[index].isDelete = true;
      }
    }
    else {
      if (documentCategory === 'insuranceAndCustomsDocuments') {
        consignment.insuranceAndCustomsDocuments.splice(index, 1);
      }
      else {
        consignment.otherDocuments.splice(index, 1);
      }
    }
    onFormValueChange(consignment, "", "", 2);
  };

  const handleDocumentsChange = (index, key, value, documentCategory) => {
    let updatedDocuments = [];

    if (documentCategory === "insuranceAndCustomsDocuments") {
      updatedDocuments = [...consignment.insuranceAndCustomsDocuments];
    } else if (documentCategory === "otherDocuments") {
      updatedDocuments = [...consignment.otherDocuments];
    }

    updatedDocuments[index] = {
      ...updatedDocuments[index],
      [key]: value,
    };

    const updatedDriver = {
      ...consignment,
      [documentCategory]: updatedDocuments,
    };

    onFormValueChange(updatedDriver, "", value, 3);
  };

  const renderDocumentTable = (documentCategory, documentOptions) => {
    let filteredDocuments = [];

    if (documentCategory === "insuranceAndCustomsDocuments") {
      filteredDocuments = consignment?.insuranceAndCustomsDocuments
    } else if (documentCategory === "otherDocuments") {
      filteredDocuments = consignment?.otherDocuments
    }

    return (
      <StyledTable2>
        <StyledTableHead2>
          <StyledTableRow2>
            <StyledTableCell2>ATTACHMENTS</StyledTableCell2>
            <StyledTableCell2>DOCUMENT TYPE</StyledTableCell2>
            <StyledTableCell2 sx={{ textAlign: 'center' }}>DRIVER</StyledTableCell2>
            <StyledTableCell2 sx={{ textAlign: 'center' }}>CUSTOMER</StyledTableCell2>
            <StyledTableCell2 sx={{ textAlign: 'center' }}>ACTION</StyledTableCell2>
          </StyledTableRow2>
        </StyledTableHead2>
        <StyledTableBody2>
          {filteredDocuments?.map((item, index) => (
            <StyledTableRow2 key={index}>
              <StyledTableCell2 sx={{ width: '25%' }}>
                <FileLink
                  href={item.fileURL || item.file.name}
                  fileName={item.fileURL || item.file.name}
                />
              </StyledTableCell2>
              <StyledTableCell2>
                <FormSelect
                  sx={{ marginBottom: '0px', marginTop: '-5px' }}
                  value={item.documentType || documentOptions[0]}
                  onChange={(selected) => {
                    handleDocumentsChange(index, "documentType", selected, documentCategory);
                  }}
                  options={documentOptions.map(option => ({
                    value: option,
                    label: option,
                  }))}
                />
              </StyledTableCell2>
              <StyledTableCell2 align="center">
                <StyledSwitch2
                  checked={item.driverView}
                  onChange={(e) =>
                    handleDocumentsChange(index, "driverView", e.target.checked, documentCategory)
                  }
                />
              </StyledTableCell2>
              <StyledTableCell2 align="center">
                <StyledSwitch2
                  checked={item.customerView}
                  onChange={(e) =>
                    handleDocumentsChange(index, "customerView", e.target.checked, documentCategory)
                  }
                />
              </StyledTableCell2>
              <StyledTableCell2 align="center">
                <IconButton onClick={() => handleRemoveFile(documentCategory, index)} sx={{ color: 'red' }}>
                  <img src={iconMap['DeleteIcon']} alt="DeleteIcon" />
                </IconButton>
              </StyledTableCell2>
            </StyledTableRow2>
          ))}
        </StyledTableBody2>
      </StyledTable2>
    );
  };

  const {
    getRootProps: getRootPropsInsurance,
    getInputProps: getInputPropsInsurance,
  } = useDropzone({
    onDrop: handleFileDrop('insuranceAndCustomsDocuments'),
    accept: 'image/*,.pdf,.doc,.xls,.xlsx,.ppt,.pptx',
    multiple: true,
  });

  const {
    getRootProps: getRootPropsOther,
    getInputProps: getInputPropsOther,
  } = useDropzone({
    onDrop: handleFileDrop('otherDocuments'),
    accept: 'image/*,.pdf,.doc,.xls,.xlsx,.ppt,.pptx',
    multiple: true,
  });

  return (
    <StyledFormContainer1>
      <StyledFormSection1>
        <StyledSectionHeader>
          <Typography variant="heading">Insurance & Customs Documents</Typography>
        </StyledSectionHeader>
        <Grid>
          <Grid {...getRootPropsInsurance()}>
            <input {...getInputPropsInsurance()} type="file" />
            <StyledUploadContainer>
              <StyledUploadIconContainer>
                <IconButton>
                  <img src={iconMap['DocUploadIcon']} alt="DocUploadIcon" />
                </IconButton>
              </StyledUploadIconContainer>
              <StyledUploadText>Drag and Drop or Browse files</StyledUploadText>
            </StyledUploadContainer>
          </Grid>
          {consignment.insuranceAndCustomsDocuments.length > 0 &&
            <div>
              {renderDocumentTable('insuranceAndCustomsDocuments', documentTypeOptions)}
            </div>
          }
        </Grid>
      </StyledFormSection1>
      <StyledFormSection1>
        <StyledSectionHeader>
          <Typography variant="heading">Other Documents</Typography>
        </StyledSectionHeader>
        <Grid>
          <Grid {...getRootPropsOther()}>
            <input {...getInputPropsOther()} type="file" />
            <StyledUploadContainer>
              <StyledUploadIconContainer>
                <IconButton>
                  <img src={iconMap['DocUploadIcon']} alt="DocUploadIcon" />
                </IconButton>
              </StyledUploadIconContainer>
              <StyledUploadText>Drag and Drop or Browse files</StyledUploadText>
            </StyledUploadContainer>
          </Grid>
          {consignment.otherDocuments.length > 0 &&
            <div>
              {renderDocumentTable('otherDocuments', otherDocumentTypeOptions)}
            </div>
          }

        </Grid>
      </StyledFormSection1>
    </StyledFormContainer1>
  );
};

ConsignmentDocumentsForm.propTypes = {
  consignment: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

export default ConsignmentDocumentsForm;

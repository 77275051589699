/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  Grid,
  Menu,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteModal from "../../../modals/DeleteModal";
import Loader from "../../../common/Loader";
import EmptyState from "../../../common/EmptyState";
import debounce from "lodash.debounce";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import {
  StyledIconButton,
  StyledMenuItem,
  StyledTableContainer,
  StyledTableHead,
} from "../../../../styledComponents";
import { sensorsTableColumns } from "../../../../utils/constant";

const SensorsListTable = () => {
  const [errors, setErrors] = useState([]);
  const [openMenuSensorId, setOpenMenuSensorId] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);

  const navigate = useNavigate();
  const { sensors, fetchMore, isLoading, isEmpty } = useOutletContext();
  const containerRef = useRef(null);

  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  );

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [sensors, isLoading, handleScroll]);

  const handleDeleteModalOpen = useCallback((event, sensor) => {
    handleActionsMenuClose();
    setSelectedSensor(sensor);
    setOpen(true);
  }, []);

  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedSensor(null);
  }, [setSelectedSensor, setOpen]);

  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    setOpenMenuSensorId(selectedItem.id);
    setSelectedSensor(selectedItem);
  }, []);

  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuSensorId(null);
    event?.stopPropagation();
  }, []);


   // Function that calls the API to perform deleteSensor
    const deleteSensor = useCallback(() => {
      setOpen(false);
      // setSelectedSensor(null)
    }, [setOpen, setSelectedSensor]);
  
    useEffect(() => {
      if (selectedSensor) {
        console.log("Selected Service has been set:", selectedSensor);
      }
    }, [selectedSensor]);

  const navigateToEditScreen = useCallback(
    (sensorId) => {
      navigate(`/management/sensors/edit/${sensorId}`);
    },
    [navigate]
  );

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };
  const deleteItem = async () => {
    setOpen(false);
  setSelectedSensor(null);
// try {
//   const response = await deleteSimCard(selectedItem.id);
//   if (response) {
//     handleResponse(response, 'SimCard deleted successfully.')
//     handleError([])
//     // Set the state to reload the page after successful update
//     setShouldReload(true);
//   }
// } catch (error) {
//   handleError(error)
// } finally {
//   setOpen(false);
//   setSelectedItem(null);
// }
};

const handleResponse = (response, successMessage, mode) => {
  window.scrollTo(0, 0)
  if (response) {
    setSuccessMessage(successMessage)
  }
}
const handleError = (error) => {
  window.scrollTo(0, 0)
  // if (error.name === 'ValidationError') {
  //   handleValidationErrors(error)
  // } else {
  setErrors(error.error)
  // }
}

  return (
    <>
      <Grid sx={{ marginTop: "50px" }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledTableContainer container ref={containerRef}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
            name="SENSORS"
            message="Oops! You have no sensors for your search criteria"
            info="Please feel free to create a new sensor by clicking on the button above"
         ></EmptyState>
          </Grid>
        )}
        {!isLoading && !isEmpty && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <StyledTableHead>
                <TableRow>
                  {sensorsTableColumns.map((column) => (
                    <TableCell key={column}>
                      <Typography variant="tableHeader">{column}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {sensors?.map((sensor) => (
                  <TableRow
                    key={sensor.id}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigateToEditScreen(sensor.id)}
                  >
                   <TableCell
                      component="th"
                      scope="row"
                      sx={{ verticalAlign: "top" }}
                    >
                      <Typography variant="tableValue">
                      {sensor?.name ? sensor?.name : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ verticalAlign: "top" }}
                    >
                      <Typography variant="tableValue">
                      {sensor?.sensorType ? sensor?.sensorType : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                      {sensor?.sensorModel ? sensor?.sensorModel : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                      {sensor?.installedLocation ? sensor?.installedLocation : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <StyledIconButton
                        id={`menu-button-${sensor.id}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleActionsMenuOpen(event, sensor);
                        }}
                      >
                        <MoreVertIcon />
                      </StyledIconButton>
                      <Menu
                        anchorEl={document.getElementById(
                          `menu-button-${sensor.id}`
                        )}
                        open={openMenuSensorId === sensor.id} // The menu is open if the openMenuAccountId matches
                        onClose={(event) => handleActionsMenuClose(event)}
                      >
                        <StyledMenuItem
                          onClick={(event) => {
                            event.stopPropagation(); // Prevents the click event from reaching the TableRow
                            handleDeleteModalOpen(event, sensor);
                          }}
                        >
                          <Grid sx={{ display: "flex", alignItems: "center" }}>
                            <DeleteOutlineIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            <Typography>Delete Sensor</Typography>
                          </Grid>
                        </StyledMenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledTableContainer>

      <DeleteModal
              open={open}
              handleDeleteModalClose={handleDeleteModalClose}
              selectedItem={selectedSensor}
              deleteItem={deleteItem}
            />
    </>
  );
};

export default SensorsListTable;

/* eslint-disable no-unused-vars */
// React core imports
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  Grid,
  Menu,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import { formatDate, getUnitsStatusColor } from "../../../../utils/functions";
import DeleteModal from "../../../modals/DeleteModal";
import DeactivateAccountModal from "../../../modals/DeactivateAccountModal";
import ActivateAccountModal from "../../../modals/ActivateAccountModal";
import { deleteUnit, updateUnit } from "../../../../services/unitNew.service";
import Loader from "../../../common/Loader";
import debounce from "lodash.debounce";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import {
  StyledTableHead,
  StyledTableContainer,
  StyledIconButton,
  StyledMenuItem,
} from "../../../../styledComponents";
import { unitTableColumnNew } from "../../../../utils/constant";
import EmptyState from "../../../common/EmptyState";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";

const UnitsListTable = () => {
  const [errors, setErrors] = useState([]);
  const [openMenuUnitId, setOpenMenuUnitId] = useState(null);
  const [open, setOpen] = useState(false);
  const [deactivateOpen, setDeactivateOpen] = useState(false);
  const [activateOpen, setActivateOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);

  const navigate = useNavigate();
  const { units, fetchMore, isLoading, isEmpty } = useOutletContext();
  const containerRef = useRef(null);

  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [units, isLoading, handleScroll]);

  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    event.stopPropagation();
    setOpenMenuUnitId(selectedItem.id); // Set the ID of the account whose menu should be open
    setSelectedItem(selectedItem);
  }, []);

  const handleActionsMenuClose = useCallback((event) => {
    setOpenMenuUnitId(null); // Set the ID of the account whose menu should be open
    event?.stopPropagation();
  }, []);

  const handleDeleteModalOpen = useCallback(
    (event, unitId) => {
      handleActionsMenuClose();
      setSelectedItem(unitId);
      setOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setOpen]
  );

  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setOpen]);

  const handleUpdateUnit = useCallback(
    async (updatedUnit) => {
      try {
        const response = await updateUnit(updatedUnit);
        if (response) {
          setSuccessMessage("Unit updated successfully.");
          setErrorMessage("");
          // Set the state to reload the page after successful update
          setShouldReload(true);
        }
      } catch (error) {
        setErrorMessage(error.message || "An unexpected error occurred");
      } finally {
        // setIsLoading(false);
      }
    },
    [setSuccessMessage, setErrorMessage, setShouldReload, selectedItem]
  );

  const handleActivateModalOpen = useCallback(
    (event, unitId) => {
      handleActionsMenuClose();
      setSelectedItem(unitId);
      setActivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setActivateOpen]
  );

  const handleDeactivateModalOpen = useCallback(
    (event, unitId) => {
      handleActionsMenuClose();
      setSelectedItem(unitId);
      setDeactivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setDeactivateOpen]
  );
  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
    //   const response = await deleteUnit(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, "Unit deleted successfully.");
    //     handleError([]);
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error);
    // } finally {
    //   setOpen(false);
    //   setSelectedItem(null);
    // }
  };

  const handleDeactivateModalClose = useCallback(() => {
    setDeactivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setDeactivateOpen]);

  const deactivateUnit = useCallback(() => {
    const status = "DEACTIVATED";
    const updatedAccount = {
      ...selectedItem,
      status: status,
    };

    setSelectedItem(updatedAccount);
    handleUpdateUnit(updatedAccount);

    // Close the modal
    setDeactivateOpen(false);
    setSelectedItem(null);
  }, [selectedItem, handleUpdateUnit, setDeactivateOpen, setSelectedItem]);

  const handleActivateModalClose = useCallback(() => {
    setActivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setActivateOpen]);

  const activateUnit = useCallback(() => {
    const status = "ACTIVATED";

    const updatedAccount = {
      ...selectedItem,
      status: status,
    };

    setSelectedItem(updatedAccount);
    handleUpdateUnit(updatedAccount);

    setActivateOpen(false);
    setSelectedItem(null);
  }, [selectedItem, handleUpdateUnit, setActivateOpen, setSelectedItem]);

  const navigateToEditScreen = useCallback(
    (unitId) => {
      navigate(`/management/units/edit/${unitId}`);
    },
    [navigate]
  );

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };
  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
    }
  };
  const handleError = (error) => {
    window.scrollTo(0, 0);
    // if (error.name === 'ValidationError') {
    //   handleValidationErrors(error)
    // } else {
    setErrors(error.error);
    // }
  };

  return (
    <>
      <Grid sx={{ marginTop: "50px" }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledTableContainer container ref={containerRef}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="UNITS"
              message="Oops! You have no units for your search criteria"
              info="Please feel free to create a new unit by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {!isLoading && !isEmpty && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <StyledTableHead>
                <TableRow>
                  {unitTableColumnNew.map((column) => (
                    <TableCell key={column}>
                      <Typography variant="tableHeader">{column}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {units?.map((unit) => (
                  <TableRow
                    key={unit.id}
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigateToEditScreen(unit.id)}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ verticalAlign: "top" }}
                    >
                      <Typography variant="tableValue">{unit.name}</Typography>
                      <Typography
                        variant="cardStatus2"
                        sx={{
                          color: getUnitsStatusColor(unit.status),
                        }}
                      >
                        &bull; &nbsp;
                        {unit.status}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {unit.unitType}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {unit?.deviceDetails.name
                          ? unit?.deviceDetails.name
                          : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {unit?.deviceType ? unit?.deviceType : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {unit?.simCardDetails.simCardNumber
                          ? unit?.simCardDetails.simCardNumber
                          : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {unit?.vehicle?.VIN || "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {unit?.vehicle?.licensePlate || "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {unit?.vehicle?.make || "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {unit?.vehicle?.model || "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {unit?.vehicle?.year
                          ? formatDate(unit?.vehicle?.year)
                          : "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Typography variant="tableValue">
                        {unit?.deviceId || "--"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ verticalAlign: "top" }}>
                      <StyledIconButton
                        id={`menu-button-${unit.id}`}
                        onClick={(event) => handleActionsMenuOpen(event, unit)}
                      >
                        <MoreVertIcon />
                      </StyledIconButton>
                      <Menu
                        anchorEl={document.getElementById(
                          `menu-button-${unit.id}`
                        )}
                        open={openMenuUnitId === unit.id} // The menu is open if the openMenuUnitId
                        onClose={(event) => handleActionsMenuClose(event)}
                      >
                        {unit && unit.status === "ACTIVATED" ? (
                          <StyledMenuItem
                            onClick={(event) => {
                              event.stopPropagation(); // Prevents the click event from reaching the TableRow
                              handleDeactivateModalOpen(event, unit);
                            }}
                          >
                            <BlockOutlinedIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            Deactivate Unit
                          </StyledMenuItem>
                        ) : (
                          <StyledMenuItem
                            onClick={(event) => {
                              event.stopPropagation(); // Prevents the click event from reaching the TableRow
                              handleActivateModalOpen(event, unit);
                            }}
                          >
                            <VerifiedUserOutlinedIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            Activate Unit
                          </StyledMenuItem>
                        )}
                        <StyledMenuItem
                          onClick={(e) => handleDeleteModalOpen(e, unit)}
                        >
                          <Grid sx={{ display: "flex", alignItems: "block" }}>
                            <DeleteOutlineIcon
                              sx={{ marginRight: "10px", color: "#0772B8" }}
                            />
                            <Typography> Delete Unit</Typography>
                          </Grid>
                        </StyledMenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledTableContainer>

      <ActivateAccountModal
        activateOpen={activateOpen}
        handleActivateModalClose={handleActivateModalClose}
        selectedItem={selectedItem}
        activateItem={activateUnit}
      />
      <DeactivateAccountModal
        deactivateOpen={deactivateOpen}
        handleDeactivateModalClose={handleDeactivateModalClose}
        selectedItem={selectedItem}
        deactivateItem={deactivateUnit}
      />
      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default UnitsListTable;

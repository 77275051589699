import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledModal2 } from '../../styledComponents';
import GeofencesForm from '../monitoring/geofences/components/GeofencesForm';
const GeofencesFormModal = ({ open, handleGeofencesFormModalClose }) => {

  // Successfully saved or errored the form
  const handleSuccess = () => {
    handleGeofencesFormModalClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleSuccess}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description">
      <StyledModal2>
        <IconButton
          onClick={handleSuccess}
          style={{ alignSelf: 'flex-end' }}
        >
          <CloseIcon />
        </IconButton>
        <GeofencesForm isShortcut={true} onSuccess={handleSuccess}
        />
      </StyledModal2>
    </Modal>
  );
};

// PropTypes validation
GeofencesFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleGeofencesFormModalClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default GeofencesFormModal;

/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Switch, Typography } from '@mui/material'
import { iconMap } from '../../../../constants/iconsList'
import {
  StyledCheckboxButton,
  StyledInputControlContainer
} from '../../../../styledComponents'
import { StyledFormContainer } from '../../billingPlans/styles/BillingPlansStyles'

const UsersForm3 = ({ user, onFormValueChange }) => {
  const checkboxTypes = [
    { name: 'Access', value: true },
    { name: 'No Access', value: false }
  ]
  const [validationErrors, setValidationErrors] = useState({})
  const userAccessRights = user.accessRights[user.accessRights.length - 1]

  // const handleManagementAccessChange = async (access, value, mode) => {
  //   try {
  //     const updatedAccessRights = userAccessRights.permissions.map(
  //       (accessRight) => {
  //         if (accessRight.serviceId === access.serviceId) {
  //           if (mode === 'add') {
  //             return { ...accessRight, add: value }
  //           } else if (mode === 'edit') {
  //             return { ...accessRight, edit: value }
  //           } else if (mode === 'view') {
  //             return { ...accessRight, view: value }
  //           } else if (mode === 'delete') {
  //             return { ...accessRight, delete: value }
  //           }
  //         }
  //         return accessRight
  //       }
  //     )

  //     onFormValueChange({
  //       ...user,
  //       accessRights: user.accessRights.map((accessRight, index) => {
  //         if (index === user.accessRights.length - 1) {
  //           // Update permissions for the last accessRight
  //           return {
  //             ...accessRight,
  //             permissions: updatedAccessRights
  //           }
  //         } else {
  //           // Keep other accessRights unchanged
  //           return accessRight
  //         }
  //       })
  //     })

  //     // If validation is successful, clear the errors
  //     setValidationErrors({})
  //   } catch (error) {
  //     // If validation fails, set the validation errors
  //     const errors = {}
  //     error.inner.forEach((err) => {
  //       errors[err.path] = err.message
  //     })
  //     setValidationErrors(errors)
  //   }
  // }

  // const handleGeneralAccessChange = (option, service) => {
  //   const updatedAccessRights = userAccessRights.permissions.map(
  //     (accessRight) => {
  //       if (accessRight.serviceId === service.serviceId) {
  //         return { ...accessRight, serviceValue: option.value }
  //       }
  //       return accessRight
  //     }
  //   )
  //   onFormValueChange({
  //     ...user,
  //     accessRights: user.accessRights.map((accessRight, index) => {
  //       if (index === user.accessRights.length - 1) {
  //         // Update permissions for the last accessRight
  //         return {
  //           ...accessRight,
  //           permissions: updatedAccessRights
  //         }
  //       } else {
  //         // Keep other accessRights unchanged
  //         return accessRight
  //       }
  //     })
  //   })
  // }

  const renderServiceItems = (serviceType) => {
    return (
      userAccessRights &&
      userAccessRights.permissions
        ?.filter((accessRight) => accessRight.type === serviceType)
        ?.map((accessRight2, index) => (
          <StyledInputControlContainer
            key={index}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {accessRight2.type === 'General' && (
              <>
                <Typography variant="label" sx={{ width: '300px' }}>
                  {accessRight2.type === 'General' && (
                    <img
                      src={iconMap[accessRight2.serviceName]}
                      alt="arrow"
                      style={{ marginRight: '8px' }}
                    />
                  )}
                  {accessRight2.serviceName}
                </Typography>
                <Grid container alignItems="center">
                  <Grid
                    item
                    xs="auto"
                    sx={{
                      backgroundColor: '#f7f7f8',
                      padding: '5px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      borderRadius: '8px'
                    }}
                    spacing={1}
                  >
                    <Grid container spacing={2}>
                      {checkboxTypes.map((option, optionIndex) => (
                        <Grid item key={optionIndex}>
                          <StyledCheckboxButton
                            item
                            disabled={true}
                            variant={
                              accessRight2.serviceValue === option.value
                                ? 'contained'
                                : 'outlined'
                            }
                            // onClick={() =>
                            //   handleGeneralAccessChange(option, accessRight2)
                            // }
                          >
                            {option.name}
                          </StyledCheckboxButton>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {(accessRight2.type === 'Management' ||
              accessRight2.type === 'Monitoring') && (
              <>
                <Grid
                  container
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #D8D8DF',
                    padding: '20px 0px',
                    margin: '-10px',
                    alignItems: 'center'
                  }}
                >
                  <Grid item xs={4}>
                    <Typography item xs={4} variant="label">
                      {accessRight2.serviceName}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Switch
                      item
                      color="white"
                      checked={accessRight2.add === true}
                      disabled={true} 
                      sx={{
                        '& .MuiSwitch-track': {
                          backgroundColor:
                            accessRight2.add === true ? '#e1f0fd' : '#d8d8df'
                        },
                        '& .MuiSwitch-thumb': {
                          backgroundColor:
                            accessRight2.add === true ? '#2f70b0' : 'white'
                        }
                      }}
                      value={accessRight2.add}
                      // onChange={(event) =>
                      //   handleManagementAccessChange(
                      //     accessRight2,
                      //     event.target.checked,
                      //     'add'
                      //   )
                      // }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Switch
                      item
                      color="white"
                      checked={accessRight2.edit === true}
                      disabled={true} 
                      sx={{
                        '& .MuiSwitch-track': {
                          backgroundColor:
                            accessRight2.edit === true ? '#e1f0fd' : '#d8d8df'
                        },
                        '& .MuiSwitch-thumb': {
                          backgroundColor:
                            accessRight2.edit === true ? '#2f70b0' : 'white'
                        }
                      }}
                      value={accessRight2.edit}
                      // onChange={(event) =>
                      //   handleManagementAccessChange(
                      //     accessRight2,
                      //     event.target.checked,
                      //     'edit'
                      //   )
                      // }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Switch
                      item
                      color="white"
                      value={accessRight2.view}
                      checked={accessRight2.view === true}
                      disabled={true} 
                      sx={{
                        '& .MuiSwitch-track': {
                          backgroundColor:
                            accessRight2.view === true ? '#e1f0fd' : '#d8d8df'
                        },
                        '& .MuiSwitch-thumb': {
                          backgroundColor:
                            accessRight2.view === true ? '#2f70b0' : 'white'
                        }
                      }}
                      // onChange={(event) =>
                      //   handleManagementAccessChange(
                      //     accessRight2,
                      //     event.target.checked,
                      //     'view'
                      //   )
                      // }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Switch
                      item
                      color="white"
                      value={accessRight2.delete}
                      checked={accessRight2.delete === true}
                      disabled={true}
                      sx={{
                        '& .MuiSwitch-track': {
                          backgroundColor:
                            accessRight2.delete === true ? '#e1f0fd' : '#d8d8df'
                        },
                        '& .MuiSwitch-thumb': {
                          backgroundColor:
                            accessRight2.delete === true ? '#2f70b0' : 'white'
                        }
                      }}
                      // onChange={(event) =>
                      //   handleManagementAccessChange(
                      //     accessRight2,
                      //     event.target.checked,
                      //     'delete'
                      //   )
                      // }
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </StyledInputControlContainer>
        ))
    )
  }

  return (
    <StyledFormContainer>
      <Grid container>
        {Object.entries(validationErrors).map(([field, message]) => (
          <Grid key={field} item xs={12}>
            <Typography variant="error">{message}</Typography>
          </Grid>
        ))}
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingRight: '50px'
          }}
        >
          <Typography variant="heading">General</Typography>
        </Grid>
        <Grid item xs={12} md={7} sx={{ borderBottom: '1px solid #d8d8df' }}>
          {renderServiceItems('General')}
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: '25px' }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingRight: '50px'
          }}
        >
          <Typography variant="heading">Management</Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#f7f7f8',
              paddingTop: '6.5px',
              paddingBottom: '6.5px',
              marginBottom: '10px',
              color: '#5B5B6E',
              fontSize: '14px',
              fontWeight: '600'
            }}
          >
            <Grid item xs={4}></Grid>
            <Grid item container xs={2}>
              <img
                src={iconMap['PlusIcon']}
                alt="PlusIcon"
                style={{ marginRight: '8px', height: '16px' }}
              />
              Add
            </Grid>
            <Grid item container xs={2}>
              <img
                src={iconMap['EditIcon']}
                alt="EditIcon"
                style={{ marginRight: '8px', height: '16px' }}
              />
              Edit
            </Grid>
            <Grid item container xs={2}>
              <img
                src={iconMap['EyeIcon']}
                alt="EyeIcon"
                style={{ marginRight: '8px', height: '16px' }}
              />
              View
            </Grid>
            <Grid item container xs={2}>
              <img
                src={iconMap['UserflowDeleteIcon']}
                alt="UserflowDeleteIcon"
                style={{ marginRight: '8px', height: '16px' }}
              />
              Delete
            </Grid>
          </Grid>
          {renderServiceItems('Management')}
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: '25px' }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingRight: '50px'
          }}
        >
          <Typography variant="heading">Monitoring</Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#f7f7f8',
              paddingTop: '6.5px',
              paddingBottom: '6.5px',
              marginBottom: '10px',
              color: '#5B5B6E',
              fontSize: '14px',
              fontWeight: '600'
            }}
          >
            <Grid item xs={4}></Grid>
            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={iconMap['PlusIcon']}
                alt="PlusIcon"
                style={{ marginRight: '8px', height: '16px' }}
              />
              Add
            </Grid>
            <Grid item container xs={2}>
              <img
                src={iconMap['EditIcon']}
                alt="EditIcon"
                style={{ marginRight: '8px', height: '16px' }}
              />
              Edit
            </Grid>
            <Grid item container xs={2}>
              <img
                src={iconMap['EyeIcon']}
                alt="EyeIcon"
                style={{ marginRight: '8px', height: '16px' }}
              />
              View
            </Grid>
            <Grid item container xs={2}>
              <img
                src={iconMap['UserflowDeleteIcon']}
                alt="UserflowDeleteIcon"
                style={{ marginRight: '8px', height: '16px' }}
              />
              Delete
            </Grid>
          </Grid>
          {renderServiceItems('Monitoring')}
        </Grid>
      </Grid>
    </StyledFormContainer>
  )
}

UsersForm3.propTypes = {
  user: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired
}

export default UsersForm3

import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import SearchFormSelect from "../../../common/SearchFormSelect";
import { fetchServicesByMultipleCriteria } from "../../../../services/serviceNew.services";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ServiceType } from "../../../../utils/constant";
import debounce from "lodash/debounce"; // Import debounce

const ServicesFilters = ({ onFiltersChange }) => {
  const [filters, setFilters] = useState({
    id: "",
    type: "",
  });

  const [services, setServices] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(15);
  const [isLoadingServices, setIsLoadingServices] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);

  useEffect(() => {
    onFiltersChange(filters);
    resetPaginationAndFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const resetPaginationAndFetch = () => {
    setPage(1);
    setServices([]);
    setHasMoreData(true);
    _fetchServicesByMultipleCriteria(1, filters.id, filters.type, true);
  };

  const _fetchServicesByMultipleCriteria = useCallback(
    async (currentPage, id, type, isNewFilter = false) => {
      if (isLoadingServices || (!hasMoreData && !isNewFilter)) return;

      setIsLoadingServices(true);

      try {
        const response = await fetchServicesByMultipleCriteria(
          id,
          type,
          currentPage,
          limit
        );

        if (response.rows.length < limit) {
          setHasMoreData(false);
        }

        setServices((prevServices) =>
          isNewFilter ? response.rows : [...prevServices, ...response.rows]
        );

        if (!isNewFilter) {
          setPage(currentPage); // Update page state
        }
      } catch (error) {
        console.error(
          "Error fetching services:",
          error?.message || "An unknown error occurred"
        );
      } finally {
        setIsLoadingServices(false);
      }
    },
    [isLoadingServices, hasMoreData, limit]
  );

  const handleFieldChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  // Debounced scroll handler
  const handleScrollEnd = useCallback(
    debounce(() => {
      if (!isLoadingServices && hasMoreData) {
        const nextPage = page + 1;
        _fetchServicesByMultipleCriteria(nextPage, filters.id, filters.type);
      }
    }, 300), // Debounce with a delay of 300ms
    [isLoadingServices, hasMoreData, page, filters.id, filters.type]
  );

  useEffect(() => {
    if (page > 1) {
      _fetchServicesByMultipleCriteria(page, filters.id, filters.type);
    }
  }, [page, filters.id, filters.type]);

  return (
    <Grid container spacing={1} sx={{ display: "flex" }}>
      <Grid item xs={12} sm={1} sx={{ display: "flex" }}>
        <Grid
          sx={{ marginTop: "20px", color: "#6C6C81", fontWeight: "600" }}
          mr={1}
        >
          FILTERS
        </Grid>
        <FilterListIcon sx={{ marginTop: "20px" }} />
      </Grid>

      <Grid item xs={12} sm={2.5}>
        <SearchFormSelect
          placeholder="Name"
          value={filters.id}
          onChange={(selected) => handleFieldChange("id", selected)}
          options={[
            ...new Map(
              services.map((item) => [
                item.name,
                { value: item.id, label: item.name },
              ])
            ).values(),
          ]}
          isLoading={isLoadingServices}
          onScrollEnd={handleScrollEnd} // Trigger scroll end
        />
      </Grid>
      <Grid item xs={12} sm={2.5}>
        <SearchFormSelect
          placeholder="Type"
          value={filters.type}
          onChange={(selected) => handleFieldChange("type", selected)}
          options={ServiceType.map((item) => ({
            value: item.value,
            label: item.name,
          }))}
          isLoading={isLoadingServices}
          onScrollEnd={handleScrollEnd} // Trigger scroll end
        />
      </Grid>
    </Grid>
  );
};

ServicesFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired,
};

export default ServicesFilters;

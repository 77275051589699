/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useCallback, useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PropTypes from "prop-types";
import { Typography, Grid, Modal, Button } from "@mui/material";
import {
  StyledModal,
  StyledModalContent,
  StyledModalHr,
  StyledCancelButton,
  StyledAllocateButton,
  FlexContainer,
  StyledModalListCard,
  StyledImage24,
} from "../../styledComponents";
import SearchFormSelect from "../common/SearchFormSelect";
import SearchField from "../common/SearchField";
import { iconMap } from "../../constants/iconsList";
import { fetchUsersByMultipleCriteria } from "../../services/userNew.service";

const StaffAllocationModal = ({
  staffOpen,
  handleStaffAllocationModalClose,
  allocateStaff,
  selectedItem,
}) => {
  const [users, setUsers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit] = useState(100);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [filters, setFilters] = useState({
    id: "",
    userName: "",
    roleName: "Staff", // Predefined filter for staff role
  });
  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  // Handle scroll event
  const handleScroll = useCallback(
    (e) => {
      if (page * limit <= count) {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (
          scrollTop + clientHeight >= scrollHeight &&
          !isLoading &&
          !isEmpty
        ) {
          setPage((prevPage) => prevPage + 1); // Load the next page
        }
      }
    },
    [isLoading, isEmpty]
  );

  useEffect(() => {
    if (staffOpen) {
      _fetchUsersByMultipleCriteria(
        filters.accountId,
        filters.id,
        filters.userName,
        filters.roleName,
        page,
        limit
      );
    }
  }, [staffOpen, page, filters]);

  const _fetchUsersByMultipleCriteria = async (
    accountId = defaultAccountId,
    id,
    userName,
    roleName
  ) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      let allUsers = [];
      let currentPage = 1;
      let totalFetched = 0;

      let data;
      do {
        data = await fetchUsersByMultipleCriteria(
          accountId,
          id,
          userName,
          roleName,
          currentPage,
          limit
        );
        allUsers = [...allUsers, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);

      setUsers(allUsers);
      setCount(data.count);
      setIsEmpty(totalFetched === 0);
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching users:", errorMessage);
      setErrorMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (value) => {
    setFilters({ ...filters, id: value });
    setUsers([]);
  };

  return (
    <Modal
      open={staffOpen}
      onClose={handleStaffAllocationModalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <StyledModal>
        <Typography variant="modalTitle" id="child-modal-title">
          Allocate Staff
        </Typography>
        <Typography variant="modalSubTitle" id="child-modal-subtitle">
          Allocate a staff member to the selected consignment.
        </Typography>

        <Grid item xs={12} sm={2.5} md={12}>
          <SearchFormSelect
            placeholder="Search Staff"
            value={filters.id || ""}
            onChange={(e) => handleSearchChange(e)}
            options={users.map((item) => ({
              value: item.id,
              label: item.userName,
            }))}
          />
        </Grid>
        {errorMessage && <p>{errorMessage}</p>}
        <StyledModalContent
          onScroll={handleScroll}
          style={{ maxHeight: "200px", overflowX: "auto" }}
        >
          {users.map((user) => (
            <StyledModalListCard key={user.id}>
              <FlexContainer item>
                <Grid>
                  <StyledImage24 src={iconMap["StaffIcon"]} alt="icon" />
                </Grid>
                <Grid>{user.userName}</Grid>
              </FlexContainer>

              <Grid item>
                {user.id === selectedItem?.staffId ? (
                  <Typography variant="body1" color="green">
                    ASSIGNED
                  </Typography>
                ) : (
                  <StyledAllocateButton
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => allocateStaff(user)}
                  >
                    ALLOCATE STAFF
                  </StyledAllocateButton>
                )}
              </Grid>
            </StyledModalListCard>
          ))}
        </StyledModalContent>
        <StyledModalHr />
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <StyledCancelButton
              fullWidth
              onClick={handleStaffAllocationModalClose}
            >
              CANCEL
            </StyledCancelButton>
          </Grid>
        </Grid>
      </StyledModal>
    </Modal>
  );
};

// PropTypes validation
StaffAllocationModal.propTypes = {
  staffOpen: PropTypes.bool.isRequired,
  handleStaffAllocationModalClose: PropTypes.func.isRequired,
  allocateStaff: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
};

export default StaffAllocationModal;

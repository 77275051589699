/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Loader from "../../../common/Loader";
import DriverRunSheet from "./DriverRunSheet";
import {
  StyledTableCell,
  StyledTableContainer,
} from "../../../../styledComponents";
import EmptyState from "../../../common/EmptyState";
import UnitsMonitoringReports from "./UnitsMonitoringReports";
import EnvReports from "./EnvReports";
import EventReport from "./EventReport";

const ReportsListTable = () => {
  const [page] = useState(1);
  const [limit] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [consignment, setConsignment] = useState([]);
  const [count, setCount] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const { reports, envReports, reportType, filters } = useOutletContext();
  const selectedDriverId = filters.id;
  const containerRef = useRef(null);
  const navigate = useNavigate();

  return (
    <>
      <StyledTableContainer ref={containerRef}>
        {isLoading && <Loader />}
        {!isLoading && isEmpty && (
          <EmptyState
            name="REPORTS"
            message="Oops! No records found for your search criteria."
            info="Please try again with a different search filter."
          />
        )}
        {!isLoading && !isEmpty && reportType === "Monitoring Report" && (
          <UnitsMonitoringReports />
        )}
        {!isLoading && !isEmpty && reportType === "Environmental Report" && (
          <EnvReports />
        )}
        {!isLoading && !isEmpty && reportType === "Events Report" && (
          <EventReport />
        )}
        {!isLoading && !isEmpty && reportType === "Driver Run Sheets" && (
          <DriverRunSheet />
        )}
      </StyledTableContainer>
    </>
  );
};

export default ReportsListTable;

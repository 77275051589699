/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { fetchCustomersByMultipleCriteria } from "../../../../services/customerNew.service";
import { Grid } from "@mui/material";
import SearchFormSelect from "../../../common/SearchFormSelect";
import FilterListIcon from "@mui/icons-material/FilterList";

const ConsigneesFilters = ({ onFiltersChange }) => {
  const [filters, setFilters] = useState({
    id: "",
  });
  const [customers, setCustomers] = useState([]);
  const [page] = useState(1);
  const [limit] = useState(15);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters]);

  useEffect(() => {
    _fetchCustomersByMultipleCriteria();
  }, [page, limit]);

  const _fetchCustomersByMultipleCriteria = async (
    id = "",
    page = 1,
    limit = 15
  ) => {
    if (isLoadingCustomers) return; // Prevent multiple calls
    setIsLoadingCustomers(true);

    try {
      let allCustomers = [];
      let currentPage = page;
      let totalFetched = 0;

      let response;
      do {
        response = await fetchCustomersByMultipleCriteria(
          id,
          currentPage,
          limit
        );
        allCustomers = [...allCustomers, ...response.rows];
        totalFetched += response.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < response.count);

      setCustomers(allCustomers);
    } catch (error) {
      console.error(
        "Error fetching customers:",
        error?.message || "An unknown error occurred"
      );
    } finally {
      setIsLoadingCustomers(false);
    }
  };

  const handleFieldChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  return (
    <>
      <Grid container spacing={1} sx={{ display: "flex" }}>
        <Grid item xs={12} sm={1} sx={{ display: "flex" }}>
          <Grid
            sx={{ marginTop: "20px", color: "#6C6C81", fontWeight: "600" }}
            mr={1}
          >
            FILTERS
          </Grid>
          <FilterListIcon sx={{ marginTop: "20px" }} />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Name"
            value={filters.id}
            onChange={(selected) => {
              handleFieldChange("id", selected);
            }}
            options={customers.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Grid>
      </Grid>
    </>
  );
};
ConsigneesFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired, // Validate that onFiltersChange is a required function prop
};

export default ConsigneesFilters;

/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useCallback, useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PropTypes from "prop-types";
import { Typography, Grid, Modal, Button } from "@mui/material";
import {
  StyledModal,
  StyledModalContent,
  StyledModalHr,
  StyledCancelButton,
  StyledAllocateButton,
  FlexContainer,
  StyledModalListCard,
  StyledImage24,
  StyledButton,
  ModalButton,
} from "../../styledComponents";
import SearchFormSelect from "../common/SearchFormSelect";
import { UnitsModel } from "../../model/units.model";
import SearchField from "../common/SearchField";
import {
  fetchAvailableUnitsByAccountId,
  fetchUnitsByMultipleCriteria,
} from "../../services/unitNew.service";
import { iconMap } from "../../constants/iconsList";

const UnitAllocationModal = ({
  unitOpen,
  handleUnitAllocationModalClose,
  handleDriverAllocationModalOpen,
  allocateUnit,
  consignment,
}) => {
  const [units, setUnits] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [filters, setFilters] = useState({
    unitType: "",
    id: "",
    VIN: "",
    licensePlate: "",
    make: "",
    model: "",
    deviceType: "",
    deviceId: "",
  });
  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  // Handle scroll event
  const handleScroll = useCallback(
    (e) => {
      if (page * limit <= count) {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (
          scrollTop + clientHeight >= scrollHeight &&
          !isLoading &&
          !isEmpty
        ) {
          setPage((prevPage) => prevPage + 1); // Load the next page
        }
      }
    },
    [isLoading, isEmpty]
  );

  useEffect(() => {
    if (unitOpen) {
      _fetchUnitsByMultipleCriteria(
        filters.accountId,
        filters.unitType,
        filters.id,
        filters.VIN,
        filters.licensePlate,
        filters.make,
        filters.model,
        filters.deviceType,
        filters.deviceId,
        page,
        limit
      );
    }
  }, [unitOpen, page, filters]);

  const _fetchUnitsByMultipleCriteria = async (
    accountId = defaultAccountId,
    unitType,
    id,
    VIN,
    licensePlate,
    make,
    model,
    deviceType,
    deviceId
  ) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      let allUnits = [];
      let currentPage = 1;
      let totalFetched = 0;

      let data;
      do {
        data = await fetchUnitsByMultipleCriteria(
          accountId,
          unitType,
          id,
          VIN,
          licensePlate,
          make,
          model,
          deviceType,
          deviceId,
          currentPage,
          limit
        );
        allUnits = [...allUnits, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);

      setUnits(allUnits);
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching units:", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = async (value) => {
    setFilters({ ...filters, id: value });
    setUnits([]);
  };

  return (
    <Modal
      open={unitOpen}
      onClose={(e) => handleUnitAllocationModalClose(e)}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <StyledModal>
        <Typography variant="modalTitle" id="child-modal-title">
          Allocate Unit
        </Typography>
        <Typography variant="modalSubTitle" id="child-modal-subtitle">
          Choose a Unit that can be allocated to your consignment.
        </Typography>

        <Grid item xs={12} sm={2.5} md={12}>
          <SearchFormSelect
            placeholder="Search Units"
            value={filters.id || ""}
            onChange={(e) => handleSearchChange(e)}
            options={units.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Grid>
        {errorMessage && <p>{errorMessage}</p>}
        <StyledModalContent
          onScroll={handleScroll}
          style={{ maxHeight: "200px", overflowX: "auto" }}
        >
          {units.map((unit) => (
            <StyledModalListCard key={unit}>
              <FlexContainer item>
                <Grid>
                  <StyledImage24 src={iconMap["DeliveryTruck1"]} alt="icon" />
                </Grid>
                <Grid>{unit.name}</Grid>
              </FlexContainer>

              <Grid item>
                {unit.id === consignment?.unitId ? (
                  <Typography variant="body1" color="green">
                    ASSIGNED
                  </Typography>
                ) : (
                  <StyledAllocateButton
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => allocateUnit(unit)}
                  >
                    ALLOCATE
                  </StyledAllocateButton>
                )}
              </Grid>
            </StyledModalListCard>
          ))}
        </StyledModalContent>
        <StyledModalHr />
        <Grid container direction="column" spacing={1}>
          {consignment?.tripStatus === "UNIT_ALLOCATION" && (
            <Grid item>
              <ModalButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={(e) => handleDriverAllocationModalOpen(e)}
              >
                ASSIGN DRIVER
              </ModalButton>
            </Grid>
          )}
          <Grid item>
            <StyledCancelButton
              item
              fullWidth
              onClick={() => handleUnitAllocationModalClose()}
            >
              CANCEL
            </StyledCancelButton>
          </Grid>
        </Grid>
      </StyledModal>
    </Modal>
  );
};

// PropTypes validation
UnitAllocationModal.propTypes = {
  unitOpen: PropTypes.bool.isRequired,
  handleUnitAllocationModalClose: PropTypes.func.isRequired,
  handleDriverAllocationModalOpen: PropTypes.func,
  selectedAccount: PropTypes.object,
  allocateUnit: PropTypes.func.isRequired,
  consignment: PropTypes.object,
};

export default UnitAllocationModal;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ListHeaderNew from "../../../common/ListHeaderNew";
import { Grid } from "@mui/material";
import ViewModeSwitch from "../../../common/ViewModeSwitch";
import ExportButton from "../../../common/ExportButton";
import { fetchUsersByMultipleCriteria } from "../../../../services/userNew.service";
import DriversFilters from "../components/DriversFilters";

export default function DriversPage() {
  // State hooks for data storage
  // const accountId = localStorage.getItem("defaultAccountId");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [limit] = useState(100);
  const [filters, setFilters] = useState({
    id: "",
    userName: "",
  });
  const [isEmpty, setIsEmpty] = useState(false); // Add empty state
  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  // Location-related logic
  const location = useLocation();
  const shouldShowHeader =
    location.pathname.endsWith("drivers") ||
    location.pathname.endsWith("card") ||
    location.pathname.endsWith("list");

  useEffect(() => {
    _fetchUsersByMultipleCriteria(
      filters.accountId,
      filters.id,
      filters.userName,
      "Driver",
      page,
      limit
    );
  }, [page, filters]);

  const _fetchUsersByMultipleCriteria = async (
    accountId = defaultAccountId,
    id,
    userName,
    roleName,
    page,
    limit
  ) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      const response = await fetchUsersByMultipleCriteria(
        accountId,
        id,
        userName,
        roleName,
        page,
        limit
      );
      setCount(response.count);
      setDrivers((prevDrivers) => [...prevDrivers, ...response.rows]);
      setIsEmpty(response.rows.length === 0); // Set empty state
    } catch (error) {
      console.log(error);
      // const errorMessage = error?.error.message || 'An unknown error occurred'
      // console.error('Error fetching consignments:', errorMessage)
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
    setDrivers([]);
    setPage(1);
  };

  const fetchMore = () => {
    if (drivers.length < count) {
      setPage((prevPage) => prevPage + 1); // Increment page number for search results
    } else {
      console.log("All drivers have been fetched.");
    }
  };

  return (
    <div className="container">
      {shouldShowHeader && (
        <Grid container flexDirection="column">
          <Grid item xs={12}>
            <ListHeaderNew name="Driver" mode="Create" />
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{
              marginTop: "30px",
              marginBottom: "30px",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12}>
              <DriversFilters onFiltersChange={handleFiltersChange} />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <ViewModeSwitch name="Driver" />
              <ExportButton name="Drivers" accounts={drivers}></ExportButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Outlet context={{ drivers, fetchMore, isLoading, isEmpty }} />
    </div>
  );
}

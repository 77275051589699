/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import SearchFormSelect from "../../../common/SearchFormSelect";
import { fetchSensorsByMultipleCriteria } from "../../../../services/sensor.service";
import FilterListIcon from "@mui/icons-material/FilterList";
import { sensorType } from "../../../../utils/constant";

const SensorsFilters = ({ onFiltersChange }) => {
  const [filters, setFilters] = useState({
    name: "",
    sensorType: "",
    sensorModel: "",
    accountId: JSON.parse(localStorage.getItem("defaultAccountId")),
  });
  const [sensors, setSensors] = useState([]);
  const [filteredSensorModels, setFilteredSensorModels] = useState([]);
  const [page] = useState(1);
  const [limit] = useState(15);
  const [isLoadingSensors, setIsLoadingSensors] = useState(false);

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters]);

  useEffect(() => {
    _fetchSensorsByCriteria();
  }, [page, limit]);

  const _fetchSensorsByCriteria = async () => {
    if (isLoadingSensors) return; // Prevent multiple calls
    setIsLoadingSensors(true);
    let accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
    try {
      const response = await fetchSensorsByMultipleCriteria(
        filters.name,
        filters.sensorType,
        filters.sensorModel,
        accountId,
        page,
        limit
      );

      setSensors(response.rows);
    } catch (error) {
      console.error(
        "Error fetching sensors:",
        error?.message || "Unknown error"
      );
    } finally {
      setIsLoadingSensors(false);
    }
  };

  const handleFieldChange = (field, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [field]: value };
      // Reset sensorModel if sensorType changes
      if (field === "sensorType") {
        updatedFilters.sensorModel = "";
        filterSensorModels(value);
      }
      return updatedFilters;
    });
  };

  const filterSensorModels = (selectedSensorType) => {
    const models = sensors
      .filter((sensor) => sensor.sensorType === selectedSensorType)
      .map((sensor) => ({
        value: sensor.sensorModel,
        label: sensor.sensorModel,
      }));

    // Remove duplicates
    const uniqueModels = [
      ...new Map(models.map((item) => [item.value, item])).values(),
    ];
    setFilteredSensorModels(uniqueModels);
  };

  return (
    <>
      <Grid container spacing={1} sx={{ display: "flex" }}>
        <Grid item xs={12} sm={1} sx={{ display: "flex" }}>
          <Grid
            sx={{ marginTop: "20px", color: "#6C6C81", fontWeight: "600" }}
            mr={1}
          >
            FILTERS
          </Grid>
          <FilterListIcon sx={{ marginTop: "20px" }} />
        </Grid>

        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Name"
            value={filters.name}
            onChange={(selected) => handleFieldChange("name", selected)}
            options={sensors.map((item) => ({
              value: item.name,
              label: item.name,
            }))}
          />
        </Grid>

        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Sensor Type"
            value={filters.sensorType}
            onChange={(selected) => handleFieldChange("sensorType", selected)}
            options={sensorType.map((item) => ({
              value: item.value,
              label: `${item.name}`,
            }))}
          />
        </Grid>

        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Sensor Model"
            value={filters.sensorModel}
            onChange={(selected) => handleFieldChange("sensorModel", selected)}
            options={filters.sensorType ? filteredSensorModels : []}
            readOnly={!filters.sensorType}
          />
        </Grid>
      </Grid>
    </>
  );
};

SensorsFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired, // Validate that onFiltersChange is a required function prop
};

export default SensorsFilters;

const defaultAccountId =
  localStorage.getItem("defaultAccountId") !== ""
    ? JSON.parse(localStorage.getItem("defaultAccountId"))
    : "";
export const SensorsModel = {
  accountId: defaultAccountId,
  name: "",
  sensorType: "GPS",
  sensorModel: "",
  installedLocation: "",
  // sensorId: null,
  // deviceId: null,
  createdBy: "",
  updatedBy: "",
};

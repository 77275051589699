/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
// React core imports
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  eventReportTableColumns,
  mapStatus,
  reportTableColumns,
} from "../../../../utils/constant";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell, StyledTableHead } from "../../../../styledComponents";
import debounce from "lodash.debounce";
import Loader from "../../../common/Loader";
import { formatDateTime } from "../../../../utils/functions";

const EventReport = () => {
  const { eventsReports, fetchMore, isLoading, hasMoreData } =
    useOutletContext();
  const containerRef = useRef(null);
  const [shouldReload, setShouldReload] = useState(false);

  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  useEffect(() => {
    if (shouldReload) {
      // getAccounts();
      window.location.reload();
    }
  }, [shouldReload]);

  // Attach the scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    // Remove the event listener on cleanup
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [eventsReports, isLoading, handleScroll]);

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ overflow: "auto", maxWidth: "75rem" }}
      >
        {isLoading && <Loader />}
        <Table aria-label="device reports table">
          <StyledTableHead>
            <TableRow>
              {eventReportTableColumns.map((column) => (
                <StyledTableCell key={column}>
                  <Typography variant="tableHeader">{column}</Typography>
                </StyledTableCell>
              ))}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {eventsReports?.map((report) => (
              <TableRow key={report.id} sx={{ cursor: "pointer" }}>
                <StyledTableCell component="th" scope="row">
                  <Typography variant="tableValue">
                    {report.deviceId}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {report.unitName || "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {report.dateTime ? formatDateTime(report.dateTime) : "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {report.DRIVER || "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {report.CONSIGNMENTNO || "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {report.sensorEvent}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {report.startDrivingTime
                      ? formatDateTime(report.startDrivingTime)
                      : "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {report.endDrivingTime
                      ? formatDateTime(report.endDrivingTime)
                      : "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography
                    variant="tableValue"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {`${report?.location?.lat || "--"}\n${
                      report?.location?.lng || "--"
                    }`}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {report?.location?.address || "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {report?.speed || "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {report?.continuousDrivingTime || "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography variant="tableValue">
                    {report?.mileage}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ whiteSpace: "wrap" }}>
                  <Typography variant="tableValue">
                    {mapStatus("lockStatus", report.lockStatus) || "--"}
                  </Typography>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EventReport;

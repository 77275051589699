/* eslint-disable no-unused-vars */
// React core imports
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

// Material-UI imports
import { Grid, Menu, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import debounce from "lodash.debounce";

// Styled components and utility functions
import {
  StyledListCard,
  StyledListCardHeader,
  StyledIconButton,
  FlexContainerSB,
  StyledListCardContent,
  StyledMenuItem,
  StyledListCardContainer,
} from "../../../../styledComponents";
import { getSimCardsStatusColor } from "../../../../utils/functions";

// Modal components
import DeleteModal from "../../../modals/DeleteModal";

// Service and common components
import Loader from "../../../common/Loader";
import EmptyState from "../../../common/EmptyState";
import {
  deleteSimCard,
  updateSimCard,
} from "../../../../services/simCardNew.service";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import AllocateSimcardModal from "../../../modals/AllocateSimCardModel";
import DeallocateSimcardModal from "../../../modals/DeallocateSimCardModel";

const SimCardsListCard = () => {
  const [errors, setErrors] = useState([]);
  // Access the searchResults from the context
  // Used to set state that tracks the currently opened menu by account ID on open and close 3 dots menu option
  const [openMenuSimCardId, setOpenMenuSimCardId] = useState(null);
  // This is for the delete modal
  const [open, setOpen] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const [activateOpen, setActivateOpen] = useState(false);
  const [deactivateOpen, setDeactivateOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);

  // Hooks for navigation and outlet context
  const navigate = useNavigate();
  const { simCards, fetchMore, isLoading, isEmpty } = useOutletContext();

  // Ref for the container to attach scroll event
  const containerRef = useRef(null);

  // Scroll event handler
  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay

  // Use effect to listen for shouldReload changes
  useEffect(() => {
    if (shouldReload) {
      // getAccounts();
      window.location.reload();
    }
  }, [shouldReload]);

  // Attach the scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    // Remove the event listener on cleanup
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [simCards, isLoading, handleScroll]); // Include dependencies that affect the event listener

  // Function that calls the API to perform handleUpdateUnit
  const handleUpdateSimCard = useCallback(
    async (updatedSimCard) => {
      try {
        const response = await updateSimCard(updatedSimCard);
        if (response) {
          setSuccessMessage("Sim Card updated successfully.");
          setErrorMessage("");
          // Set the state to reload the page after successful update
          setShouldReload(true);
        }
      } catch (error) {
        setErrorMessage(error.message || "An unexpected error occurred");
      } finally {
        // setIsLoading(false);
      }
    },
    [setSuccessMessage, setErrorMessage, setShouldReload, selectedItem]
  );

  // Function to open the menu on click of 3 dots in the list cards/tables
  // Callbacks wrapped with useCallback for performance optimization

  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    setOpenMenuSimCardId(selectedItem.id); // Set the ID of the account whose menu should be open
    setSelectedItem(selectedItem);
  }, []);

  // Function to close the menu on selecting any option from the menu
  const handleActionsMenuClose = useCallback(() => {
    setOpenMenuSimCardId(null); // Set the ID of the account whose menu should be open
  }, []);

  const handleDeactivateModalOpen = useCallback(
    (event, unit) => {
      handleActionsMenuClose();
      setSelectedItem(unit);
      setDeactivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setDeactivateOpen]
  );

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeactivateModalClose = useCallback(() => {
    setDeactivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setDeactivateOpen]);

  // Function that calls the API to perform deleteAccount
  const deactivateUnit = useCallback(() => {
    const status = "DEACTIVATED";

    // Update the selectedItem with the new subscriptions array
    const updatedSimCard = {
      ...selectedItem,
      status: status,
    };

    setSelectedItem(updatedSimCard);
    handleUpdateSimCard(updatedSimCard);

    // Close the modal
    setDeactivateOpen(false);
    setSelectedItem(null);
  }, [selectedItem, handleUpdateSimCard, setDeactivateOpen, setSelectedItem]);

  //Activate Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleActivateModalOpen = useCallback(
    (event, unit) => {
      handleActionsMenuClose();
      setSelectedItem(unit);
      setActivateOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setActivateOpen]
  );

  const handleActivateModalClose = useCallback(() => {
    setActivateOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setActivateOpen]);

  // Function that calls the API to perform deleteAccount
  const activateUnit = useCallback(() => {
    const status = "ACTIVATED";

    // Update the selectedItem with the new subscriptions array
    const updatedSimCard = {
      ...selectedItem,
      status: status,
    };

    setSelectedItem(updatedSimCard);
    handleUpdateSimCard(updatedSimCard);

    // Close the modal
    setActivateOpen(false);
    setSelectedItem(null);
  }, [selectedItem, handleUpdateSimCard, setActivateOpen, setSelectedItem]);

  //Delete Account
  // Function to open the modal on click of "Delete Account" in the menu options
  const handleDeleteModalOpen = useCallback(
    (event, accountId) => {
      handleActionsMenuClose();
      setSelectedItem(accountId);
      setOpen(true);
    },
    [handleActionsMenuClose, setSelectedItem, setOpen]
  );

  // Function to close the modal on click of "Delete Account" in the menu options
  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setOpen]);

  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
    //   const response = await deleteSimCard(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, 'SimCard deleted successfully.')
    //     handleError([])
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error)
    // } finally {
    //   setOpen(false);
    //   setSelectedItem(null);
    // }
  };

  // // Function that calls the API to perform deleteAccount
  // const deleteAccount = useCallback(() => {
  //   setOpen(false);
  //   setSelectedItem(null);
  // }, [setOpen, setSelectedItem]);

  //Function to navigate to the edit screen on click of the card
  const navigateToEditScreen = useCallback(
    (simCardId) => {
      navigate(`/management/sim-cards/edit/${simCardId}`);
      // Your navigation logic here
    },
    [navigate]
  );

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };
  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
    }
  };
  const handleError = (error) => {
    window.scrollTo(0, 0);
    // if (error.name === 'ValidationError') {
    //   handleValidationErrors(error)
    // } else {
    setErrors(error.error);
    // }
  };

  return (
    <>
      <Grid sx={{ marginTop: "50px" }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledListCardContainer container ref={containerRef} spacing={2}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="SIM CARDS"
              message="Oops! You have no sim cards for your search criteria"
              info="Please feel free to create a new sim card by clicking on the button above"
            ></EmptyState>
          </Grid>
        )}
        {simCards.map((simCard) => (
          <Grid item xs={12} sm={6} md={4} key={simCard.id}>
            <StyledListCard>
              <StyledListCardHeader
                title={
                  <Grid container sx={{ justifyContent: "space-between" }}>
                    <Typography variant="cardTitle1">
                      {simCard.areaCode} {simCard.simCardNumber}
                    </Typography>
                    <Typography
                      variant="cardStatus"
                      sx={{
                        color: getSimCardsStatusColor(simCard.status),
                      }}
                    >
                      &bull; &nbsp;
                      {simCard.status}
                    </Typography>
                  </Grid>
                }
                action={
                  <Grid>
                    <StyledIconButton
                      id={`menu-button-${simCard.id}`}
                      onClick={(event) => handleActionsMenuOpen(event, simCard)}
                    >
                      <MoreVertIcon />
                    </StyledIconButton>
                    <Menu
                      anchorEl={document.getElementById(
                        `menu-button-${simCard.id}`
                      )}
                      open={openMenuSimCardId === simCard.id} // The menu is open if the openMenuSimCardId
                      onClose={(event) => handleActionsMenuClose(event)}
                    >
                      <StyledMenuItem
                        onClick={(e) => handleDeleteModalOpen(e, simCard)}
                      >
                        <Grid sx={{ display: "flex", alignItems: "block" }}>
                          <DeleteOutlineIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          <Typography> Delete Sim Card</Typography>
                        </Grid>
                      </StyledMenuItem>
                    </Menu>
                  </Grid>
                }
              />
              <StyledListCardContent
                onClick={() => navigateToEditScreen(simCard.id)}
              >
                <Grid>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Account</Typography>
                    <Typography variant="cardValue">
                      {simCard?.accountName ? simCard?.accountName : "--"}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Device</Typography>
                    <Typography variant="cardValue">
                    {simCard?.deviceDetails?.name ? simCard?.deviceDetails?.name : "--"}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Unit</Typography>
                    <Typography variant="cardValue">
                      {simCard?.unitDetails?.name ? simCard?.unitDetails?.name : "--"}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">IMEI Number</Typography>
                    <Typography variant="cardValue">
                      {simCard?.imeiNumber ? simCard?.imeiNumber : "--"}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">ICCD Number</Typography>
                    <Typography variant="cardValue">
                      {simCard?.iccdNumber ? simCard?.iccdNumber : "--"}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">MSSIDN Number</Typography>
                    <Typography variant="cardValue">
                      {simCard?.mssidnNumber ? simCard?.mssidnNumber : "--"}
                    </Typography>
                  </FlexContainerSB>
                </Grid>
              </StyledListCardContent>
            </StyledListCard>
          </Grid>
        ))}
      </StyledListCardContainer>

      <AllocateSimcardModal
        activateOpen={activateOpen}
        handleActivateModalClose={handleActivateModalClose}
        selectedItem={selectedItem}
        activateItem={activateUnit}
      />
      <DeallocateSimcardModal
        deactivateOpen={deactivateOpen}
        handleDeactivateModalClose={handleDeactivateModalClose}
        selectedItem={selectedItem}
        deactivateItem={deactivateUnit}
      />

      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default SimCardsListCard;

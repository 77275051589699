import axios from "axios";
import { geofenceApi } from "../utils/constant";

// Create an instance of axios with default headers and base URL
const api = axios.create({
  baseURL: geofenceApi,
  headers: {
    "Content-Type": "application/json",
  },
});

// Intercept requests to add the authorization token
api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
  if (error.response) {
    console.error("API Error:", error.response.data);
    throw error.response.data;
  } else if (error.request) {
    console.error("No response received:", error.request);
    throw new Error("No response received from server.");
  } else {
    console.error("Request setup error:", error.message);
    throw new Error("Request setup error.");
  }
};

//Get a geofence by its ID
export const getGeofenceById = async (geofenceId) => {
  try {
    const response = await api.get(`/${geofenceId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Create a new geofence
export const createGeofence = async (data) => {
  try {
    const response = await api.post("/", data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Update an existing geofence by its ID
export const updateGeofence = async (data) => {
  try {
    const response = await api.put(`/${data.id}`, data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Delete a geofence by its ID
export const deleteGeofence = async (id) => {
  try {
    const response = await api.delete(`/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchGeofencesByMultipleCriteria = async (id, page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchGeofencesByMultipleCriteria", {
      params: {
        accountId,
        id,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ListHeaderNew from "../../../common/ListHeaderNew";
import { Grid } from "@mui/material";
import ViewModeSwitch from "../../../common/ViewModeSwitch";
import ExportButton from "../../../common/ExportButton";
import { fetchSimCardsByMultipleCriteria } from "../../../../services/simCardNew.service";
import SimCardsFilters from "../components/SimCardsFilter";

export default function SimCardsPage() {
  // State hooks for data storage
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [simCards, setSimCards] = useState([]);
  const [limit] = useState(15);

  const [filters, setFilters] = useState({
    simCardNumber: "",
    imeiNumber: "",
    iccdNumber: "",
    mssidnNumber: "",
    status: "",
  });
  const [isEmpty, setIsEmpty] = useState(false); // Add empty state

  // Location-related logic
  const location = useLocation();
  const shouldShowHeader =
    location.pathname.endsWith("sim-cards") ||
    location.pathname.endsWith("card") ||
    location.pathname.endsWith("list");

  useEffect(() => {
    _fetchSimCardsByMultipleCriteria(
      filters.simCardNumber,
      filters.imeiNumber,
      filters.iccdNumber,
      filters.mssidnNumber,
      filters.status,
      page,
      limit
    );
  }, [page, filters]);

  const _fetchSimCardsByMultipleCriteria = async (
    simCardNumber,
    imeiNumber,
    iccdNumber,
    mssidnNumber,
    status,
    page,
    limit
  ) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      const response = await fetchSimCardsByMultipleCriteria(
        simCardNumber,
        imeiNumber,
        iccdNumber,
        mssidnNumber,
        status,
        page,
        limit
      );
      setCount(response.count);
      setSimCards((prevSimCards) => [...prevSimCards, ...response.rows]);
      setIsEmpty(response.rows.length === 0); // Set empty state
    } catch (error) {
      console.log(error);
      // const errorMessage = error?.error.message || 'An unknown error occurred'
      // console.error('Error fetching consignments:', errorMessage)
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
    setSimCards([]);
    setPage(1);
  };

  const fetchMore = () => {
    if (simCards.length < count) {
      setPage((prevPage) => prevPage + 1); // Increment page number for search results
    } else {
      console.log("All simCards have been fetched.");
    }
  };

  return (
    <div className="container">
      {shouldShowHeader && (
        <Grid container flexDirection="column">
          <Grid item xs={12}>
            <ListHeaderNew name="Sim Card" mode="Create" />
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{
              marginTop: "30px",
              marginBottom: "30px",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12}>
              <SimCardsFilters onFiltersChange={handleFiltersChange} />{" "}
            </Grid>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <ViewModeSwitch name="Sim Card" />
              <ExportButton name="Sim Cards" accounts={simCards}></ExportButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Outlet context={{ simCards, fetchMore, isLoading, isEmpty }} />
    </div>
  );
}

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import SearchFormSelect from "../../../common/SearchFormSelect";
import {
  fetchAllSimCards,
  fetchSimCardsByMultipleCriteria,
} from "../../../../services/simCardNew.service";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  fetchAccountsByMultipleCriteria,
  fetchAllAccounts,
} from "../../../../services/accountNew.service";

const SimCardsFilters = ({ onFiltersChange }) => {
  const [filters, setFilters] = useState({
    simCardNumber: "",
    imeiNumber: "",
    iccdNumber: "",
    mssidnNumber: "",
    accountId: "",
  });
  const [simCards, setSimCards] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [page] = useState(1);
  const [limit] = useState(15);
  const [isLoadingSimCards, setIsLoadingSimCards] = useState(false);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters]);

  useEffect(() => {
    _fetchSimCardsByMultipleCriteria();
    _fetchAccountsByMultipleCriteria();
  }, [page, limit]);

  const _fetchSimCardsByMultipleCriteria = async (
    simCardNumber = "",
    imeiNumber = "",
    iccdNumber = "",
    mssidnNumber = "",
    status = "",
    page = 1,
    limit = 15
  ) => {
    if (isLoadingSimCards) return; // Prevent multiple calls
    setIsLoadingSimCards(true);

    try {
      let allSimCards = [];
      let currentPage = page;
      let totalFetched = 0;

      let response;
      do {
        response = await fetchSimCardsByMultipleCriteria(
          simCardNumber,
          imeiNumber,
          iccdNumber,
          mssidnNumber,
          status,
          currentPage,
          limit
        );
        allSimCards = [...allSimCards, ...response.rows];
        totalFetched += response.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < response.count);

      setSimCards(allSimCards);
    } catch (error) {
      console.error(
        "Error fetching simCards:",
        error?.message || "An unknown error occurred"
      );
    } finally {
      setIsLoadingSimCards(false);
    }
  };

  const _fetchAccountsByMultipleCriteria = async (
    accountId = "",
    billingPlanId = "",
    page = 1,
    limit = 15
  ) => {
    if (isLoadingAccounts) return; // Prevent multiple calls
    setIsLoadingAccounts(true);

    try {
      let allAccounts = [];
      let currentPage = page;
      let totalFetched = 0;

      let response;
      do {
        response = await fetchAccountsByMultipleCriteria(
          accountId,
          billingPlanId,
          currentPage,
          limit
        );
        allAccounts = [...allAccounts, ...response.rows];
        totalFetched += response.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < response.count);

      setAccounts(allAccounts);
    } catch (error) {
      console.error(
        "Error fetching billingPlans:",
        error?.message || "An unknown error occurred"
      );
    } finally {
      setIsLoadingAccounts(false);
    }
  };

  const _fetchAllAccounts = async () => {
    if (isLoadingAccounts) return; // Prevent multiple calls
    setIsLoadingAccounts(true);

    try {
      let allAccounts = [];
      let currentPage = 1;
      let totalFetched = 0;

      let data;
      do {
        data = await fetchAllAccounts(currentPage, limit);
        allAccounts = [...allAccounts, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);

      setAccounts(allAccounts);
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching accounts:", errorMessage);
    } finally {
      setIsLoadingAccounts(false);
    }
  };

  const handleFieldChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  return (
    <>
      <Grid container spacing={1} sx={{ display: "flex" }}>
        <Grid item xs={12} sm={1} sx={{ display: "flex" }}>
          <Grid
            sx={{ marginTop: "20px", color: "#6C6C81", fontWeight: "600" }}
            mr={1}
          >
            FILTERS
          </Grid>
          <FilterListIcon sx={{ marginTop: "20px" }} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SearchFormSelect
            placeholder="Sim #"
            value={filters.simCardNumber}
            onChange={(selected) => {
              handleFieldChange("simCardNumber", selected);
            }}
            options={simCards.map((item) => ({
              value: item.simCardNumber,
              label: item.simCardNumber,
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SearchFormSelect
            placeholder="IMEI #"
            value={filters.imeiNumber}
            onChange={(selected) => {
              handleFieldChange("imeiNumber", selected);
            }}
            options={simCards.map((item) => ({
              value: item.imeiNumber,
              label: item.imeiNumber,
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SearchFormSelect
            placeholder="ICCD #"
            value={filters.iccdNumber}
            onChange={(selected) => {
              handleFieldChange("iccdNumber", selected);
            }}
            options={simCards.map((item) => ({
              value: item.iccdNumber,
              label: item.iccdNumber,
            }))}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SearchFormSelect
            placeholder="MSSIDN #"
            value={filters.mssidnNumber}
            onChange={(selected) => {
              handleFieldChange("mssidnNumber", selected);
            }}
            options={simCards.map((item) => ({
              value: item.mssidnNumber,
              label: item.mssidnNumber,
            }))}
          />
        </Grid>
        {/* <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Accounts"
            value={filters.accountId}
            onChange={(selected) => {
              handleFieldChange("accountId", selected);
            }}
            options={accounts.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Grid> */}
      </Grid>
    </>
  );
};

SimCardsFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired, // Validate that onFiltersChange is a required function prop
};

export default SimCardsFilters;

import axios from "axios";
import { unitsMonitoringApi } from "../utils/constant";

const api = axios.create({
  baseURL: unitsMonitoringApi,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
  if (error.response) {
    console.error("API Error:", error.response.data);
    throw error.response.data;
  } else if (error.request) {
    console.error("No response received:", error.request);
    throw new Error("No response received from server.");
  } else {
    console.error("Request setup error:", error.message);
    throw new Error("Request setup error.");
  }
};

export const fetchUnitsMonitoring = async (page, limit) => {
  try {
    const response = await api.get("/", {
      params: { page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchUnitsMonitoringReportsByMultipleCriteria = async (
  reportTypes,
  accountId,
  deviceId,
  unitId,
  startDate,
  endDate,
  page,
  limit
) => {
  try {
    const response = await api.get(
      "/fetchUnitsMonitoringReportsByMultipleCriteria",
      {
        params: {
          reportTypes,
          accountId,
          deviceId,
          unitId,
          startDate,
          endDate,
          page,
          limit,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchEnvironmentalReportsByMultipleCriteria = async (
  reportTypes,
  accountId,
  deviceId,
  unitId,
  startDate,
  endDate,
  page,
  limit
) => {
  try {
    const response = await api.get(
      "/fetchEnvironmentalReportsByMultipleCriteria",
      {
        params: {
          reportTypes,
          accountId,
          deviceId,
          unitId,
          startDate,
          endDate,
          page,
          limit,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchEventsReportsByMultipleCriteria = async (
  reportTypes,
  accountId,
  deviceId,
  unitId,
  startDate,
  endDate,
  page,
  limit
) => {
  try {
    const response = await api.get("/fetchEventsReportsByMultipleCriteria", {
      params: {
        reportTypes,
        accountId,
        deviceId,
        unitId,
        startDate,
        endDate,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch the latest unit monitoring records
export const fetchLatestUnitMonitoringRecords = async () => {
  try {
    const response = await api.get("/fetchLatestUnitMonitoringRecords");
    return response.data;
  } catch (error) {
    console.error("Error fetching latest unit monitoring records:", error);
    throw error;
  }
};

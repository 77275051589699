import React from "react";
import {
  StyledInputControlContainer,
  StyledTextField,
} from "../../styledComponents";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import FormError from "./FormError";

const FormField = ({
  label,
  placeholder,
  value,
  onChange,
  type = "text",
  error,
  errorMessage,
  required = false,
  readOnly = false,
  disabled = false,
}) => {
  return (
    <StyledInputControlContainer>
      <Typography variant="label">
        {label}
        {required && " *"}
      </Typography>
      <StyledTextField
        variant="outlined"
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        type={type}
        error={!!error}
        inputProps={{ readOnly: readOnly }}
        disabled={disabled}
      />
      {error && <FormError errorMessage={errorMessage} />}
    </StyledInputControlContainer>
  );
};

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FormField;

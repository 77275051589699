/* eslint-disable no-unused-vars */
import { Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { StyledFormContainer } from "../../billingPlans/styles/BillingPlansStyles.js";
import PropTypes from "prop-types";
import {
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
} from "../../../../styledComponents.js";
import FormField from "../../../common/FormField.js";
import FormOptionSelector from "../../../common/FormOptionSelector.js";
import FormSelect from "../../../common/FormSelect.js";
import { deviceType } from "../../../../utils/constant.js";
import { fetchDevicesByMultipleCriteria } from "../../../../services/deviceNew.services.js";
import DevicesFormModal from "../../../modals/DevicesFormModal";
import SearchFormSelect from "../../../common/SearchFormSelect.js";
import { useParams } from "react-router-dom";
const unitTypes = [
  { name: "Container", value: "Container", control: "unitType" },
  { name: "Vehicle", value: "Vehicle", control: "unitType" },
];

const UnitsForm1 = ({ unit, onFormValueChange, validationErrors }) => {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [limit] = useState(15);
  const [devicesFormModal, setDevicesFormModal] = useState(false);
  const [filters, setFilters] = useState({
    id: "",
    make: "",
    model: "",
  });

  const [isEmpty, setIsEmpty] = useState(false); // Add empty state

  const handleFieldChange = async (name, value) => {
    try {
      let updatedUnit = { ...unit };
      updatedUnit[name] = value;
      onFormValueChange(updatedUnit, name, value, 1); // Pass step index here
    } catch (error) {
      console.error("Error in handleFieldChange:", error);
    }
  };

    useEffect(() => {
      if(unit.deviceType != "") {
      if (id) {
        _fetchDevicesByMultipleCriteria("edit");
      } else {
        _fetchDevicesByMultipleCriteria("create");
      }
    }
    }, [unit.deviceType]);

  const _fetchDevicesByMultipleCriteria = async (mode) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      let allDevices = [];
      let currentPage = page;
      let totalFetched = 0;
      let deviceType=unit.deviceType;
      let name=""
      let make = unit.make;
      let model = unit.model;
      const accountId = JSON.parse(localStorage.getItem('defaultAccountId'))

      let response;
      do {
        response = await fetchDevicesByMultipleCriteria(
          accountId,
          name,
          deviceType,
          make,
          model,
          currentPage,
          limit
        );
        allDevices = [...allDevices, ...response.rows];
        totalFetched += response.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < response.count);
      let filteredDevices;
      if (mode === "create") {
        filteredDevices = allDevices.filter(device => device.status === "UNPROVISIONED");
      } 
      if (mode === "edit") {
        filteredDevices = allDevices.filter(device => device.status === "UNPROVISIONED" || device.id === unit.deviceId);
      }
      setDevices(filteredDevices);
    } catch (error) {
      console.error(
        "Error fetching devices:",
        error?.message || "An unknown error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleScrollEnd = () => {
    if (!isLoading && devices.length < count) {
      setPage((prevPage) => prevPage + 1); // Increment page number for next fetch
      _fetchDevicesByMultipleCriteria(page + 1); // Fetch next page data
    }
  };

  const handleDevicesFormModalOpen = useCallback(() => {
    setDevicesFormModal(true);
  }, []);

  const handleDevicesFormModalClose = useCallback(() => {
    setDevicesFormModal(false);
    _fetchDevicesByMultipleCriteria(); // Refresh device list after modal closes
  }, []);

  return (
    <StyledFormContainer>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">General parameters</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormField
            label="Name"
            required
            placeholder="Enter name"
            value={unit?.name}
            onChange={(e) => handleFieldChange("name", e.target.value)}
            error={!!validationErrors.name}
            errorMessage={validationErrors.name}
          />
          <FormOptionSelector
            label="Unit Type"
            required
            options={unitTypes}
            selectedOption={unit?.unitType}
            handleOptionChange={(value) => handleFieldChange("unitType", value)}
            error={!!validationErrors.unitType}
            errorMessage={validationErrors.unitType}
          />
          <>
            <FormSelect
              label="Choose Device Type"
              required={unit?.unitType !== "Vehicle"}
              value={unit?.deviceType}
              onChange={(e) => handleFieldChange("deviceType", e)}
              options={deviceType.map((item) => ({
                value: item.value,
                label: `${item.name}`,
              }))}
              error={!!validationErrors.deviceType}
              errorMessage={validationErrors.deviceType}
            />
            <SearchFormSelect
              label="Choose Device"
              label2="Add Device"
              onLabel2Click={handleDevicesFormModalOpen}
              required={unit?.unitType !== "Vehicle"}
              value={unit?.deviceId}
              onChange={(e) => handleFieldChange("deviceId", e)}
              options={devices.map((item) => ({
                value: item.id,
                label: `${item.name}`,
              }))}
              disabled={!unit.deviceType || devices.length === 0}
              error={!!validationErrors.deviceId}
              errorMessage={validationErrors.deviceId}
              onScrollEnd={handleScrollEnd} // Pass scroll end handler to trigger loading more data
            />
          </>
        </StyledSectionRight>
      </StyledFormSection>
      <DevicesFormModal
        open={devicesFormModal}
        handleDevicesFormModalClose={handleDevicesFormModalClose}
      />
    </StyledFormContainer>
  );
};

UnitsForm1.propTypes = {
  unit: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

export default UnitsForm1;

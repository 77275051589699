/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { fetchCustomersByMultipleCriteria } from "../../../../services/customerNew.service";
import { fetchUnitsByMultipleCriteria } from "../../../../services/unitNew.service";
import {
  fetchUsersByMultipleCriteria,
  fetchUsersByRoleNameAndAccountId,
} from "../../../../services/userNew.service";
import { TripStatus } from "../../../../utils/constant";
import SearchFormSelect from "../../../common/SearchFormSelect";
import SelectedDateRange from "../../../common/SelectedDateRange";
import FilterListIcon from "@mui/icons-material/FilterList";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // if using UTC

dayjs.extend(utc);

const ConsignmentFilters = ({ filters, onFiltersChange, selectedTab }) => {
  const [customers, setCustomers] = useState([]);
  const [units, setUnits] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [isLoadingUnits, setIsLoadingUnits] = useState(false);
  const [isLoadingDrivers, setIsLoadingDrivers] = useState(false);
  const [count, setCount] = useState(0);
  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  useEffect(() => {
    _fetchCustomersByMultipleCriteria();
    _fetchUnitsByMultipleCriteria();
    _fetchUsersByMultipleCriteria();
  }, []);

  useEffect(() => {
    console.log("Filters updated:", filters); // Debugging line
    onFiltersChange(filters);
  }, [filters]);

  const _fetchCustomersByMultipleCriteria = async (
    id = "",
    page = 1,
    limit = 15
  ) => {
    if (isLoadingCustomers) return; // Prevent multiple calls
    setIsLoadingCustomers(true);

    try {
      let allCustomers = [];
      let currentPage = page;
      let totalFetched = 0;

      let response;
      do {
        response = await fetchCustomersByMultipleCriteria(
          id,
          currentPage,
          limit
        );
        allCustomers = [...allCustomers, ...response.rows];
        totalFetched += response.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < response.count);

      setCustomers(allCustomers);
    } catch (error) {
      console.error(
        "Error fetching customers:",
        error?.message || "An unknown error occurred"
      );
    } finally {
      setIsLoadingCustomers(false);
    }
  };

  const _fetchUnitsByMultipleCriteria = async (
    accountId = defaultAccountId,
    unitType = "",
    id = "",
    VIN = "",
    licensePlate = "",
    make = "",
    model = "",
    deviceType = "",
    deviceId = "",
    page = 1,
    limit = 15
  ) => {
    if (isLoadingUnits) return; // Prevent multiple calls
    setIsLoadingUnits(true);

    try {
      let allUnits = [];
      let currentPage = page;
      let totalFetched = 0;

      let response;
      do {
        response = await fetchUnitsByMultipleCriteria(
          accountId,
          unitType,
          id,
          VIN,
          licensePlate,
          make,
          model,
          deviceType,
          deviceId,
          currentPage,
          limit
        );
        allUnits = [...allUnits, ...response.rows];
        totalFetched += response.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < response.count);

      setUnits(allUnits);
    } catch (error) {
      console.error(
        "Error fetching units:",
        error?.message || "An unknown error occurred"
      );
    } finally {
      setIsLoadingUnits(false);
    }
  };

  // const _fetchUsersByRoleNameAndAccountId = async () => {
  //   if (isLoadingDrivers) return;
  //   setIsLoadingDrivers(true);

  //   try {
  //     let allDrivers = [];
  //     let currentPage = 1;
  //     let totalFetched = 0;

  //     let data;
  //     do {
  //       data = await fetchUsersByRoleNameAndAccountId(
  //         roleName,
  //         currentPage,
  //         15
  //       );
  //       allDrivers = [...allDrivers, ...data.rows];
  //       totalFetched += data.rows.length;
  //       currentPage++;
  //     } while (totalFetched < data.count);

  //     setDrivers(allDrivers);
  //   } catch (error) {
  //     console.error(
  //       "Error fetching drivers:",
  //       error.message || "An unknown error occurred"
  //     );
  //   } finally {
  //     setIsLoadingDrivers(false);
  //   }
  // };

  const _fetchUsersByMultipleCriteria = async (
    accountId = defaultAccountId,
    id,
    userName,
    page = 1,
    limit = 15
  ) => {
    const roleName = "Driver";

    if (isLoadingDrivers) return;
    setIsLoadingDrivers(true);

    try {
      const response = await fetchUsersByMultipleCriteria(
        accountId,
        id,
        userName,
        roleName,
        page,
        limit
      );
      setCount(response.count);
      setDrivers((prevUsers) => [...prevUsers, ...response.rows]);
      // setIsEmpty(response.rows.length === 0); // Set empty state
    } catch (error) {
      console.log(error);
      // const errorMessage = error?.error.message || 'An unknown error occurred'
      // console.error('Error fetching consignments:', errorMessage)
    } finally {
      setIsLoadingDrivers(false);
    }
  };

  const tripStatusOptions = TripStatus.filter((item) => {
    if (selectedTab === 1) {
      return item.value !== "RECEIVED" && item.value !== "DELIVERED";
    }
    return true;
  });

  const handleFieldChange = (field, value) => {
    let formattedValue = value;

    if (field === "dateRange") {
      const { startDate, endDate } = value;
      // Log for debugging
      console.log("Handling date range change:", { startDate, endDate });

      onFiltersChange({
        ...filters,
        startDate,
        endDate,
      });
    } else {
      if (value instanceof dayjs && value.isValid()) {
        formattedValue = value.toISOString();
      } else if (value instanceof Date && !isNaN(value)) {
        formattedValue = dayjs(value).toISOString();
      } else if (typeof value === "string" && dayjs(value).isValid()) {
        formattedValue = dayjs(value).toISOString();
      }

      onFiltersChange({
        ...filters,
        [field]: formattedValue,
      });

      // Log for debugging
      console.log(`Handling field change: ${field} = ${formattedValue}`);
    }
  };

  return (
    <Grid container spacing={1} sx={{ display: "flex" }}>
      <Grid item xs={12} sm={1} sx={{ display: "flex" }}>
        <Grid
          sx={{ marginTop: "20px", color: "#6C6C81", fontWeight: "600" }}
          mr={1}
        >
          FILTERS
        </Grid>
        <FilterListIcon sx={{ marginTop: "20px" }} />
      </Grid>
      <Grid item xs={12} sm={2.5}>
        <SearchFormSelect
          placeholder="Customer"
          value={filters.customerId || ""}
          onChange={(selected) => handleFieldChange("customerId", selected)}
          options={customers.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
        />
      </Grid>
      <Grid item xs={12} sm={2.5}>
        <SearchFormSelect
          placeholder="Unit"
          value={filters.unitId || ""}
          onChange={(selected) => handleFieldChange("unitId", selected)}
          options={units.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
        />
      </Grid>
      <Grid item xs={12} sm={2.5}>
        <SearchFormSelect
          placeholder="Driver"
          value={filters.driverId || ""}
          onChange={(selected) => handleFieldChange("driverId", selected)}
          options={drivers.map((item) => ({
            value: item.id,
            label: `${item.firstName} ${item.lastName}`,
          }))}
        />
      </Grid>
      <Grid item xs={12} sm={2.5}>
        <SearchFormSelect
          placeholder="Trip Status"
          value={filters.tripStatus || ""}
          readOnly={
            filters.tripStatus === "RECEIVED" ||
            filters.tripStatus === "DELIVERED"
          }
          onChange={(selected) => handleFieldChange("tripStatus", selected)}
          options={tripStatusOptions.map((item) => ({
            value: item.value,
            label: item.name,
          }))}
        />
      </Grid>
      <Grid item xs={12} sm={3.5}>
        <SelectedDateRange
          value={[
            filters.startDate ? new Date(filters.startDate) : null,
            filters.endDate ? new Date(filters.endDate) : null,
          ]}
          onChange={(dates) => handleFieldChange("dateRange", dates)}
          error={false}
          errorMessage=""
          placeholder="Date Range"
        />
      </Grid>
    </Grid>
  );
};

ConsignmentFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
};

export default ConsignmentFilters;

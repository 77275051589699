/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ListHeaderNew from "../../../common/ListHeaderNew";
import { Grid } from "@mui/material";
import { formatDate2, formatToLocal } from "../../../../utils/functions";
import ViewModeSwitch from "../../../common/ViewModeSwitch";
import ExportButton from "../../../common/ExportButton";
import { fetchConsignmentsByMultipleCriteria } from "../../../../services/consignmentNew.service";
import ConsignmentFilters from "../components/ConsignmentFilters";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useLogMessages } from "../../../common/WebsocketContext";
dayjs.extend(utc);

export default function ConsignmentsPage() {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [consignments, setConsignments] = useState([]);
  const [limit] = useState(15);
  const [websocketMessage, setWebsocketMessage] = useState({});
  const [filters, setFilters] = useState({
    customerId: "",
    unitId: "",
    driverId: "",
    tripStatus: "RECEIVED",
    startDate: null,
    endDate: null,
  });
  const [isEmpty, setIsEmpty] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const location = useLocation();
  const response = location.state?.response;
  const { logMessages } = useLogMessages();

  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  const shouldShowHeader =
    location.pathname.endsWith("consignments") ||
    location.pathname.endsWith("card") ||
    location.pathname.endsWith("list");

  // Helper function to format dateRange as a single string
  const formatDateRange = (range) => {
    if (!range || range.length !== 2) return { startDate: null, endDate: null };
    if (!range || range.length !== 2) return { startDate: null, endDate: null };

    const [startDate, endDate] = range;

    const formattedStartDate =
      startDate && dayjs(startDate).isValid()
        ? dayjs(startDate).utc().toISOString()
        : null;
    const formattedEndDate =
      endDate && dayjs(endDate).isValid()
        ? dayjs(endDate).utc().toISOString()
        : null;

    return {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
  };

  useEffect(() => {
    const { startDate, endDate } = formatDateRange([
      filters.startDate,
      filters.endDate,
    ]);

    _fetchConsignmentsByMultipleCriteria(
      filters.accountId,
      filters.customerId,
      filters.unitId,
      filters.driverId,
      filters.tripStatus,
      startDate,
      endDate,
      page,
      limit
    );
  }, [page, filters, response]);

  useEffect(() => {
    setWebsocketMessage(logMessages);
  }, [logMessages]);

  useEffect(() => {
    if (
      websocketMessage.module == "Consignments" &&
      (websocketMessage.mode == "Create" || websocketMessage.mode == "Update")
    ) {
      _fetchConsignmentsByMultipleCriteria(
        filters.accountId,
        filters.customerId,
        filters.unitId,
        filters.driverId,
        filters.tripStatus,
        filters.startDate,
        filters.endDate,
        page,
        limit
      );
    }
  }, [websocketMessage]);

  const _fetchConsignmentsByMultipleCriteria = async (
    accountId = defaultAccountId,
    customerId,
    unitId,
    driverId,
    tripStatus,
    startDate,
    endDate,
    initialPage = 1,
    limit = 15
  ) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      let allConsignments = [];
      let currentPage = initialPage;
      let totalFetched = 0;

      let response;
      do {
        response = await fetchConsignmentsByMultipleCriteria(
          accountId,
          customerId,
          unitId,
          driverId,
          tripStatus,
          startDate,
          endDate,
          currentPage,
          limit
        );

        const formattedRows = response.rows.map((element) => ({
          ...element,
          deliverySchedule: {
            ...element.deliverySchedule,
            pickupTime: {
              from: element.deliverySchedule.pickupTime.from,
              to: element.deliverySchedule.pickupTime.to,
            },
            deliveryTime: {
              from: element.deliverySchedule.deliveryTime.from,
              to: element.deliverySchedule.deliveryTime.to,
            },
            expectedPickupDate: formatToLocal(
              element.deliverySchedule.expectedPickupDate
            ),
            expectedDeliveryDate: formatToLocal(
              element.deliverySchedule.expectedDeliveryDate
            ),
          },
          checkpoints: element.checkpoints,
        }));

        allConsignments = [...allConsignments, ...formattedRows];
        totalFetched += response.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < response.count);

      setConsignments(allConsignments);
      setCount(response.count);
      setIsEmpty(allConsignments.length === 0); // Set empty state
    } catch (error) {
      console.error("Error fetching consignments:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
    setConsignments([]);
    setPage(1);
  };

  const fetchMore = () => {
    if (consignments.length < count) {
      setPage((prevPage) => prevPage + 1); // Increment page number for search results
    } else {
      console.log("All consignments have been fetched.");
    }
  };

  const handleTabChange = (event, newValue) => {
    let tripStatus;
    if (newValue === 0) tripStatus = "RECEIVED";
    if (newValue === 1) tripStatus = "";
    if (newValue === 2) tripStatus = "DELIVERED";

    setSelectedTab(newValue); // Update selected tab index

    const { startDate, endDate } = formatDateRange([
      filters.startDate,
      filters.endDate,
    ]);

    // Update filters with the current tab's status
    setFilters((prevFilters) => ({
      ...prevFilters,
      tripStatus: tripStatus,
    }));

    // Fetch consignments with updated filters
    _fetchConsignmentsByMultipleCriteria(
      filters.customerId,
      filters.unitId,
      filters.driverId,
      tripStatus,
      startDate, // Properly pass startDate
      endDate, // Properly pass endDate
      1,
      limit
    );
  };

  return (
    <div className="container">
      {shouldShowHeader && (
        <Grid container flexDirection="column">
          <Grid item xs={12}>
            <ListHeaderNew name="Consignment" mode="Create" />
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{
              marginTop: "30px",
              marginBottom: "30px",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12}>
              <ConsignmentFilters
                filters={filters}
                onFiltersChange={handleFiltersChange}
                selectedTab={selectedTab}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <ViewModeSwitch name="Consignment" />
              <ExportButton name="Consignments" accounts={consignments} />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Outlet
        context={{
          consignments,
          fetchMore,
          isLoading,
          isEmpty,
          handleTabChange,
          selectedTab,
        }}
      />
    </div>
  );
}

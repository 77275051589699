/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import SearchFormSelect from "../../../common/SearchFormSelect";
import { fetchGeofencesByMultipleCriteria } from "../../../../services/geofencesNew.service";
import FilterListIcon from "@mui/icons-material/FilterList";

const GeofenceFilters = ({ onFiltersChange }) => {
  const [filters, setFilters] = useState({
    id: "",
  });
  const [geofences, setGeofences] = useState([]);
  const [page] = useState(1);
  const [limit] = useState(15);
  const [isLoadingGeofences, setIsLoadingGeofences] = useState(false);

  useEffect(() => {
    onFiltersChange(filters);
  }, [filters]);

  useEffect(() => {
    _fetchGeofencesByMultipleCriteria();
  }, [page, limit]);

  // const _fetchGeofencesByAccountId = async () => {
  //   if (isLoadingGeofences) return; // Prevent multiple calls
  //   setIsLoadingGeofences(true);

  //   try {
  //     let allGeofences = [];
  //     let currentPage = 1;
  //     let totalFetched = 0;

  //     let data;
  //     do {
  //       data = await fetchGeofencesByAccountId(currentPage, limit);
  //       allGeofences = [...allGeofences, ...data.rows];
  //       totalFetched += data.rows.length;
  //       currentPage++; // Move to the next page
  //     } while (totalFetched < data.count);

  //     setGeofences(allGeofences);
  //   } catch (error) {
  //     const errorMessage = error?.message || "An unknown error occurred";
  //     console.error("Error fetching geofences:", errorMessage);
  //   } finally {
  //     setIsLoadingGeofences(false);
  //   }
  // };

  const _fetchGeofencesByMultipleCriteria = async (
    billingPlanId = "",
    page = 1,
    limit = 15
  ) => {
    if (isLoadingGeofences) return; // Prevent multiple calls
    setIsLoadingGeofences(true);

    try {
      let allGeofences = [];
      let currentPage = page;
      let totalFetched = 0;

      let response;
      do {
        response = await fetchGeofencesByMultipleCriteria(
          billingPlanId,
          currentPage,
          limit
        );
        allGeofences = [...allGeofences, ...response.rows];
        totalFetched += response.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < response.count);

      setGeofences(allGeofences);
    } catch (error) {
      console.error(
        "Error fetching geofences:",
        error?.message || "An unknown error occurred"
      );
    } finally {
      setIsLoadingGeofences(false);
    }
  };

  const handleFieldChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  return (
    <>
      <Grid container spacing={1} sx={{ display: "flex" }}>
        <Grid item xs={12} sm={1} sx={{ display: "flex" }}>
          <Grid
            sx={{ marginTop: "20px", color: "#6C6C81", fontWeight: "600" }}
            mr={1}
          >
            FILTERS
          </Grid>
          <FilterListIcon sx={{ marginTop: "20px" }} />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <SearchFormSelect
            placeholder="Name"
            value={filters.id}
            onChange={(selected) => {
              handleFieldChange("id", selected);
            }}
            options={geofences.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Grid>
      </Grid>
    </>
  );
};

GeofenceFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired, // Validate that onFiltersChange is a required function prop
};

export default GeofenceFilters;

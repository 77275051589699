import { React } from "react";

import HomeIcon from "./assets/icons/homeIcon.svg";
import DashboardIcon from "./assets/icons/dashboardIcon.svg";
import BillingPlanIcon from "./assets/icons/billingPlanIcon.svg";
import AccountsIcon from "./assets/icons/accountIcon.svg";
import UsersIcon from "./assets/icons/userIcon.svg";
import UnitsIcon from "./assets/icons/unitIcon.svg";
import UnitGroupsIcon from "./assets/icons/unitGroupIcon.svg";
import DevicesIcon from "./assets/icons/deviceIcon.svg";
import RolesIcon from "./assets/icons/roleIcon.svg";
import SimCardsIcon from "./assets/icons/simCardIcon.svg";
import ServicesIcon from "./assets/icons/serviceIcon.svg";
import PrivacyPolicyIcon from "./assets/icons/serviceIcon.svg";
import PaymentsIcon from "./assets/icons/serviceIcon.svg";
import ConsignmentIcon from "./assets/icons/consignmentIcon.svg";
import DriverIcon from "./assets/icons/driversIcon.svg";
import ActiveHomeIcon from "./assets/icons/activeHomeIcon.svg";
import ActiveDashboardIcon from "./assets/icons/activeDashboardIcon.svg";
import ActiveBillingPlanIcon from "./assets/icons/activeBillingPlanIcon.svg";
import ActiveAccountsIcon from "./assets/icons/activeAccountIcon.svg";
import ActiveUsersIcon from "./assets/icons/activeUserIcon.svg";
import ActiveUnitsIcon from "./assets/icons/activeUnitIcon.svg";
import ActiveUnitGroupsIcon from "./assets/icons/activeUnitGroupIcon.svg";
import ActiveDevicesIcon from "./assets/icons/activeDeviceIcon.svg";
import ActiveRolesIcon from "./assets/icons/activeRoleIcon.svg";
import ActiveSimCardsIcon from "./assets/icons/activeSimCardIcon.svg";
import ActiveServicesIcon from "./assets/icons/activeServiceIcon.svg";
import ActivePrivacyPolicyIcon from "./assets/icons/serviceIcon.svg";
import ActivePaymentsIcon from "./assets/icons/serviceIcon.svg";
import ActiveConsignmentIcon from "./assets/icons/activeConsignmentIcon.svg";
import ActiveDriverIcon from "./assets/icons/activeDriverIcon.svg";
import GeofencesIcon from "./assets/icons/geofencesIcon.svg";
import ActiveGeofencesIcon from "./assets/icons/activeGeofencesIcon.svg";
import reportsIcon from "./assets/icons/reportsIcon.svg";
import activeReportsIcon from "./assets/icons/activeReportsIcon.svg";
import UnitsMonitoringIcon from "./assets/icons/unitsMonitoringIcon.svg";
import ActiveUnitsMonitoringIcon from "./assets/icons/activeUnitsMonitoringIcon.svg";
import RouteOptimizationIcon from "./assets/icons/routeOptimizationIcon.svg";
import ActiveRouteOptmizationIcon from "./assets/icons/activeRouteOptimizationIcon.svg";
import VehicleTypesIcon from "./assets/icons/vehicleTypesIcon.svg";
import ActiveVehicleTypesIcon from "./assets/icons/activeVehicleTypesIcon.svg";
import MaintenanceChecklistIcon from "./assets/icons/maintenanceChecklistIcon.svg";
import ActiveMaintenanceChecklistIcon from "./assets/icons/activeMaintenanceChecklistIcon.svg";

import LoginPage from "./modules/management/login/pages/LoginPage";
import Home from "./modules/management/home/pages/HomePage";
import PrivacyPolicy from "./modules/management/privacyPolicy/pages/PrivacyPolicy";
import TermsAndConditions from "./modules/management/termsAndConditions/pages/TermsAndConditions";
import Dashboard from "./modules/management/dashboard/pages/DashBoard";
import Graphs from "./modules/management/dashboard/components/Graphs";

import UsersPage from "./modules/management/users/pages/UsersPage";
import UsersListCard from "./modules/management/users/components/UsersListCard.js";
import UsersListTable from "./modules/management/users/components/UsersListTable.js";

import UsersForm from "./modules/management/users/components/UsersForm";
import UnitsListCard from "./modules/management/units/components/UnitsListCard.js";
import UnitsListTable from "./modules/management/units/components/UnitsListTable.js";

import AccountsPage from "./modules/management/accounts/pages/AccountsPage";
import AccountsCreateForm from "./modules/management/accounts/components/AccountCreateForm";
import AccountsEditForm from "./modules/management/accounts/components/AccountsEditForm";
import AccountsListCard from "./modules/management/accounts/components/AccountsListCard";
import AccountsListTable from "./modules/management/accounts/components/AccountsListTable";

import PaymentsPage from "./modules/management/payments/pages/PaymentsPage";
import PaymentsForm from "./modules/management/payments/components/PaymentsForm";

import BillingPlansPage from "./modules/management/billingPlans/pages/BillingPlansPage";
import BillingPlansForm from "./modules/management/billingPlans/components/BillingPlansForm.js";
import BillingPlansListCard from "./modules/management/billingPlans/components/BillingPlansListCard.js";
import BillingPlansListTable from "./modules/management/billingPlans/components/BillingPlanListTable.js";

import DevicesPage from "./modules/management/devices/pages/DevicesPage";
import DevicesForm from "./modules/management/devices/components/DevicesForm.js";
import DevicesListTable from "./modules/management/devices/components/DevicesListTable.js";
import DevicesListCard from "./modules/management/devices/components/DevicesListCard.js";

import RolesPage from "./modules/management/roles/pages/RolesPage";
import RolesForm from "./modules/management/roles/components/RolesForm";
import RolesListCard from "./modules/management/roles/components/RolesListCard";
import RolesListTable from "./modules/management/roles/components/RolesListTable";

import ServicesPage from "./modules/management/services/pages/ServicesPage";
import ServicesForm from "./modules/management/services/components/ServicesForm";
import ServicesListCard from "./modules/management/services/components/ServicesListCard.js";
import ServicesListTable from "./modules/management/services/components/ServicesListTable.js";

import SimCardsPage from "./modules/management/simCards/pages/SimCardsPage";
import SimCardsListTable from "./modules/management/simCards/components/SimCardsListTable";
import SimCardForm from "./modules/management/simCards/components/SimCardForm";
import SimCardsListCard from "./modules/management/simCards/components/SimCardsListCard.js";

import UnitGroupsPage from "./modules/management/unitGroups/pages/UnitGroupsPage";
import UnitGroupsFormNew from "./modules/management/unitGroups/components/UnitGroupsForm.js";
import UnitGroupsListCard from "./modules/management/unitGroups/components/UnitGroupsListCard.js";
import UnitGroupsListTable from "./modules/management/unitGroups/components/UnitGroupsListTable";

import ConsignmentsPage from "./modules/monitoring/consignments/pages/ConsignmentsPage";
import ConsignmentsForm from "./modules/monitoring/consignments/components/ConsignmentsForm";
import ConsignmentsListCard from "./modules/monitoring/consignments/components/ConsignmentsListCard";
import ConsignmentsListTable from "./modules/monitoring/consignments/components/ConsignmentListTable";

import DriversPage from "./modules/monitoring/drivers/pages/DriversPage";
import DriversForm from "./modules/monitoring/drivers/components/DriversForm";
import DriversListCard from "./modules/monitoring/drivers/components/DriversListCard";
import DriversListTable from "./modules/monitoring/drivers/components/DriversListTable";

import GeofencesPage from "./modules/monitoring/geofences/pages/GeofencesPage.js";
import GeofencesForm from "./modules/monitoring/geofences/components/GeofencesForm.js";
import GeofencesListCard from "./modules/monitoring/geofences/components/GeofencesListCard.js";
import GeofencesListTable from "./modules/monitoring/geofences/components/GeofencesListTable.js";

import ConsigneesPage from "./modules/monitoring/customers/pages/ConsigneesPage.js";
import ConsigneesListCard from "./modules/monitoring/customers/components/ConsigneesListCard.js";
import ConsigneesListTable from "./modules/monitoring/customers/components/ConsigneesListTable.js";
import ConsigneesForm from "./modules/monitoring/customers/components/ConsigneesForm.js";

import VehicleTypeForm from "./modules/settings/vehicleType/components/VehicleTypeForm";
import MaintenanceCheckListPage from "./modules/settings/maintenanceChecklist/pages/maintenanceChecklistPage";
import VehicleTypePage from "./modules/settings/vehicleType/pages/vehicleTypePage";
import UnitsFormNew from "./modules/management/units/components/UnitsForm.js";
import CheckListForm from "./modules/settings/maintenanceChecklist/components/CheckListForm";
import DriversConsignmentsListTable from "./modules/monitoring/drivers/components/DriversConsignmentListTable.js";
import DriversConsignmentsListCard from "./modules/monitoring/drivers/components/DriversConsignmentListCard.js";
import ConsignmentSummeryPage from "./modules/monitoring/consignments/components/ConsignmentSummeryPage.js";
import UnitsMonitoringPage from "./modules/monitoring/units/pages/UnitsMonitoringPage.js";
import RouteOptimzationListCard from "./modules/monitoring/routeOptimization/components/RouteOptimzationListCard.js";
import RouteOptimizationPage from "./modules/monitoring/routeOptimization/pages/RouteOptimizationPage.js";
import RouteOptimzationListTable from "./modules/monitoring/routeOptimization/components/RouteOptimzationListTable.js";
import RouteOptimizationForm from "./modules/monitoring/routeOptimization/components/RouteOptimizationForm.js";
import UnitsPage from "./modules/management/units/pages/UnitsPage.js";
import SensorForm from "./modules/management/sensors/components/SensorForm.js";
import SensorsPage from "./modules/management/sensors/pages/SensorsPage.js";
import SensorsListCard from "./modules/management/sensors/components/SensorsListCard.js";
import SensorsListTable from "./modules/management/sensors/components/SensorsListTable.js";
import ReportsPage from "./modules/monitoring/reports/pages/ReportsPage.js";
import ReportsListCard from "./modules/monitoring/reports/components/ReportsListCard.js";
import ReportsListTable from "./modules/monitoring/reports/components/ReportsListTable.js";

// import DriversConsignmentsListCard from "./modules/monitoring/drivers/components/DriversConsignmentListCard.js";

export const routes = [
  {
    path: "/",
    element: <LoginPage />,
    label: "Login",
  },
  {
    path: "/home",
    element: <Home />,
    label: "Home",
    icon: HomeIcon,
    activeIcon: ActiveHomeIcon,
  },
  {
    path: "/management/dashboard",
    element: <Dashboard />,
    label: "Dashboard",
    type: "management",
    icon: DashboardIcon,
    activeIcon: ActiveDashboardIcon,
    children: [
      {
        path: "",
        element: <Graphs />,
      },
    ],
  },
  {
    path: "/management/billing-plans",
    element: <BillingPlansPage />,
    label: "Billing Plans",
    type: "management",
    icon: BillingPlanIcon,
    activeIcon: ActiveBillingPlanIcon,
    children: [
      {
        path: "",
        element: <BillingPlansListCard />,
      },
      {
        path: "card",
        element: <BillingPlansListCard />,
      },
      {
        path: "list",
        element: <BillingPlansListTable />,
      },
      {
        path: "create",
        element: <BillingPlansForm />,
      },
      {
        path: "edit/:id",
        element: <BillingPlansForm />,
      },
    ],
  },
  {
    path: "/management/accounts",
    element: <AccountsPage />,
    label: "Accounts",
    type: "management",
    icon: AccountsIcon,
    activeIcon: ActiveAccountsIcon,
    children: [
      {
        path: "",
        element: <AccountsListCard />,
      },
      {
        path: "card",
        element: <AccountsListCard />,
      },
      {
        path: "list",
        element: <AccountsListTable />,
      },
      {
        path: "create",
        element: <AccountsCreateForm mode="create" />,
      },
      {
        path: "edit/:id/:tab",
        element: <AccountsEditForm mode="edit" />,
      },
    ],
  },
  {
    path: "/management/users",
    element: <UsersPage />,
    label: "Users",
    type: "management",
    icon: UsersIcon,
    activeIcon: ActiveUsersIcon,
    children: [
      {
        path: "",
        element: <UsersListCard />,
      },
      {
        path: "card",
        element: <UsersListCard />,
      },
      {
        path: "list",
        element: <UsersListTable />,
      },
      {
        path: "create",
        element: <UsersForm mode="create" />,
      },
      {
        path: "edit/:id",
        element: <UsersForm mode="edit" />,
      },
    ],
  },
  {
    path: "/management/units",
    element: <UnitsPage />,
    label: "Units",
    type: "management",
    icon: UnitsIcon,
    activeIcon: ActiveUnitsIcon,
    children: [
      {
        path: "",
        element: <UnitsListCard />,
      },
      {
        path: "card",
        element: <UnitsListCard />,
      },
      {
        path: "list",
        element: <UnitsListTable />,
      },
      {
        path: "create",
        element: <UnitsFormNew mode="create" />,
      },
      {
        path: "edit/:id",
        element: <UnitsFormNew mode="edit" />,
      },
    ],
  },
  {
    path: "/management/unit-groups",
    element: <UnitGroupsPage />,
    label: "Unit Groups",
    type: "management",
    icon: UnitGroupsIcon,
    activeIcon: ActiveUnitGroupsIcon,
    children: [
      {
        path: "",
        element: <UnitGroupsListCard />,
      },
      {
        path: "card",
        element: <UnitGroupsListCard />,
      },
      {
        path: "list",
        element: <UnitGroupsListTable />,
      },
      {
        path: "create",
        element: <UnitGroupsFormNew mode="create" />,
      },
      {
        path: "edit/:id",
        element: <UnitGroupsFormNew mode="edit" />,
      },
    ],
  },
  {
    path: "/management/devices",
    element: <DevicesPage />,
    label: "Devices",
    type: "management",
    icon: DevicesIcon,
    activeIcon: ActiveDevicesIcon,
    children: [
      {
        path: "",
        element: <DevicesListCard />,
      },
      {
        path: "card",
        element: <DevicesListCard />,
      },
      {
        path: "list",
        element: <DevicesListTable />,
      },
      {
        path: "create",
        element: <DevicesForm mode="create" />,
      },
      {
        path: "edit/:id",
        element: <DevicesForm mode="edit" />,
      },
    ],
  },
  {
    path: "/management/sensors",
    element: <SensorsPage />,
    label: "Sensors",
    type: "management",
    icon: DevicesIcon,
    activeIcon: ActiveDevicesIcon,
    children: [
      {
        path: "",
        element: <SensorsListCard />,
      },
      {
        path: "card",
        element: <SensorsListCard />,
      },
      {
        path: "list",
        element: <SensorsListTable />,
      },
      {
        path: "create",
        element: <SensorForm mode="create" />,
      },
      {
        path: "edit/:id",
        element: <SensorForm mode="edit" />,
      },
    ],
  },
  {
    path: "/management/sim-cards",
    element: <SimCardsPage />,
    label: "Sim Cards",
    type: "management",
    icon: SimCardsIcon,
    activeIcon: ActiveSimCardsIcon,
    children: [
      {
        path: "",
        element: <SimCardsListCard />,
      },
      {
        path: "card",
        element: <SimCardsListCard />,
      },
      {
        path: "list",
        element: <SimCardsListTable />,
      },
      {
        path: "create",
        element: <SimCardForm mode="create" />,
      },
      {
        path: "edit/:id",
        element: <SimCardForm mode="edit" />,
      },
    ],
  },
  {
    path: "/monitoring/consignees",
    element: <ConsigneesPage />,
    label: "Consignees",
    type: "monitoring",
    icon: UsersIcon,
    activeIcon: ActiveUsersIcon,
    children: [
      {
        path: "",
        element: <ConsigneesListCard />,
      },
      {
        path: "card",
        element: <ConsigneesListCard />,
      },
      {
        path: "list",
        element: <ConsigneesListTable />,
      },
      {
        path: "create",
        element: <ConsigneesForm mode="create" />,
      },
      {
        path: "edit/:id",
        element: <ConsigneesForm mode="edit" />,
      },
    ],
  },
  {
    path: "/monitoring/consignments",
    element: <ConsignmentsPage />,
    label: "Consignments",
    type: "monitoring",
    icon: ConsignmentIcon,
    activeIcon: ActiveConsignmentIcon,
    children: [
      {
        path: "",
        element: <ConsignmentsListCard />,
      },
      {
        path: "card",
        element: <ConsignmentsListCard />,
      },
      {
        path: "list",
        element: <ConsignmentsListTable />,
      },
      {
        path: "create",
        element: <ConsignmentsForm mode="create" />,
      },
      {
        path: "edit/:id",
        element: <ConsignmentsForm mode="edit" />,
      },
      {
        path: "summery/:id",
        element: <ConsignmentSummeryPage />,
      },
    ],
  },
  {
    path: "/settings/vehicle-type",
    element: <VehicleTypePage />,
    label: "Vehicle Types",
    type: "settings",
    icon: VehicleTypesIcon,
    activeIcon: ActiveVehicleTypesIcon,
    children: [
      {
        path: "",
        element: <VehicleTypeForm />,
      },
    ],
  },
  {
    path: "/settings/maintenance-checklist",
    element: <MaintenanceCheckListPage />,
    label: "Maintenance CheckList",
    type: "settings",
    icon: MaintenanceChecklistIcon,
    activeIcon: ActiveMaintenanceChecklistIcon,
    children: [
      {
        path: "",
        element: <CheckListForm />,
      },
    ],
  },
  {
    path: "/monitoring/units",
    element: <UnitsMonitoringPage />,
    label: "Units Monitoring",
    type: "monitoring",
    icon: UnitsMonitoringIcon,
    activeIcon: ActiveUnitsMonitoringIcon,
    children: [
      {
        path: "",
        element: <UnitsMonitoringPage />,
      },
    ],
  },
  {
    path: "/monitoring/reports",
    element: <ReportsPage />,
    label: "Reports",
    type: "monitoring",
    icon: reportsIcon,
    activeIcon: activeReportsIcon,
    children: [
      {
        path: "card",
        element: <ReportsListCard />,
      },
      {
        path: "",
        element: <ReportsListTable />,
      },
    ],
  },
  {
    path: "/management/services",
    element: <ServicesPage />,
    label: "Services",
    type: "management",
    icon: ServicesIcon,
    activeIcon: ActiveServicesIcon,
    children: [
      {
        path: "",
        element: <ServicesListCard />,
      },
      {
        path: "card",
        element: <ServicesListCard />,
      },
      {
        path: "list",
        element: <ServicesListTable />,
      },
      {
        path: "create",
        element: <ServicesForm mode="create" />,
      },
      {
        path: "edit/:id",
        element: <ServicesForm mode="edit" />,
      },
    ],
  },
  {
    path: "/management/roles",
    element: <RolesPage />,
    type: "management",
    label: "Roles",
    icon: RolesIcon,
    activeIcon: ActiveRolesIcon,
    children: [
      {
        path: "",
        element: <RolesListCard />,
      },
      {
        path: "card",
        element: <RolesListCard />,
      },
      {
        path: "list",
        element: <RolesListTable />,
      },
      {
        path: "create",
        element: <RolesForm mode="create" />,
      },
      {
        path: "edit/:id",
        element: <RolesForm mode="edit" />,
      },
    ],
  },
  {
    path: "/management/payments",
    element: <PaymentsPage />,
    label: "Payments",
    type: "management",
    icon: PaymentsIcon,
    activeIcon: ActivePaymentsIcon,
    children: [
      {
        path: "create",
        element: <PaymentsForm mode="create" />,
      },
      {
        path: "edit/:id",
        element: <PaymentsForm mode="edit" />,
      },
    ],
  },

  {
    path: "/management/payments/create",
    element: <PaymentsForm mode="create" />,
    label: "Payments",
    type: "management",
    icon: ServicesIcon,
    activeIcon: ActiveServicesIcon,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    label: "Privacy Policy",
    icon: PrivacyPolicyIcon,
    activeIcon: ActivePrivacyPolicyIcon,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
    label: "Terms and Conditions",
    icon: PrivacyPolicyIcon,
    activeIcon: ActivePrivacyPolicyIcon,
  },
  {
    path: "/monitoring/geofences",
    element: <GeofencesPage />,
    label: "Geofences",
    type: "monitoring",
    icon: GeofencesIcon,
    activeIcon: ActiveGeofencesIcon,
    children: [
      {
        path: "",
        element: <GeofencesListCard />,
      },
      {
        path: "card",
        element: <GeofencesListCard />,
      },
      {
        path: "list",
        element: <GeofencesListTable />,
      },
      {
        path: "create",
        element: <GeofencesForm />,
      },
      {
        path: "edit/:id",
        element: <GeofencesForm />,
      },
    ],
  },
  {
    path: "/monitoring/drivers",
    element: <DriversPage />,
    label: "Drivers",
    type: "monitoring",
    icon: DriverIcon,
    activeIcon: ActiveDriverIcon,
    children: [
      {
        path: "",
        element: <DriversListCard />,
      },
      {
        path: "card",
        element: <DriversListCard />,
      },
      {
        path: "list",
        element: <DriversListTable />,
      },
      {
        path: "create",
        element: <DriversForm mode="create" />,
      },
      {
        path: "edit/:id",
        element: <DriversForm mode="edit" />,
      },
      {
        path: "consignment/:id",
        element: <DriversConsignmentsListCard />,
      },
      {
        path: "consignment/card/:id",
        element: <DriversConsignmentsListCard />,
      },
      {
        path: "consignment/list/:id",
        element: <DriversConsignmentsListTable />,
      },
    ],
  },
  {
    path: "/monitoring/route-optimization",
    element: <RouteOptimizationPage />,
    label: "Route Optimization",
    type: "monitoring",
    icon: RouteOptimizationIcon,
    activeIcon: ActiveRouteOptmizationIcon,
    children: [
      {
        path: "",
        element: <RouteOptimzationListCard />,
      },
      {
        path: "card",
        element: <RouteOptimzationListCard />,
      },
      {
        path: "list",
        element: <RouteOptimzationListTable />,
      },
      {
        path: "create",
        element: <RouteOptimizationForm mode="create" />,
      },
    ],
  },
];

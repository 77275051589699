import axios from "axios";
import {
  userApi,
  loginApi,
  MASTER_KEY,
  forgetPasswordApi,
  resetPasswordApi,
} from "../utils/constant";

const api = axios.create({
  baseURL: userApi,
  headers: {
    "Content-Type": "application/json",
  },
});

// Intercept requests to add the authorization token
api.interceptors.request.use(
  (config) => {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    if (access_token) {
      config.headers.Authorization = `${access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Centralized error handling to log and throw errors.
const handleApiError = (error) => {
  if (error.response) {
    console.error("API Error:", error.response.data);
    throw error.response.data;
  } else if (error.request) {
    console.error("No response received:", error.request);
    throw new Error("No response received from server.");
  } else {
    console.error("Request setup error:", error.message);
    throw new Error("Request setup error.");
  }
};

// Fetch all users with optional query parameters
export const fetchAllUsers = async (queryParams) => {
  try {
    const response = await api.get("/", { params: queryParams });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const loginUser = async (loginData) => {
  try {
    const response = await axios.post(loginApi, loginData, {
      headers: {
        Authorization: `Bearer ${MASTER_KEY}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("Server Error:", error.response.data);
      throw new Error(error.response.data.message || "Server Error");
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No Response from Server");
      throw new Error("No Response from Server");
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Request Error:", error.message);
      throw new Error("Request Error");
    }
  }
};

export const forgetPassword = async (userData) => {
  try {
    const response = await api.post(forgetPasswordApi, userData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const resetPassword = async (userData) => {
  try {
    const response = await api.post(resetPasswordApi, userData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const validateUser = async (id, account, role) => {
  try {
    const response = await api.get(
      `/validateUser?userId=${id}&roleId=${role}&accountId=${account}`
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getUserStatusCounts = async (date, interval) => {
  const interval1 = interval === "daterange" ? "RANGE" : interval;

  // Ensure date is formatted correctly if it's provided
  if (date && typeof date.from === "object") {
    date.from = date.from.format("YYYY-MM-DD");
    date.to = date.to.format("YYYY-MM-DD");
  }

  // Build the query string based on the presence of date
  const queryString = date
    ? `/getUserStatusCounts?from=${date.from}&to=${date.to}&interval=${interval1}`
    : `/getUserStatusCounts?interval=${interval1}`; // No dates for MONTHLY

  try {
    const response = await api.get(queryString);

    return response.data; // Return the fetched data
  } catch (error) {
    handleApiError(error);
    return []; // Return an empty array in case of error
  }
};

// Fetch  user by ID
export const getUserById = async (userId) => {
  try {
    const response = await api.get(`/${userId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
// Fetch users by account id with pagination
export const fetchUsersByAccountId = async (page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchUsersByAccountId", {
      params: { accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch users by account id and roles with pagination
// Fetch users by account id and roles with pagination
export const fetchUsersByRoleNameAndAccountId = async (
  roleName,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchUsersByRoleNameAndAccountId", {
      params: { roleName, accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch users by account id and name with pagination
export const fetchUsersByNameAndAccountId = async (name, page, limit) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchUsersByNameAndAccountId", {
      params: { name, accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch users by account id and userName with pagination
export const fetchUsersByUsernameAndAccountId = async (
  userName,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchUsersByUsernameAndAccountId", {
      params: { userName, accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch users by account id and userName and role with pagination
export const fetchUsersByNameAndAccountIdAndRoleName = async (
  name,
  roleName,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get("/fetchUsersByNameAndAccountIdAndRoleName", {
      params: { name, roleName, accountId, page, limit },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// Fetch users by account id and userName and role with pagination
export const fetchUsersByUsernameAndAccountIdAndRoleName = async (
  roleName,
  userName,
  page,
  limit
) => {
  const accountId = JSON.parse(localStorage.getItem("defaultAccountId"));
  try {
    const response = await api.get(
      "/fetchUsersByUsernameAndAccountIdAndRoleName",
      {
        params: { roleName, userName, accountId, page, limit },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const appendFormData = (formData, data, parentKey) => {
  if (data && typeof data === "object" && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      appendFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    let value = data;
    if (typeof data === "number") {
      value = `${data}#number`; // Append number suffix
    } else if (typeof data === "boolean") {
      value = `${data}#boolean`; // Append boolean suffix
    }
    formData.append(parentKey, value);
  }
};
const appendDocumentsToFormData = (formData, documents) => {
  documents.forEach((doc, index) => {
    if (doc.file) {
      formData.append(doc.documentType, doc.file);
    }
    console.log(index);
    // formData.append(documentsURL[index].fileURL,  doc.fileURL);
    // formData.append(`documentsURL[${index}].fileURL`, doc.fileURL);
    // formData.append(`documentsURL[${index}].isDelete`, doc.isDelete);
    // formData.append(`documentsURL[${index}].documentType`, doc.documentType);
    // formData.append(`documentsURL[${index}].customerView`, doc.customerView);
    // formData.append(`documentsURL[${index}].driverView`, doc.driverView);
  });
};
const appendFormData2 = (formData, data, parentKey = "") => {
  if (data && typeof data === "object" && !(data instanceof File)) {
    if (Array.isArray(data)) {
      data.forEach((item, index) => {
        console.log(`Appending array item: ${parentKey}[${index}]`);
        appendFormData2(formData, item, `${parentKey}[${index}]`);
      });
    } else {
      Object.keys(data).forEach((key) => {
        console.log(
          `Appending object key: ${parentKey ? `${parentKey}.${key}` : key}`
        );
        appendFormData2(
          formData,
          data[key],
          parentKey ? `${parentKey}.${key}` : key
        );
      });
    }
  } else {
    console.log(`Appending value: ${parentKey} = ${data}`);
    formData.append(parentKey, data);
  }
};

export const createUser = async (userData) => {
  try {
    const formData = new FormData();

    appendFormData2(formData, userData);

    for (const pair of formData.entries()) {
      console.log(`${pair[0]}:`, pair[1]);
    }

    const response = await api.post("/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const updateUser = async (id, userData) => {
  try {
    const formData = new FormData();

    // Append user data to formData
    appendFormData(formData, userData);

    // Append documents to formData
    if (userData.documentsURL) {
      appendDocumentsToFormData(formData, userData.documentsURL);
    }

    const response = await api.put(`/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await api.delete(`/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const handleApiResponse = async (promise) => {
  try {
    const response = await promise;
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchUsersByMultipleCriteria = async (
  accountId,
  id,
  userName,
  roleName,
  page,
  limit
) => {
  try {
    const response = await api.get("/fetchUsersByMultipleCriteria", {
      params: {
        accountId,
        id,
        userName,
        roleName,
        page,
        limit,
      },
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

import React from 'react';
import { StyledInputControlContainer, StyledCheckboxButton } from '../../styledComponents';
import { Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import FormError from './FormError';

const FormOptionSelector = ({
  label,
  options,
  selectedOption,
  handleOptionChange,
  required = false,
  error,
  errorMessage,
  disabled = false, 
}) => {
  return (
    <StyledInputControlContainer>
      <Typography variant="label">
        {label}
        {required && ' *'}
      </Typography>
      <Grid
        xs={12}
        md={10}
        sx={{
          backgroundColor: "#f7f7f8",
          padding: "2px",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: '8px',
        }}
        spacing={1}
      >
        <Grid container spacing={2}>
          {options.map((option, optionIndex) => (
            <Grid item key={optionIndex}>
              <StyledCheckboxButton
                item
                variant={
                  selectedOption === option.value
                    ? "contained"
                    : "outlined"
                }
                onClick={() => !disabled && handleOptionChange(option.value)} // Prevent click if disabled
                disabled={disabled} // Disable button if in edit mode
              >
                {option.name}
              </StyledCheckboxButton>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {error && (
        <FormError errorMessage={errorMessage} />
      )}
    </StyledInputControlContainer>
  );
};

FormOptionSelector.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOption: PropTypes.any.isRequired,
  handleOptionChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool, 
};

export default FormOptionSelector;
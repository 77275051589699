const defaultAccountId =
  localStorage.getItem("defaultAccountId") !== ""
    ? JSON.parse(localStorage.getItem("defaultAccountId"))
    : "";
export const DeviceModel = {
  accountId: defaultAccountId,
  deviceImageUrl: "",
  deviceType: "",
  name: "",
  make: "",
  model: "",
  SSID: "",
  simCardId: "",
  UID: "",
  serverAddress: "",
  rfids: [],
  description: "",
  setupInstructions: "",
  status: "UNPROVISIONED",
  createdBy: "",
  updatedBy: "",
};

import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledModal2 } from '../../styledComponents'; // Ensure this styled component is applicable
import SensorForm from '../management/sensors/components/SensorForm';


const SensorsFormModal = ({ open, handleSensorsFormModalClose }) => {

  // Successfully saved or errored the form
  const handleSuccess = () => {
    handleSensorsFormModalClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleSuccess} // Close the modal on success
      aria-labelledby="sensor-modal-title"
      aria-describedby="sensor-modal-description"
    >
      <StyledModal2>
      <IconButton
          onClick={handleSuccess}
          style={{ alignSelf: 'flex-end' }}
        >
          <CloseIcon />
        </IconButton>
        <SensorForm isShortcut={true} onSuccess={handleSuccess} />
      </StyledModal2>
    </Modal>
  );
};

// PropTypes validation
SensorsFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleSensorsFormModalClose: PropTypes.func.isRequired,
};

export default SensorsFormModal;

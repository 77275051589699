import React from "react";
import { Modal, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";

const LocationPermissionModal = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="location-permission-popup"
      aria-describedby="request-location-permission"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "8px",
          margin: "auto",
          top: "30%",
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center", // Center text alignment
        }}
      >
        <Typography variant="h6" id="location-permission-popup">
          Location Access Required
        </Typography>
        <Typography
          id="request-location-permission"
          style={{ marginTop: "10px", marginBottom: "20px" }}
        >
          Please enable location services for this site <br />
          to access your current location.
        </Typography>

        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </div>
    </Modal>
  );
};

LocationPermissionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LocationPermissionModal;

import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledModal2 } from '../../styledComponents';
import DevicesForm from '../management/devices/components/DevicesForm';


const DevicesFormModal = ({ open, handleDevicesFormModalClose }) => {
  // Handle successful save or error in the form
  const handleSuccess = () => {
    handleDevicesFormModalClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleSuccess} // Trigger handleSuccess when the modal closes
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <StyledModal2>
      <IconButton
          onClick={handleSuccess}
          style={{ alignSelf: 'flex-end' }}
        >
          <CloseIcon />
        </IconButton>
        <DevicesForm isShortcut={true} onSuccess={handleSuccess} />
      </StyledModal2>
    </Modal>
  );
};

// PropTypes validation
DevicesFormModal.propTypes = {
  open: PropTypes.bool.isRequired, // Indicates if the modal is open
  handleDevicesFormModalClose: PropTypes.func, // Callback to close the modal
  onSuccess: PropTypes.func, // Optional success callback
};

export default DevicesFormModal;

import React, { useCallback, useEffect, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import { envReportTableColumns } from "../../../../utils/constant";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell, StyledTableHead } from "../../../../styledComponents";
import debounce from "lodash.debounce";
import Loader from "../../../common/Loader";

const EnvReports = () => {
  const { envReports, fetchMore, isLoading } = useOutletContext();
  const containerRef = useRef(null);

  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      // Check if the user is at the bottom of the container and if we are not already loading
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading // Adding a buffer of 50px
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  ); // Debounce with 300ms delay


  // Attach the scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    // Remove the event listener on cleanup
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [envReports, isLoading, handleScroll]);

  return (
    <div style={{ width: "100%", height: "100vh", padding: "16px" }}>
      <TableContainer
        component={Paper}
        sx={{
          overflow: "auto",
          width: "100%",
          height: "100%",
          borderRadius: "8px",
        }}
        ref={containerRef}
      >
        {isLoading && <Loader />}
        <Table aria-label="device reports table" sx={{ minWidth: "650px" }}>
          <StyledTableHead>
            <TableRow>
              {envReportTableColumns.map((column) => (
                <StyledTableCell key={column} sx={{ padding: "16px" }}>
                  <Typography variant="tableHeader">{column}</Typography>
                </StyledTableCell>
              ))}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {envReports?.map((report) => (
              <TableRow key={report.deviceId} sx={{ cursor: "pointer" }}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ padding: "16px" }}
                >
                  <Typography variant="tableValue">
                    {report.deviceId}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ padding: "16px" }}>
                  <Typography variant="tableValue">
                    {report.totalDistance || "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ padding: "16px" }}>
                  <Typography variant="tableValue">
                    {report.totalFuelConsumption || "--"}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ padding: "16px" }}>
                  <Typography variant="tableValue">
                    {report.carbonDioxide} kg
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ padding: "16px" }}>
                  <Typography variant="tableValue">
                    {report.carbonMonoxide} kg
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ padding: "16px" }}>
                  <Typography variant="tableValue">
                    {report?.nitrogenOxides} kg
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ padding: "16px" }}>
                  <Typography variant="tableValue">
                    {report.particulateMatter} kg
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left" sx={{ padding: "16px" }}>
                  <Typography variant="tableValue">
                    {report.hydrocarbons} kg
                  </Typography>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EnvReports;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
} from "@mui/material";
import { UnitsModel } from "../../../../model/units.model";
import { Title } from "../styles/UnitsStyles";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import SuccessMessage from "../../../common/SuccessMessage";
import UnitsForm1 from "./UnitsForm1";
import UnitsForm2 from "./UnitsForm2";
import UnitsForm4 from "./UnitsForm4";
import Loader from "../../../common/Loader";
import { BackButton, NextButton } from "../../../../styledComponents";
import {
  createUnit,
  getUnitById,
  updateUnit,
} from "../../../../services/unitNew.service";
import { validateForm, validateForm2 } from "../../../../utils/yupValidations";
import {
  unitsSchemaStep1,
  unitsSchemaStep2,
  // unitsSchemaStep3
} from "../../../../utils/validation-schemas/unitsSchema";
import { getRoute } from "../../../../constants/routes";
import { iconMap } from "../../../../constants/iconsList";

const UnitsFormNew = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [unit, setUnit] = useState(UnitsModel);
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [defaultsSet, setDefaultsSet] = useState(false);
  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  const steps =
    unit.unitType === "Vehicle"
      ? ["General", "Vehicle Details", "Sensors"]
      : ["General"];

  const validationSchemas = {
    1: unitsSchemaStep1,
    2: unitsSchemaStep2,
    // 3: unitsSchemaStep3,
    4: unitsSchemaStep2,
  };

  useEffect(() => {
    if (id) {
      fetchUnitById(id);
    } else {
      setDefaults();
    }
  }, [id]);

  const fetchUnitById = async (id) => {
    setIsLoading(true);
    try {
      const data = await getUnitById(id);
      setUnit(data);
      handleResponse(data, "Unit fetched successfully!", "Get");
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const setDefaults = () => {
    const updatedUnit = {
      ...unit,
      accountId: defaultAccountId,
    };
    setUnit(updatedUnit);
    setDefaultsSet(true);
  };

  const handleValueChange = async (
    updatedUnit,
    fieldName,
    fieldValue,
    step
  ) => {
    setUnit((prevUnit) => ({
      ...prevUnit,
      ...updatedUnit,
    }));

    const schema = validationSchemas[step];
    try {
      await validateForm2(updatedUnit, fieldName, setValidationErrors, schema);
    } catch (error) {
      console.error("Field validation error:", error);
    }
  };

  const handleNext = async () => {
    try {
      const schema = validationSchemas[activeStep + 1];
      if (schema) {
        const isValid = await validateForm(unit, setValidationErrors, schema);
        if (isValid) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          console.error("Form validation failed");
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const schema =
        unit.unitType === "Vehicle"
          ? validationSchemas[2]
          : validationSchemas[1];
      const isValid = await validateForm(unit, setValidationErrors, schema);
      if (!isValid) {
        console.error("Form validation failed");
        setIsLoading(false);
        return;
      }

      let response;
      if (id === undefined) {
        response = await createUnit(unit);
        handleResponse(response, "Unit created successfully!", "Card");
      } else {
        response = await updateUnit(unit);
        handleResponse(response, "Unit updated successfully!", "Card");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
      setTimeout(() => {
        if (mode === "Card") {
          navigate(getRoute("Unit", mode));
        }
      }, 3000); // Closing parentheses and braces for setTimeout
    }
  };

  const handleError = (error) => {
    window.scrollTo(0, 0);
    if (error.name === "ValidationError") {
      handleValidationErrors(error);
    } else {
      setErrors(error.error);
    }
  };

  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message;
      return acc;
    }, {});
    setValidationErrors(errors);
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  const handleReset = () => setActiveStep(0);

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <UnitsForm1
            unit={unit}
            onFormValueChange={handleValueChange}
            validationErrors={validationErrors}
          />
        );
      case 1:
        if (unit.unitType === "Vehicle") {
          return (
            <UnitsForm2
              unit={unit}
              onFormValueChange={handleValueChange}
              validationErrors={validationErrors}
            />
          );
        }
        break;
      case 2:
        if (unit.unitType === "Vehicle") {
          return (
            <UnitsForm4
              unit={unit}
              onFormValueChange={handleValueChange}
              validationErrors={validationErrors}
            />
          );
        }
        break;
      default:
        return "Unknown stepIndex";
    }
  };

  const getStepColor = (index) => {
    if (index < activeStep) {
      return "#26861D"; // Completed step color
    } else if (index === activeStep) {
      return "#0772B8"; // Active step color
    } else {
      return "#EEEEF0"; // Inactive step color
    }
  };

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  // Check if id is undefined and defaultSet is true
  if (id === undefined && !defaultsSet) {
    return null; // or any other fallback UI you want to show
  }
  return (
    <Box>
      <ErrorMessage1 errors={errors} onClose={handleClose} />
      <SuccessMessage message={successMessage} onClose={handleClose} />
      {isLoading && <Loader />}
      <Box sx={{ width: "100%", padding: "Calc(2.3%-50px) Calc(10.8%-50px)" }}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #d8d8df",
            paddingBottom: "26.5px",
          }}
        >
          <Grid xs={12} md={5}>
            <Title item>
              {" "}
              <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
                <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
              </IconButton>
              {id ? "Update" : "Create"} Unit
            </Title>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step
                  key={label}
                  sx={{
                    "& .MuiStepIcon-root": {
                      height: "32px",
                      width: "32px",
                      color: getStepColor(index),
                    },
                    "& .MuiStepIcon-root.Mui-active": {
                      color: "#0772B8", // Active step color
                    },
                    "& .MuiStepIcon-text": {
                      fill: "#5B5B6E", // Change this to your desired number color
                    },
                    "& .MuiStepIcon-root.Mui-active .MuiStepIcon-text": {
                      fill: "#FFFFFF",
                    },
                  }}
                >
                  <StepLabel>
                    <Typography
                      sx={{
                        font: "16px",
                        fontWeight: index === activeStep ? 600 : 400,
                        color: "#0F446B",
                      }}
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 3, marginBottom: 3 }}>
          {activeStep === steps.length ? (
            <Box>
              <Typography sx={{ mt: 2 }}>All steps completed</Typography>
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          ) : (
            <Box>
              {getStepContent(activeStep)}
              <Grid
                xs={12}
                sx={{
                  mt: 3,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <BackButton disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </BackButton>
                <NextButton
                  onClick={
                    activeStep === steps.length - 1 ? handleSubmit : handleNext
                  }
                >
                  {activeStep === steps.length - 1
                    ? "FINISH"
                    : "SAVE & CONTINUE"}
                </NextButton>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default UnitsFormNew;

// const UnitsFormNew = () => {
//   const { id } = useParams()
//   const [errors, setErrors] = useState([])
//   const [successMessage, setSuccessMessage] = useState('')
//   const [activeStep, setActiveStep] = useState(0)
//   const [unit, setUnit] = useState(UnitsModel)
//   const navigate = useNavigate()
//   const [validationErrors, setValidationErrors] = useState({})
//   const [isLoading, setIsLoading] = useState(false)
//   const [defaultsSet, setDefaultsSet] = useState(false)
//   const defaultAccountId =
//     localStorage.getItem('defaultAccountId') !== ''
//       ? JSON.parse(localStorage.getItem('defaultAccountId'))
//       : ''

//   const steps =
//     unit.unitType == 'Vehicle'
//       ? ['General', 'Vehicle Details', 'Sensors']
//       : ['General', 'E Lock Details']

//   const validationSchemas = {
//     1: unitsSchemaStep1,
//     2: unitsSchemaStep2,
//     3: unitsSchemaStep3,
//     4: unitsSchemaStep2
//   }

//   useEffect(() => {
//     if (id) {
//       _getUnitById(id)
//     } else {
//       setDefaults()
//     }
//   }, [id])

//   const _getUnitById = async (id) => {
//     setIsLoading(true);
//     try {
//       const data = await getUnitById(id);
//       setUnit(data);
//       handleResponse(data, 'Unit fetched successfully!');
//     } catch (error) {
//       handleError(error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const setDefaults = () => {
//     const updatedUnit = {
//       ...unit,
//       accountId: defaultAccountId
//     }
//     setUnit(updatedUnit)
//     setDefaultsSet(true)
//   }

//   const handleValueChange = async (
//     updatedUnit,
//     fieldName,
//     fieldValue,
//     step
//   ) => {
//     setUnit((prevUnit) => ({
//       ...prevUnit,
//       ...updatedUnit
//     }))

//     const schema = validationSchemas[step]
//     try {
//       await validateForm2(updatedUnit, fieldName, setValidationErrors, schema)
//     } catch (error) {
//       console.error('Field validation error:', error)
//     }
//   }

//   const handleNext = async () => {
//     try {
//       const schema = validationSchemas[activeStep + 1]
//       if (schema) {
//         const isValid = await validateForm(
//           unit,
//           setValidationErrors,
//           schema
//         )
//         if (isValid) {
//           setActiveStep((prevActiveStep) => prevActiveStep + 1)
//         } else {
//           console.error('Form validation failed')
//         }
//       } else {
//         setActiveStep((prevActiveStep) => prevActiveStep + 1)
//       }
//     } catch (error) {
//       console.error('Form submission error:', error)
//     }
//   }

//   const handleSubmit = async () => {
//     setIsLoading(true)
//     try {
//       let schema;
//       if (unit.unitType == 'Vehicle') {
//         schema = validationSchemas[2]
//       }
//       else {
//         schema = validationSchemas[3]
//       }

//       const isValid = await validateForm(unit, setValidationErrors, schema)
//       if (!isValid) {
//         console.error('Form validation failed')
//         setIsLoading(false)
//         return
//       }

//       // const formData = new FormData()
//       // appendFormData(formData, consignment)

//       let response
//       if (id === undefined) {
//         response = await createUnit(unit)
//         handleResponse(response, 'Unit created successfully!')
//       } else {
//         response = await updateUnit(unit)
//         handleResponse(response, 'Unit updated successfully!')
//       }
//     } catch (error) {
//       handleError(error)
//     } finally {
//       setIsLoading(false)
//     }
//   }

//   const handleResponse = (response, successMessage) => {
//     if (response) {
//       setSuccessMessage(successMessage)
//       setTimeout(() => navigate('/management/units'), 3000)
//     }
//   }

//   const handleError = (error) => {
//     window.scrollTo(0, 0);
//     if (error.name === 'ValidationError') {
//       handleValidationErrors(error)
//     } else {
//       setErrors(error.error)
//     }
//   }

//   const handleValidationErrors = (error) => {
//     const errors = error?.inner?.reduce((acc, err) => {
//       acc[err.path] = err.message
//       return acc
//     }, {})
//     setValidationErrors(errors)
//   }

//   const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)
//   const handleReset = () => setActiveStep(0)

//   const getStepContent = (stepIndex) => {
//     switch (stepIndex) {
//       case 0:
//         return (
//           <UnitsForm1New
//             unit={unit}
//             onFormValueChange={handleValueChange}
//             validationErrors={validationErrors}
//           />
//         )
//       case 1:
//         if (unit.unitType === 'Vehicle') {
//           return (
//             <UnitsForm2New
//               unit={unit}
//               onFormValueChange={handleValueChange}
//               validationErrors={validationErrors}
//             />
//           )
//         } else if (unit.unitType === 'E Lock') {
//           return (
//             <UnitsForm3New
//               unit={unit}
//               onFormValueChange={handleValueChange}
//               validationErrors={validationErrors}
//             />
//           )
//         }
//         break
//       case 2:
//         if (unit.unitType === 'Vehicle') {
//           return (
//             <UnitsForm4New
//               unit={unit}
//               onFormValueChange={handleValueChange}
//               validationErrors={validationErrors}
//             />
//           )
//         }
//         break
//       default:
//         return 'Unknown stepIndex'
//     }
//   }

//   const getStepColor = (index) => {
//     if (index < activeStep) {
//       return '#26861D' // Completed step color
//     } else if (index === activeStep) {
//       return '#0772B8' // Active step color
//     } else {
//       return '#EEEEF0' // Inactive step color
//     }
//   }

//   const handleClose = () => {
//     setErrors([])
//   }

//   return (
//     <Box>
//       <ErrorMessage1 errors={errors} onClose={handleClose} />
//       {isLoading && <Loader />}
//       <SuccessMessage message={successMessage} onClose={handleClose} />
//       <Box sx={{ width: '100%', padding: 'Calc(2.3%-50px) Calc(10.8%-50px)' }}>
//         <Grid
//           container
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             borderBottom: '1px solid #d8d8df',
//             paddingBottom: '26.5px'
//           }}
//         >
//           <Grid xs={12} md={5}>
//             <Title item>{id ? 'Update' : 'Create'} Unit</Title>
//           </Grid>

//           <Grid item xs={12} md={7}>
//             <Stepper activeStep={activeStep}>
//               {steps.map((label, index) => (
//                 <Step
//                   key={label}
//                   sx={{
//                     '& .MuiStepIcon-root': {
//                       height: '32px',
//                       width: '32px',
//                       color: getStepColor(index)
//                     },
//                     '& .MuiStepIcon-root.Mui-active': {
//                       color: '#0772B8' // Active step color
//                     },
//                     '& .MuiStepIcon-text': {
//                       fill: '#5B5B6E' // Change this to your desired number color
//                     },
//                     '& .MuiStepIcon-root.Mui-active .MuiStepIcon-text': {
//                       fill: '#FFFFFF'
//                     }
//                   }}
//                 >
//                   <StepLabel>
//                     <Typography
//                       sx={{
//                         font: '16px',
//                         fontWeight: index === activeStep ? 600 : 400,
//                         color: '#0F446B'
//                       }}
//                     >
//                       {label}
//                     </Typography>
//                   </StepLabel>
//                 </Step>
//               ))}
//             </Stepper>
//           </Grid>
//         </Grid>
//         <Box sx={{ marginTop: 3, marginBottom: 3 }}>
//           {activeStep === steps.length ? (
//             <Box>
//               <Typography sx={{ mt: 2 }}>
//                 All steps completed - you&apos;re finished
//               </Typography>
//               <Button onClick={handleReset}>Reset</Button>
//             </Box>
//           ) : (
//             <Box>
//               {getStepContent(activeStep)}
//               <Grid
//                 xs={12}
//                 sx={{
//                   mt: 3,
//                   display: 'flex',
//                   flexDirection: 'row',
//                   justifyContent: 'end'
//                 }}
//               >
//                 <BackButton disabled={activeStep === 0} onClick={handleBack}>
//                   Back
//                 </BackButton>
//                 <NextButton
//                   onClick={
//                     activeStep === steps.length - 1 ? handleSubmit : handleNext
//                   }
//                 >
//                   {activeStep === steps.length - 1
//                     ? 'FINISH'
//                     : 'SAVE & CONTINUE'}
//                 </NextButton>
//               </Grid>
//             </Box>
//           )}
//         </Box>
//       </Box>
//     </Box>
//   )
// }

// export default UnitsFormNew

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  FlexContainerSB,
  StyledCargoRadio,
  StyledFormContainer1,
  StyledFormSection1,
  StyledInputControlContainer,
  StyledPaymentTermContainer,
  StyledSectionHeader,
} from "../../../../styledComponents.js";
import FormOptionSelector from "../../../common/FormOptionSelector.js";
import FormFieldMultiline from "../../../common/FormFieldMultiline.js";
import FormFieldWithEndAdornment from "../../../common/FormFieldWithEndAdornment.js";
import { iconMap } from "../../../../constants/iconsList.js";
import { DoubleArrowIconContainer } from "../../drivers/styles/DriversStyles.js";
import {
  paymentTerms,
  transportationModeType,
  transportationRateFinalize,
} from "../../../../utils/constant.js";
import PropTypes from "prop-types";
import FormCurrency from "../../../common/FormCurrency.js";
// import { getAccountById } from "../../../../services/accountNew.service.js";

const ConsignmentsForm4 = ({
  consignment,
  onFormValueChange,
  validationErrors,
}) => {
  const [selectedTerm, setSelectedTerm] = useState("");
  const [accountDetails, setAccountDetails] = useState();
  const defaultAccountId =
    localStorage.getItem("defaultAccountId") != ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";
  // useEffect(() => {
  //   setSelectedTerm(consignment?.billingInformation?.paymentTerm || "");
  // }, [id]);

  // useEffect(() => {
  //   if (defaultAccountId !== null) {
  //     getAccountId();
  //   }
  // }, []);

  const handleFieldChange = async (name, value) => {
    try {
      let updatedConsignment = { ...consignment };

      if (name.startsWith("billingInformation.")) {
        const field = name.split(".")[1];
        updatedConsignment.billingInformation = {
          ...updatedConsignment.billingInformation,
          [field]: value,
        };
      } else if (name.startsWith("additionalInformation.")) {
        const field = name.split(".")[1];
        updatedConsignment.additionalInformation = {
          ...updatedConsignment.additionalInformation,
          [field]: value,
        };
      } else {
        updatedConsignment[name] = value;
      }

      onFormValueChange(updatedConsignment, name, value, 3); // Pass step index here
    } catch (error) {
      console.error("Error in handleFieldChange:", error);
    }
  };

  // const getAccountId = async () => {
  //   try {
  //     const res = await getAccountById(defaultAccountId);
  //     setAccountDetails(res);

  //     // Update consignment's billing information with the taxRate
  //     if (res?.taxRate !== undefined) {
  //       handleFieldChange("billingInformation.VAT", res.taxRate);
  //     }

  //     // Update consignment's billing information with the currency
  //     if (res?.currency !== undefined) {
  //       handleFieldChange("billingInformation.currency", res.currency);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching account details:", error);
  //     // Handle the error, such as displaying an error message to the user
  //   }
  // };

  return (
    <StyledFormContainer1>
      <StyledFormSection1>
        <StyledSectionHeader>
          <Typography variant="heading">Additional Details</Typography>
        </StyledSectionHeader>
        <Grid>
          <FormOptionSelector
            label="Transportation Mode"
            required
            options={transportationModeType}
            selectedOption={
              consignment?.additionalInformation?.transportationMode
            }
            handleOptionChange={(value) =>
              handleFieldChange(
                "additionalInformation.transportationMode",
                value
              )
            }
            error={
              !!validationErrors["additionalInformation.transportationMode"]
            }
            errorMessage={
              validationErrors["additionalInformation.transportationMode"]
            }
          />
          <FormFieldMultiline
            label="Special Instructions"
            placeholder="Enter Special Instructions"
            value={consignment?.additionalInformation?.specialInstructions}
            onChange={(e) =>
              handleFieldChange(
                "additionalInformation.specialInstructions",
                e.target.value
              )
            }
            error={
              !!validationErrors["additionalInformation.specialInstructions"]
            }
            errorMessage={
              validationErrors["additionalInformation.specialInstructions"]
            }
            multiline
            rows={4}
          />
        </Grid>
      </StyledFormSection1>

      <StyledFormSection1>
        <StyledSectionHeader>
          <Typography variant="heading">Billing Information</Typography>
        </StyledSectionHeader>
        <Grid>
          <FormOptionSelector
            label="Is the Transport Rate Finalized?"
            required
            options={transportationRateFinalize}
            selectedOption={consignment?.billingInformation?.rateFinalized}
            handleOptionChange={(value) =>
              handleFieldChange("billingInformation.rateFinalized", value)
            }
            error={!!validationErrors.billingInformation?.rateFinalized}
            errorMessage={validationErrors.billingInformation?.rateFinalized}
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormCurrency
                label="Cost"
                type="number"
                // required
                placeholder="Enter Cost"
                value={consignment.billingInformation.amount || ""}
                onChange={(field, event) => {
                  const value = event === "" ? null : parseFloat(event);
                  handleFieldChange(field, value);
                }}
                error={!!validationErrors["billingInformation.amount"]}
                errorMessage={validationErrors["billingInformation.amount"]}
                currency={consignment.billingInformation.currency}
              ></FormCurrency>
            </Grid>
            <Grid item xs={6}>
              <FormFieldWithEndAdornment
                label="VAT %"
                placeholder="Enter VAT"
                value={consignment.billingInformation.VAT || ""}
                onChange={(e) => {}}
                type="number"
                disabled
                endAdornmentText="%"
                error={!!validationErrors["billingInformation.VAT"]}
                errorMessage={validationErrors["billingInformation.VAT"]}
              />
            </Grid>
          </Grid>
          <FormFieldMultiline
            label="Additional Notes"
            placeholder="Enter Additional Notes"
            value={consignment.billingInformation.notes || ""}
            onChange={(e) =>
              handleFieldChange("billingInformation.notes", e.target.value)
            }
            multiline
            error={!!validationErrors["billingInformation.notes"]}
            errorMessage={validationErrors["billingInformation.notes"]}
          />
          <StyledInputControlContainer>
            <Typography variant="label">Choose a Payment Term *</Typography>
            {validationErrors["billingInformation.paymentTerm"] && (
              <Box display="flex" alignItems="center" mt={1}>
                <img
                  src={iconMap["ALERTICON"]}
                  alt="alertIcon"
                  style={{ marginRight: "5px", width: "25px", height: "25px" }}
                />
                <Typography sx={{ fontSize: "16px", color: "#e71111" }}>
                  {validationErrors["billingInformation.paymentTerm"]}
                </Typography>
              </Box>
            )}
            <Grid container alignItems="center" spacing={1}>
              {paymentTerms.map((term, index) => (
                <FlexContainerSB item xs={12} sm={3} key={index}>
                  <StyledPaymentTermContainer
                    item
                    xs={12}
                    selected={selectedTerm === term.value}
                    onClick={() =>
                      handleFieldChange(
                        "billingInformation.paymentTerm",
                        term.value
                      )
                    }
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      {term.name}
                    </Typography>
                    <StyledCargoRadio
                      value={term.value}
                      checked={selectedTerm === term.value}
                      onChange={() =>
                        handleFieldChange(
                          "billingInformation.paymentTerm",
                          term.value
                        )
                      }
                    />
                  </StyledPaymentTermContainer>
                  {selectedTerm === "Credit point" &&
                    term.value === "Credit point" && (
                      <>
                        <Grid item xs={4}>
                          <DoubleArrowIconContainer>
                            <img src={iconMap["Arrow"]} alt="arrow" />
                          </DoubleArrowIconContainer>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sx={{
                            marginBottom: "-16px",
                            "& .MuiOutlinedInput-root": {
                              width: "120px",
                            },
                          }}
                        >
                          <FormFieldWithEndAdornment
                            type="number"
                            value={
                              consignment.billingInformation.creditDays || ""
                            }
                            onChange={(event) => {
                              const value =
                                event.target.value === ""
                                  ? null
                                  : parseInt(event.target.value);
                              handleFieldChange(
                                "billingInformation.creditDays",
                                value
                              );
                            }}
                            error={
                              !!validationErrors[
                                "billingInformation.creditDays"
                              ]
                            }
                            errorMessage={
                              validationErrors["billingInformation.creditDays"]
                            }
                            endAdornmentText="days"
                          />
                        </Grid>
                      </>
                    )}
                </FlexContainerSB>
              ))}
            </Grid>
          </StyledInputControlContainer>
        </Grid>
      </StyledFormSection1>
    </StyledFormContainer1>
  );
};

ConsignmentsForm4.propTypes = {
  consignment: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
};

export default ConsignmentsForm4;

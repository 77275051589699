/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ListHeaderNew from "../../../common/ListHeaderNew";
import { Grid } from "@mui/material";
import ViewModeSwitch from "../../../common/ViewModeSwitch";
import ExportButton from "../../../common/ExportButton";
import { fetchSensorsByMultipleCriteria } from "../../../../services/sensor.service";
import SensorsFilters from "../components/SensorsFilter";

export default function SensorsPage() {
  // State hooks for data storage
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sensors, setSensors] = useState([]);
  const [limit] = useState(15);
  const accountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";
  const [filters, setFilters] = useState({
    name: "",
    sensorType: "",
    sensorModel: "",
    accountId: accountId,
  });

  const [isEmpty, setIsEmpty] = useState(false); // Add empty state

  // Location-related logic
  const location = useLocation();
  const shouldShowHeader =
    location.pathname.endsWith("sensors") ||
    location.pathname.endsWith("card") ||
    location.pathname.endsWith("list");

  useEffect(() => {
    _fetchSensorsByMultipleCriteria(
      filters.name,
      filters.sensorType,
      filters.sensorModel,
      accountId,
      page,
      limit
    );
  }, [page, filters]);

  const _fetchSensorsByMultipleCriteria = async (
    name,
    sensorType,
    sensorModel,
    accountId,
    page,
    limit
  ) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      const response = await fetchSensorsByMultipleCriteria(
        name,
        sensorType,
        sensorModel,
        accountId,
        page,
        limit
      );
      setCount(response.count);
      setSensors((prevSensors) => [...prevSensors, ...response.rows]);
      setIsEmpty(response.rows.length === 0); // Set empty state
      console.log("Fetched sensors data:", response); // Log the fetched data
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
    setSensors([]); // Clear previous sensors
    setPage(1); // Reset to the first page
  };

  const fetchMore = () => {
    if (sensors.length < count) {
      setPage((prevPage) => prevPage + 1); // Increment page number for search results
    } else {
      console.log("All sensors have been fetched.");
    }
  };

  return (
    <div className="container">
      {shouldShowHeader && (
        <Grid container flexDirection="column">
          <Grid item xs={12}>
            <ListHeaderNew name="Sensor" mode="Create" />
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{
              marginTop: "30px",
              marginBottom: "30px",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={12}>
              <SensorsFilters onFiltersChange={handleFiltersChange} />{" "}
            </Grid>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <ViewModeSwitch name="Sensor" />
              <ExportButton name="Sensors" accounts={sensors}></ExportButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Outlet context={{ sensors, fetchMore, isLoading, isEmpty }} />
    </div>
  );
}

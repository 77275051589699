export const UsersModel = {
  type: "New User",
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  areaCode: null,
  userName: null,
  password: null,
  confirmPassword: null,
  accessAllAccounts: false,
  sessions: [
    // {
    //   loginTime: null,
    //   expiryTime: null
    // }
  ],
  settings: {
    measurementSystem: "Metric",
    timeFormat: "24 Hrs",
    dateFormat: "dd/mm/yyyy",
    timeZone: "",
  },
  status: "ACTIVE",
  accessRights: [
    // {
    //   accountId: null,
    //   roleId: null,
    //   permissions: [
    //     {
    //       serviceId: null,
    //       serviceName: null,
    //       add: false,
    //       edit: false,
    //       view: false,
    //       delete: false,
    //       serviceValue: false,
    //       type: null,
    //     },
    //   ],
    // },
  ],
  documentsURL: [],
  // documentsURL: [
  //   {
  //     file: null,
  //     fileURL: null,
  //     isDelete: false,
  //     documentType: "Driver's License",
  //     customerView: false,
  //     driverView: false
  //   }
  // ],
  licenseNumber: null,
  licenseExpiryDate: "",
  passportNumber: null,
  passportExpiryDate: "",
  fatiguePlanId: null,
  dailyLogs: [],
  fcmToken: [],
  webFcmToken: [],
  resetPasswordToken: null,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear"; // Import a clear icon
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  StyledInputControlContainer,
  StyledTextField2,
  StyledTimePicker,
} from "../../styledComponents";
import FormError from "./FormError";

const FormTimepicker = ({
  label,
  value,
  onChange,
  error,
  errorMessage,
  required = false,
}) => {
  const [time, setTime] = useState(value ? value : null);

  // Effect to update local state when external value changes
  useEffect(() => {
    setTime(value);
  }, [value]);

  const handleChange = (newValue) => {
    if (newValue) {
      setTime(newValue);
      onChange(newValue); // Pass the new value back to parent
    }
  };

  const handleClear = () => {
    console.log("Clear button clicked"); // Debugging line
    setTime(null); // Clear local state
    onChange(null); // Notify parent about the cleared value
  };

  return (
    <StyledInputControlContainer>
      <Typography variant="label">
        {label}
        {required && " *"}
      </Typography>
      <Box display="flex" alignItems="center">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyledTimePicker
            value={time}
            onChange={handleChange}
            renderInput={(params) => (
              <StyledTextField2
                {...params}
                error={error}
                helperText={errorMessage}
              />
            )}
          />
        </LocalizationProvider>
        {/* Clear Button with visibility based on `time` */}
        {time && (
          <IconButton onClick={handleClear}>
            <ClearIcon />
          </IconButton>
        )}
      </Box>
      {error && <FormError errorMessage={errorMessage} />}
    </StyledInputControlContainer>
  );
};

FormTimepicker.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
};

export default FormTimepicker;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Country } from "country-state-city";
import {
  StyledInputControlContainer,
  StyledSelect2,
  StyledStartAdornmentTextfield2,
} from "../../styledComponents";
import { Grid, InputAdornment, MenuItem, Typography } from "@mui/material";
import PropTypes from "prop-types";
import FormError from "./FormError";

const FormCurrency = ({
  label,
  placeholder,
  value,
  onChange,
  type = "number",
  error,
  errorMessage,
  required = false,
  currency,
}) => {
  const [countries, setCountries] = useState([]);
  const [enhancedCountries, setEnhancedCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});

  useEffect(() => {
    const fetchCountries = async () => {
      const allCountries = Country.getAllCountries();
      const enhancedData = allCountries.map((country) => {
        const countryDetails = Country.getCountryByCode(country.isoCode);
        return {
          ...country,
          ...countryDetails,
        };
      });
      setCountries(allCountries);
      setEnhancedCountries(enhancedData);
      setSelectedCountry(Country.getCountryByCode("AU"));
    };

    fetchCountries();
  }, []);

  // useEffect(() => {
  //   alert(selectedCountry.isoCode)
  //   onChange('billingInformation.currency', selectedCountry.currency);
  // },[]);

  const handleCountryChange = (event) => {
    const country = event.target.value;
    setSelectedCountry(country);
    onChange("billingInformation.currency", country.currency);
  };

  const handleAmountChange = (event) => {
    onChange("billingInformation.amount", event.target.value);
  };

  return (
    <StyledInputControlContainer>
      <Typography variant="label">
        {label}
        {required && " *"}
      </Typography>
      <StyledStartAdornmentTextfield2
        variant="outlined"
        margin="normal"
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={handleAmountChange}
        type={type}
        error={!!error}
        inputProps={{ step: "any" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#f7f7f8",
                  color: "#0F446B",
                  padding: "12px",
                  fontSize: "16px",
                }}
              >
                <Typography variant="startAdornment">
                  {currency} {/* Display currency here */}
                </Typography>
              </Grid>
            </InputAdornment>
          ),
        }}
      />
      {error && <FormError errorMessage={errorMessage} />}
    </StyledInputControlContainer>
  );
};

FormCurrency.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  currency: PropTypes.string,
};

export default FormCurrency;

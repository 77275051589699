import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledModal2 } from '../../styledComponents';
import VehicleTypeForm from '../settings/vehicleType/components/VehicleTypeForm';

const VehicleTypeFormModal = ({ open, handleVehicleTypeFormModalClose }) => {
  // Successfully saved or errored the form
  const handleSuccess = () => {
    handleVehicleTypeFormModalClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleSuccess}
      aria-labelledby="vehicle-type-modal-title"
      aria-describedby="vehicle-type-modal-description"
    >
      <StyledModal2>
      <IconButton
          onClick={handleSuccess}
          style={{ alignSelf: 'flex-end' }}
        >
          <CloseIcon />
        </IconButton>
        <VehicleTypeForm isShortcut={true} onSuccess={handleSuccess} />
      </StyledModal2>
    </Modal>
  );
};

// PropTypes validation
VehicleTypeFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleVehicleTypeFormModalClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default VehicleTypeFormModal;

/* eslint-disable no-unused-vars */
import { Box, Button, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { StyledFormContainer } from "../../../management/billingPlans/styles/BillingPlansStyles.js";
import { useParams } from "react-router-dom";
import { iconMap } from "../../../../constants/iconsList.js";
import {
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
} from "../../../../styledComponents.js";
import FormField from "../../../common/FormField.js";
import FormPhone from "../../../common/FormPhone.js";
import FormPassword from "../../../common/FormPassword.js";
import FormOptionSelector from "../../../common/FormOptionSelector.js";
import { userNameTypes } from "../../../../utils/constant.js";
import { getAccountById } from "../../../../services/accountNew.service.js";

const DriversForm1 = ({
  driver,
  onFormValueChange,
  validationErrors,
  setValidationErrors,
}) => {
  const { id } = useParams();
  const [selectedUserNameSource, setSelectedUserNameSource] = useState("email"); // Default source for userName
  const [profileImage, setProfileImage] = useState(null);
  const profileImageURL = driver.documentsURL.find(
    (doc) => doc.documentType === "Profile Image"
  )?.fileURL;
  const [accountDetails, setAccountDetails] = useState();
  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  useEffect(() => {
    if (defaultAccountId !== null) {
      getAccountId();
    }
  }, []);

  const handleFieldChange = async (name, value) => {
    try {
      let updatedDriver = { ...driver };
      if (name === "phone") {
        const phoneNumberParts = value.split(" ");
        updatedDriver.areaCode = phoneNumberParts[0]; // +255
        updatedDriver.phone = phoneNumberParts.slice(1).join(" "); // "21 233 44"
      } else {
        updatedDriver[name] = value;
      }
      onFormValueChange(updatedDriver, name, value, 1); // Pass step index here
    } catch (error) {
      console.error("Error in handleFieldChange:", error);
    }
  };

  const handleUserNameSourceChange = (value) => {
    setSelectedUserNameSource(value);

    // Set the userName based on the selected source
    if (value === "Phone") {
      // Assuming `user.phone` is the phone number and `user.areaCode` is the area code
      handleFieldChange("userName", `${driver.phone}`);
    } else if (value === "Email") {
      handleFieldChange("userName", driver.email); // Assuming `user.email` exists
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const documentType = "Profile Image"; // Set document type
        const uploadKey = "profile_image"; // Set upload key
        file.fieldname = documentType;
        // Create an updated file object similar to your documents structure
        const updatedFile = {
          fileURL: "",
          file: file,
          isDelete: false,
          documentType,
          driverView: false,
          customerView: false,
        };

        // Update documents URL in the driver object
        const newDocuments = [
          ...driver.documentsURL.filter(
            (doc) => doc.documentType !== documentType
          ), // Remove existing profile images
          updatedFile, // Add new profile image
        ];
        const updatedDriver = {
          ...driver,
          documentsURL: newDocuments,
        };

        // Update local state for profile image preview
        setProfileImage(reader.result);
        // Call form value change handler
        onFormValueChange(updatedDriver, "documentsURL", reader.result, 1);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageDelete = () => {
    onFormValueChange({
      ...driver,
      profileImage: { imageURL: driver.profileImage.imageURL, isDelete: true },
    });
  };

  const getAccountId = async () => {
    try {
      const res = await getAccountById(defaultAccountId);
      setAccountDetails(res);

      // Update consignment's billing information with the countryCode
      if (res?.countryCode !== undefined) {
        // Assuming countryCode is a string like "+255"
        handleFieldChange("areaCode", res.countryCode);
      }
    } catch (error) {
      console.error("Error fetching account details:", error);
      // Handle the error, such as displaying an error message to the user
    }
  };

  return (
    <StyledFormContainer>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Basic Details</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <img
                src={profileImageURL || iconMap["PROFILEICON"]}
                alt="Profile"
                style={{
                  width: "68px",
                  height: "68px",
                  marginRight: "10px",
                  borderRadius: "50%",
                }}
              />
              <input
                id="profile-image-upload"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: "none" }}
              />
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <button
                    onClick={() =>
                      document.getElementById("profile-image-upload").click()
                    }
                    style={{
                      cursor: "pointer",
                      padding: "8px 12px",
                      borderRadius: "8px",
                      border: "1px solid #D8D8DF",
                      textAlign: "center",
                      backgroundColor: "#FFFFFF",
                      color: "#6C6C81",
                      fontSize: "14px",
                      fontWeight: 600,
                      fontStyle: "Archivo",
                      lineHeight: "19px",
                      boxShadow: "0px 1.6px 3.6px 0px rgba(38, 54, 0, 0.11)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={iconMap["UPLODEICON"]}
                      alt="Upload"
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "5px",
                      }}
                    />
                    UPLOAD
                  </button>
                  <Typography
                    sx={{
                      color: "#6C6C81",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    .png or jpg up to 1 MB
                  </Typography>
                </Grid>

                {(profileImage || driver.profileImage?.imageURL) && (
                  <img
                    src={iconMap["DeleteIcon"]}
                    alt="Delete"
                    style={{
                      width: "37px",
                      height: "34px",
                      marginLeft: "10px",
                      cursor: "pointer",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "8px",
                      border: "1px solid #D8D8DF",
                      padding: "5px",
                      boxShadow: "0px 1.6px 3.6px 0px rgba(38, 54, 0, 0.11)",
                    }}
                    onClick={handleImageDelete}
                  />
                )}
              </Grid>
            </Box>
          </Box>
          <Box>
            <FormField
              label="First Name"
              required
              placeholder="Enter first name"
              value={driver.firstName}
              onChange={(e) => handleFieldChange("firstName", e.target.value)}
              error={!!validationErrors.firstName}
              errorMessage={validationErrors.firstName}
            />
            <FormField
              label="Last Name"
              required
              placeholder="Enter last name"
              value={driver.lastName}
              onChange={(e) => handleFieldChange("lastName", e.target.value)}
              error={!!validationErrors.lastName}
              errorMessage={validationErrors.lastName}
            />
            <FormField
              label="Email"
              placeholder="Enter email"
              value={driver.email}
              onChange={(e) => handleFieldChange("email", e.target.value)}
              error={!!validationErrors.email}
              errorMessage={validationErrors.email}
            />
            <FormPhone
              label="Phone"
              value={driver.areaCode + " " + driver.phone}
              onChange={(newValue) => handleFieldChange("phone", newValue)}
              error={!!validationErrors.phone}
              errorMessage={validationErrors.phone}
              required
            />
          </Box>
        </StyledSectionRight>
      </StyledFormSection>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Credentials</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormOptionSelector
            label="Username"
            options={userNameTypes}
            selectedOption={selectedUserNameSource}
            value={selectedUserNameSource}
            handleOptionChange={(value) => handleUserNameSourceChange(value)}
          />
          <FormField
            label="Username"
            required
            disabled="true"
            placeholder="Enter username"
            value={driver.userName}
            onChange={(e) => handleFieldChange("userName", e.target.value)}
            error={!!validationErrors.userName}
            errorMessage={validationErrors.userName}
          />
          {id === undefined && (
            <>
              <FormPassword
                label="Password"
                value={driver.password}
                onChange={(e) => handleFieldChange("password", e.target.value)}
                error={!!validationErrors.password}
                errorMessage={validationErrors.password}
                required
              />
              <FormPassword
                label="Confirm Password"
                value={driver.confirmPassword}
                onChange={(e) =>
                  handleFieldChange("confirmPassword", e.target.value)
                }
                error={!!validationErrors.confirmPassword}
                errorMessage={validationErrors.confirmPassword}
                required
              />
            </>
          )}
        </StyledSectionRight>
      </StyledFormSection>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Company Pin</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormField
            label="Company PIN"
            required
            disabled="true"
            placeholder="Enter company PIN"
            value={driver.companyPin}
            onChange={(e) => handleFieldChange("companyPin", e.target.value)}
            error={!!validationErrors.companyPin}
            errorMessage={validationErrors.companyPin}
          />
        </StyledSectionRight>
      </StyledFormSection>
    </StyledFormContainer>
  );
};

DriversForm1.propTypes = {
  driver: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  setValidationErrors: PropTypes.func.isRequired,
};

export default DriversForm1;

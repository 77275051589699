import React, { useEffect, useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import {
  StyledHeader2,
  Title,
  StyledFormContainer,
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
  NextButton,
} from "../../../../styledComponents";
import FormField from "../../../common/FormField";
// import { validateForm } from "../../../../utils/yupValidations";
// import { sensorSchema } from "../../../../utils/validation-schemas/sensorSchema";
import {
  createSensor,
  getSensorById,
  updateSensor,
} from "../../../../services/sensor.service";
import Loader from "../../../common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import ErrorMessage1 from "../../../common/ErrorMessage1";
import SuccessMessage from "../../../common/SuccessMessage";
import { SensorsModel } from "../../../../model/sensors.model";
import { getRoute } from "../../../../constants/routes";
import { iconMap } from "../../../../constants/iconsList";
import FormSelect from "../../../common/FormSelect";
import { sensorType } from "../../../../utils/constant";
import PropTypes from "prop-types";
import { validateForm, validateForm2 } from "../../../../utils/yupValidations";
import { sensorsSchema } from "../../../../utils/validation-schemas/sensorsSchema";

const SensorForm = ({ isShortcut, onSuccess }) => {
  const { id } = useParams();
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [sensor, setSensor] = useState(SensorsModel);
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch sensor by ID for edit mode
  useEffect(() => {
    if (id) {
      _getSensorById(id);
    }
  }, [id]);

  const _getSensorById = async (sensorId) => {
    setIsLoading(true);
    try {
      const data = await getSensorById(sensorId);
      console.log("Fetched Sensor Data:", data); // Debugging
      setSensor(data);
      handleResponse(data, "Sensor fetched successfully!", "Get");
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFieldChange = async (name, value) => {
    try {
      setSensor((prevSensor) => ({
        ...prevSensor,
        [name]: value,
      }));
      await validateForm2(
        { ...sensor, [name]: value },
        name,
        setValidationErrors,
        sensorsSchema
      );
    } catch (error) {
      console.error("Error updating field:", error);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Validate the form fields
      const isValid = await validateForm(
        sensor,
        setValidationErrors,
        sensorsSchema
      );
      if (!isValid) {
        console.error("Form validation failed");
        setIsLoading(false);
        return;
      }

      let response;
      if (id === undefined) {
        response = await createSensor(sensor);
        if (isShortcut == true) {
          handleResponse(response, "Sensor created successfully!", "");
        } else {
          handleResponse(response, "Sensor created successfully!", "Card");
        }
      } else {
        response = await updateSensor(sensor);
        if (isShortcut == true) {
          handleResponse(response, "Sensor created successfully!", "");
        } else {
          handleResponse(response, "Sensor updated successfully!", "Card");
        }
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
      setTimeout(() => {
        if (mode === "Card") {
          navigate(getRoute("Sensor", mode));
        } else if (mode === "") {
          onSuccess("Success");
        }
      }, 3000); // Closing parentheses and braces for setTimeout
    }
  };

  const handleError = (error) => {
    window.scrollTo(0, 0);
    if (error.name === "ValidationError") {
      handleValidationErrors(error);
    } else {
      setErrors(error.error);
    }
  };
  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message;
      return acc;
    }, {});
    setValidationErrors(errors);
  };

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <StyledFormContainer>
      <ErrorMessage1 errors={errors} onClose={handleClose} />
      <SuccessMessage message={successMessage} onClose={handleClose} />
      {isLoading && <Loader />}

      <StyledHeader2>
        <Grid item xs={12}>
          <Title>
            <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
              <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
            </IconButton>
            {id ? "Update" : "Create"} Sensor
          </Title>
        </Grid>
      </StyledHeader2>

      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">Sensor Details</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormField
            label="Name"
            required
            placeholder="Enter Sensor Name"
            value={sensor.name}
            onChange={(e) => handleFieldChange("name", e.target.value)}
            error={!!validationErrors.name}
            errorMessage={validationErrors.name}
          />
          <FormSelect
            label="Sensor Type"
            value={sensor.sensorType}
            onChange={(e) => handleFieldChange("sensorType", e)}
            options={sensorType.map((item) => ({
              value: item.value,
              label: `${item.name}`,
            }))}
            error={!!validationErrors.sensorType}
            errorMessage={validationErrors.sensorType}
          />
          <FormField
            label="Sensor Model"
            placeholder="Enter Sensor Model"
            value={sensor.sensorModel}
            onChange={(e) => handleFieldChange("sensorModel", e.target.value)}
            error={!!validationErrors.sensorModel}
            errorMessage={validationErrors.sensorModel}
          />
          <FormField
            label="Installed Location"
            placeholder="Enter Installed Location"
            value={sensor.installedLocation}
            onChange={(e) =>
              handleFieldChange("installedLocation", e.target.value)
            }
            error={!!validationErrors.installedLocation}
            errorMessage={validationErrors.installedLocation}
          />
        </StyledSectionRight>
      </StyledFormSection>
      <Grid
        sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
      >
        <Grid sx={{ mt: 2 }}>
          <NextButton onClick={handleSubmit}>SAVE</NextButton>
        </Grid>
      </Grid>
    </StyledFormContainer>
  );
};
SensorForm.propTypes = {
  isShortcut: PropTypes.bool,
  onSuccess: PropTypes.func,
};

export default SensorForm;

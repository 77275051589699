import React from "react";
import {
  StyledCountryMuiTelInput,
  StyledInputControlContainer,
} from "../../styledComponents";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import FormError from "./FormError";

const FormCountrySelect = ({
  label,
  value,
  onChange,
  error,
  errorMessage,
  required = false,
  readOnly = false,
}) => {
  return (
    <StyledInputControlContainer>
      <Typography variant="label">
        {label}
        {required && " *"}
      </Typography>
      <StyledCountryMuiTelInput
        defaultCountry="TZ"
        value={value}
        onChange={onChange}
        error={error}
        inputProps={{ readOnly: readOnly }}
      />
      {error && <FormError errorMessage={errorMessage} />}
    </StyledInputControlContainer>
  );
};

FormCountrySelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default FormCountrySelect;

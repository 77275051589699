/* eslint-disable no-unused-vars */
// React core imports
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

// Material-UI imports
import { Grid, Menu, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

// Styled components and utility functions
import {
  StyledListCard,
  StyledListCardHeader,
  StyledIconButton,
  FlexContainerSB,
  StyledListCardContent,
  StyledMenuItem,
  StyledListCardContainer,
} from "../../../../styledComponents";
import { formatDate } from "../../../../utils/functions"; // Include any other functions you need

// Modal components
import DeleteModal from "../../../modals/DeleteModal";

// Service and common components
// import { deleteSensor } from "../../../../services/sensor.service"; // Adjust based on your service structure
import Loader from "../../../common/Loader";
import EmptyState from "../../../common/EmptyState";
import debounce from "lodash.debounce";
import SuccessMessage from "../../../common/SuccessMessage";
import ErrorMessage1 from "../../../common/ErrorMessage1";

const SensorsListCard = () => {
  const [errors, setErrors] = useState([]);
  const [openMenuSensorId, setOpenMenuSensorId] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [shouldReload, setShouldReload] = useState(false);

  const navigate = useNavigate();
  const { sensors, fetchMore, isLoading, isEmpty } = useOutletContext();

  const containerRef = useRef(null);

  const handleScroll = useCallback(
    debounce(() => {
      const container = containerRef.current;
      if (
        container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 &&
        !isLoading
      ) {
        fetchMore();
      }
    }, 300),
    [fetchMore, isLoading]
  );

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
    }
  }, [shouldReload]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [sensors, isLoading, handleScroll]);



  const handleDeleteModalClose = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setSelectedItem, setOpen]);

  // Function that calls the API to perform deleteAccount
  const deleteItem = async () => {
    setOpen(false);
    setSelectedItem(null);
    // try {
    //   const response = await deleteUnit(selectedItem.id);
    //   if (response) {
    //     handleResponse(response, 'Unit deleted successfully.')
    //     handleError([])
    //     // Set the state to reload the page after successful update
    //     setShouldReload(true);
    //   }
    // } catch (error) {
    //   handleError(error)
    // } finally {
    //   setOpen(false);
    //   setSelectedItem(null);
    // }
  };

   // Function that calls the API to perform deleteSensor
    const deleteSensor = useCallback(() => {
      setOpen(false);
      setSelectedItem(null);
    }, [setOpen, setSelectedItem]);

  //   const deleteSensorHandler = async () => {
  //     if (selectedSensor) {
  //       try {
  //         await deleteSensor(selectedSensor.id);
  //         setSuccessMessage("Sensor deleted successfully.");
  //         setErrors([]);
  //         setShouldReload(true);
  //       } catch (error) {
  //         setErrors([error.message || "An unexpected error occurred"]);
  //       } finally {
  //         handleDeleteModalClose();
  //       }
  //     }
  //   };

  const handleActionsMenuOpen = useCallback((event, selectedItem) => {
    setOpenMenuSensorId(selectedItem.id);
    setSelectedItem(selectedItem);
  }, []);

  const handleActionsMenuClose = useCallback(() => {
    setOpenMenuSensorId(null);
  }, []);
  const handleDeleteModalOpen = useCallback((event, sensor) => {
    handleActionsMenuClose();
    setSelectedItem(sensor);
    setOpen(true);
  }, [handleActionsMenuClose, setSelectedItem, setOpen]);

  const navigateToEditScreen = useCallback(
    (sensorId) => {
      navigate(`/management/sensors/edit/${sensorId}`);
    },
    [navigate]
  );

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };

  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0)
    if (response) {
      setSuccessMessage(successMessage)
    }
  }
  const handleError = (error) => {
    window.scrollTo(0, 0)
    // if (error.name === 'ValidationError') {
    //   handleValidationErrors(error)
    // } else {
    setErrors(error.error)
    // }
  }

  return (
    <>
      <Grid sx={{ marginTop: "50px" }}>
        <SuccessMessage message={successMessage} onClose={handleClose} />
        <ErrorMessage1 errors={errors} onClose={handleClose} />
      </Grid>
      <StyledListCardContainer container ref={containerRef} spacing={2}>
        {isLoading && <Loader />}
        {isEmpty && !isLoading && (
          <Grid item xs={12}>
            <EmptyState
              name="SENSORS"
              message="Oops! You have no sensors for your search criteria"
              info="Please feel free to create a new sensor by clicking on the button above"
            />
          </Grid>
        )}
        {sensors?.map((sensor) => (
          <Grid item xs={12} sm={6} md={4} key={sensor.id}>
            <StyledListCard>
              <StyledListCardHeader
                title={
                  <Grid container sx={{ justifyContent: "space-between" }}>
                    <Typography variant="cardTitle1">
                      {sensor.sensorType}
                    </Typography>
                  </Grid>
                }
                action={
                  <Grid>
                    <StyledIconButton
                      id={`menu-button-${sensor.id}`}
                      onClick={(event) => handleActionsMenuOpen(event, sensor)}
                    >
                      <MoreVertIcon />
                    </StyledIconButton>
                    <Menu
                      anchorEl={document.getElementById(
                        `menu-button-${sensor.id}`
                      )}
                      open={openMenuSensorId === sensor.id} // The menu is open if the openMenuSimCardId
                      onClose={(event) => handleActionsMenuClose(event)}
                    >
                      <StyledMenuItem
                        onClick={(e) => handleDeleteModalOpen(e, sensor)}
                      >
                        <Grid sx={{ display: "flex", alignItems: "block" }}>
                          <DeleteOutlineIcon
                            sx={{ marginRight: "10px", color: "#0772B8" }}
                          />
                          <Typography> Delete Sensor</Typography>
                        </Grid>
                      </StyledMenuItem>
                    </Menu>
                  </Grid>
                }
              />
              <StyledListCardContent
                onClick={() => navigateToEditScreen(sensor.id)}
              >
                <Grid>
                <FlexContainerSB item container>
                    <Typography variant="cardLabel">Name</Typography>
                    <Typography variant="cardValue">
                      {sensor?.name ? sensor?.name : "--"}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">Sensor Model</Typography>
                    <Typography variant="cardValue">
                      {sensor?.sensorModel ? sensor?.sensorModel : "--"}
                    </Typography>
                  </FlexContainerSB>
                  <FlexContainerSB item container>
                    <Typography variant="cardLabel">
                      Installed Location
                    </Typography>
                    <Typography variant="cardValue">
                      {sensor?.installedLocation
                        ? sensor?.installedLocation
                        : "--"}
                    </Typography>
                  </FlexContainerSB>
                </Grid>
              </StyledListCardContent>
            </StyledListCard>
          </Grid>
        ))}
      </StyledListCardContainer>

      <DeleteModal
        open={open}
        handleDeleteModalClose={handleDeleteModalClose}
        selectedItem={selectedItem}
        deleteItem={deleteItem}
      />
    </>
  );
};

export default SensorsListCard;

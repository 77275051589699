import React from 'react';
import PropTypes from 'prop-types';
import { iconMap } from '../../constants/iconsList';
import { Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledErrorContainer, StyledErrorItem, StyledErrorList } from '../../styledComponents';
export default function ErrorMessage1({ errors, onClose }) {
  if (errors?.length === 0 || errors == undefined) {
    return null;
  }

  return (
    <Grid>
      <StyledErrorContainer>
        <Grid container>
          <Grid item xs={10} sx={{ display: 'flex' }}>
            <img style={{ marginRight: '8px' }} src={iconMap['ALERTICON']} alt="alertIcon" />
            <StyledErrorList>
              {errors.map((error, index) => (
                <StyledErrorItem key={index}>{error.message}</StyledErrorItem>
              ))}
            </StyledErrorList>
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'right' }}>
            <IconButton size="small" style={{ color: '#e71111' }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </StyledErrorContainer>
    </Grid>
  );
}
ErrorMessage1.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
    message: PropTypes.string.isRequired,
    })
    ).isRequired,
  // errors: PropTypes.arrayOf(
  // ).isRequired,
  onClose: PropTypes.func.isRequired,
};
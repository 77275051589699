import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { StyledChip, StyledInputControlContainer } from '../../styledComponents';
import FormError from './FormError';

const TimeRangePicker = ({ 
  label, 
  value, 
  onChange, 
  times, 
  error, 
  errorMessage, 
  required = false 
}) => {
  const handleSelectTime = (selectedTime) => {
    onChange(selectedTime);
  };

  return (
    <StyledInputControlContainer>
      <Typography variant="label">
        {label}
        {required && ' *'}
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
        {times.map((time, index) => (
          <StyledChip
            key={index}
            label={`${time.startTime} - ${time.endTime}`}
            onClick={() => handleSelectTime(time)}
            selected={value && value.startTime === time.startTime && value.endTime === time.endTime}
            clickable
          />
        ))}
      </Box>
      {error && <FormError errorMessage={errorMessage} />}
    </StyledInputControlContainer>
  );
};

TimeRangePicker.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  times: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.string.isRequired,
      endTime: PropTypes.string.isRequired,
    })
  ).isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
};

export default TimeRangePicker;

/* eslint-disable no-unused-vars */
// /* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  StyledFormContainer1,
  StyledFormSection1,
  StyledSectionHeader,
} from "../../../../styledComponents";
import { Grid, Typography } from "@mui/material";
import FormSelect from "../../../common/FormSelect";
import { fetchCustomersByMultipleCriteria } from "../../../../services/customerNew.service";
import FormField from "../../../common/FormField";
import FormPhone from "../../../common/FormPhone";
import FormAddress2 from "../../../common/FormAddress2";
import FormAddress3 from "../../../common/FormAddress3";
import ConsigneesFormModal from "../../../modals/ConsigneesFormModal";
// import { getAccountById } from "../../../../services/accountNew.service";
// import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import {  useParams } from "react-router-dom";
const ConsignmentsForm1 = ({
  consignment,
  onFormValueChange,
  validationErrors,
  accountDetails,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [customers, setCustomers] = useState([]);
  // const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [consigneesFormModal, setConsigneesFormModal] = useState(false);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  // const [account, setAccount] = useState(null);
  // const defaultAccountId = localStorage.getItem("defaultAccountId")
  //   ? JSON.parse(localStorage.getItem("defaultAccountId"))
  //   : "";
    const { id } = useParams();
    const selectedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));


    useEffect(() => {
      _fetchCustomersByMultipleCriteria();
    }, [id]);

  // useEffect(() => {
  //   const savedCustomer = localStorage.getItem("selectedCustomer");
  //   _fetchCustomersByMultipleCriteria();
  //   if (savedCustomer) {
  //     setSelectedCustomer(JSON.parse(savedCustomer));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (defaultAccountId) {
  //     getAccountId();
  //   }
  // }, [defaultAccountId]);

  // eslint-disable-next-line no-unused-vars
  const handleConsigneesFormModalOpen = useCallback(() => {
    setConsigneesFormModal(true);
  }, []);

  const handleConsigneesFormModalClose = useCallback(() => {
    setConsigneesFormModal(false);
    _fetchCustomersByMultipleCriteria();
  }, []);

  const _fetchCustomersByMultipleCriteria = async (id = "", page = 1, limit = 15) => {
    if (isLoadingCustomers) return;
    setIsLoadingCustomers(true);
    try {
      let allCustomers = [];
      let currentPage = page;
      let totalFetched = 0;
      let response;
      do {
        response = await fetchCustomersByMultipleCriteria(id, currentPage, limit);
        allCustomers = [...allCustomers, ...response.rows];
        totalFetched += response.rows.length;
        currentPage++;
      } while (totalFetched < response.count);

      setCustomers(allCustomers);
    } catch (error) {
      console.error("Error fetching customers:", error?.message || "An unknown error occurred");
    } finally {
      setIsLoadingCustomers(false);
    }
  };

  const splitPhoneNumber = (value) => {
    const phoneNumberParts = value.split(" ");
    return {
      areaCode: phoneNumberParts[0],
      phone: phoneNumberParts.slice(1).join(" "),
    };
  };


  // eslint-disable-next-line no-unused-vars
  const handleFieldChange = async (name, value) => {
    try {
      let updatedConsignment = { ...consignment };

      // Handle pickup details
      if (name.startsWith("pickupDetails.")) {
        if (name === "pickupDetails.areaCode") {
          updatedConsignment.pickupDetails.areaCode = value;
        } else if (name === "pickupDetails.name") {
          updatedConsignment.pickupDetails.name = value;
        } else if (name === "pickupDetails.phone") {
          const { phone, areaCode } = splitPhoneNumber(value);
          updatedConsignment.pickupDetails.phone = phone;
          updatedConsignment.pickupDetails.areaCode = areaCode;
        } else if (name.startsWith("pickupDetails.address.")) {
          const addressField = name.split("pickupDetails.address.")[1];
          updatedConsignment.pickupDetails.address[addressField] = value;
          if (updatedConsignment.pickupDetails.address.addressType === "Address") {
            if (updatedConsignment.pickupDetails.address.geofenceId != null) {
              updatedConsignment.pickupDetails.address.geofenceId = null;
            }
          }
          if (updatedConsignment.pickupDetails.address.addressType === "Geofence") {
            ["streetOne", "streetTwo", "city", "state", "country", "postalCode"].forEach((field) => {
              if (updatedConsignment.pickupDetails.address[field]) {
                updatedConsignment.pickupDetails.address[field] = null;
              }
            });
          }
        }
      }

      // Handle delivery details
      else if (name.startsWith("deliveryDetails.")) {
        if (name === "deliveryDetails.areaCode") {
          updatedConsignment.deliveryDetails.areaCode = value;
        }else if (name === "deliveryDetails.name") {
          updatedConsignment.deliveryDetails.name = value;
        } 
        else if (name === "deliveryDetails.phone") {
          const { phone, areaCode } = splitPhoneNumber(value);
          updatedConsignment.deliveryDetails.phone = phone;
          updatedConsignment.deliveryDetails.areaCode = areaCode;
        } else if (name.startsWith("deliveryDetails.address.")) {
          const addressField = name.split("deliveryDetails.address.")[1];
          updatedConsignment.deliveryDetails.address[addressField] = value;
          if (updatedConsignment.deliveryDetails.address.addressType === "Address") {
            if (updatedConsignment.deliveryDetails.address.geofenceId != null) {
              updatedConsignment.deliveryDetails.address.geofenceId = null;
            }
          }
          if (updatedConsignment.deliveryDetails.address.addressType === "Geofence") {
            ["streetOne", "streetTwo", "city", "state", "country", "postalCode"].forEach((field) => {
              if (updatedConsignment.deliveryDetails.address[field]) {
                updatedConsignment.deliveryDetails.address[field] = null;
              }
            });
          }
        }
      }
      else if(name == 'customerId'){
        updatedConsignment[name] = value.id;
      }

        else {
          updatedConsignment[name] = value;
        }

      onFormValueChange(updatedConsignment, name, value, 0);
    } catch (error) {
      console.error("Error in handleFieldChange:", error);
    }
  };

  // const getAccountId = async () => {
  //   try {
  //     if (!defaultAccountId) {
  //       console.warn("defaultAccountId is not set");
  //       return;
  //     }
  //     const res = await getAccountById(defaultAccountId);
  //     if (res) {
  //       // setAccount(res);

  //       if (res.countryCode) {
  //         handleFieldChange("pickupDetails.areaCode", res.countryCode);
  //         handleFieldChange("deliveryDetails.areaCode", res.countryCode);
  //       } else {
  //         console.warn("Country code is undefined or null");
  //       }
  //     } else {
  //       console.warn("No account details found for the given defaultAccountId");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching account details:", error);
  //   }
  // };

  return (
    <StyledFormContainer1>
      <StyledFormSection1>
        {!accountDetails?.autoAssignConsignmentNumber && (
          <Grid item xs={6}>
            <FormField
              label="Consignment Number"
              required
              placeholder="Enter Consignment Number"
              value={consignment.orderNumber}
              onChange={(e) => handleFieldChange("orderNumber", e.target.value)}
              error={!!validationErrors["orderNumber"]}
              errorMessage={validationErrors["orderNumber"]}
            />
          </Grid>
        )}
        <Grid>
          <FormSelect
            label="Consignee Name"
            label2="Add Consignee"
            onLabel2Click={handleConsigneesFormModalOpen}
            // disabled={consignment?.id !== undefined}
            value={consignment?.customerId}
            required
            onChange={(selected) => {
              handleFieldChange("customerId", selected);
            }}
            options={customers
              .filter((item) => item.id)
              .map((item) => ({
                value: item.id,
                label: item.name,
                item,
              }))}
            error={!!validationErrors.customerId}
            errorMessage={validationErrors.customerId}
          />
        </Grid>
        <StyledSectionHeader>
          <Typography variant="heading">Pickup Address</Typography>
        </StyledSectionHeader>
        <Grid>
          {((consignment?.id !== undefined && consignment?.accountId) || consignment?.id == undefined) && (
            <FormAddress2
              address={consignment?.pickupDetails?.address}
              addressKey="-1"
              handleFieldChange={(name, value) =>
                handleFieldChange("pickupDetails.address." + name, value)
              }
              validationErrors={validationErrors}
            />
          )}
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormField
                label="Pickup Person Name"
                required
                placeholder="Enter Pickup Person Name"
                value={consignment?.pickupDetails?.name}
                onChange={(e) =>
                  handleFieldChange("pickupDetails.name", e.target.value)
                }
                error={!!validationErrors["pickupDetails.name"]}
                errorMessage={validationErrors["pickupDetails.name"]}
              />
            </Grid>
            <Grid item xs={6}>
              <FormPhone
                label="Phone"
                value={
                  consignment.pickupDetails.areaCode +
                  " " +
                  consignment.pickupDetails.phone
                }
                onChange={(newValue) =>
                  handleFieldChange("pickupDetails.phone", newValue)
                }
                required
                error={!!validationErrors["pickupDetails.phone"]}
                errorMessage={validationErrors["pickupDetails.phone"]}
              />
            </Grid>
          </Grid>
        </Grid>
      </StyledFormSection1>
      <Grid>
        <StyledFormSection1>
          <StyledSectionHeader>
            <Typography variant="heading">Delivery Address</Typography>
          </StyledSectionHeader>
          <Grid>
            <FormSelect
              label="Delivery Address"
              value={consignment?.deliveryDetails?.addressId}
              onChange={(selected) => {
                handleFieldChange("deliveryDetails.addressId", selected);
              }}
              options={
                selectedCustomer
                  ? selectedCustomer?.address?.map((item) => ({
                    value: item._id,
                    label:
                      item.geofenceId ||
                      `${item.streetOne}, ${item.streetTwo}, ${item.city}, ${item.state}, ${item.country}, ${item.postalCode}`,
                    item,
                  }))
                  : []
              }
              error={!!validationErrors["deliveryDetails.addressId"]}
              errorMessage={validationErrors["deliveryDetails.addressId"]}
            />
            {((consignment?.id !== undefined && consignment?.accountId) ||
              consignment?.id === undefined) && (
                <FormAddress3
                  address={consignment?.deliveryDetails?.address}
                  addressKey="-1"
                  handleFieldChange={(name, value) =>
                    handleFieldChange("deliveryDetails.address." + name, value)
                  }
                  validationErrors={validationErrors}
                />
              )}
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormField
                  label="Delivery Person Name"
                  required
                  placeholder="Enter Delivery Person Name"
                  value={consignment?.deliveryDetails?.name}
                  onChange={(e) =>
                    handleFieldChange("deliveryDetails.name", e.target.value)
                  }
                  error={!!validationErrors["deliveryDetails.name"]}
                  errorMessage={validationErrors["deliveryDetails.name"]}
                />
              </Grid>
              <Grid item xs={6}>
                <FormPhone
                  label="Phone"
                  value={
                    consignment?.deliveryDetails?.areaCode +
                    " " +
                    consignment?.deliveryDetails?.phone
                  }
                  onChange={(newValue) =>
                    handleFieldChange("deliveryDetails.phone", newValue)
                  }
                  required
                  error={!!validationErrors["deliveryDetails.phone"]}
                  errorMessage={validationErrors["deliveryDetails.phone"]}
                />
              </Grid>
            </Grid>
          </Grid>
        </StyledFormSection1>
      </Grid>

      <ConsigneesFormModal
        open={consigneesFormModal}
        handleConsigneesFormModalClose={handleConsigneesFormModalClose}
      />
    </StyledFormContainer1>
  );
};

ConsignmentsForm1.propTypes = {
  consignment: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
  accountDetails: PropTypes.object.isRequired,
};

export default ConsignmentsForm1;
// const ConsignmentsForm1 = ({
//   consignment,
//   onFormValueChange,
//   validationErrors,
//   accountDetails,
// }) => {
//   const [customers, setCustomers] = useState([ConsigneeModel]);
//   const [selectedCustomer, setSelectedCustomer] = useState(ConsigneeModel);
//   const [consigneesFormModal, setConsigneesFormModal] = useState(false);
//   const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
//   const [account, setAccount] = useState();
//   const defaultAccountId =
//     localStorage.getItem("defaultAccountId") !== ""
//       ? JSON.parse(localStorage.getItem("defaultAccountId"))
//       : "";

//   useEffect(() => {
//     // Retrieve the state from local storage when the component mounts
//     const savedCustomer = localStorage.getItem("selectedCustomer");
//     _fetchCustomersByMultipleCriteria();
//     if (savedCustomer) {
//       setSelectedCustomer(JSON.parse(savedCustomer));
//     }
//   }, []);

//   useEffect(() => {
//     if (defaultAccountId !== null) {
//       getAccountId();
//     }
//   }, []);

//   const handleConsigneesFormModalOpen = useCallback(() => {
//     setConsigneesFormModal(true);
//   }, []);

//   const handleConsigneesFormModalClose = useCallback(() => {
//     setConsigneesFormModal(false);
//     _fetchCustomersByMultipleCriteria();
//   }, []);

//   const _fetchCustomersByMultipleCriteria = async (
//     id = "",
//     page = 1,
//     limit = 15
//   ) => {
//     if (isLoadingCustomers) return;
//     setIsLoadingCustomers(true);
//     try {
//       let allCustomers = [];
//       let currentPage = page;
//       let totalFetched = 0;
//       let response;
//       do {
//         response = await fetchCustomersByMultipleCriteria(
//           id,
//           currentPage,
//           limit
//         );
//         allCustomers = [...allCustomers, ...response.rows];
//         totalFetched += response.rows.length;
//         currentPage++;
//       } while (totalFetched < response.count);

//       setCustomers(allCustomers);
//     } catch (error) {
//       console.error(
//         "Error fetching customers:",
//         error?.message || "An unknown error occurred"
//       );
//     } finally {
//       setIsLoadingCustomers(false);
//     }
//   };

//   const splitPhoneNumber = (value) => {
//     const phoneNumberParts = value.split(" ");
//     return {
//       areaCode: phoneNumberParts[0],
//       phone: phoneNumberParts.slice(1).join(" "),
//     };
//   };
//   const handleFieldChange = async (name, value) => {
//     try {
//       let updatedConsignment = { ...consignment };

//       // Handle pickup details
//       if (name.startsWith("pickupDetails.")) {
//         if (name === "pickupDetails.areaCode") {
//           updatedConsignment.pickupDetails.areaCode = value; // Update area code directly
//         } else if (name === "pickupDetails.phone") {
//           const { phone, areaCode } = splitPhoneNumber(value);
//           updatedConsignment.pickupDetails.phone = phone;
//           updatedConsignment.pickupDetails.areaCode = areaCode; // Update area code as well
//         } else if (name.startsWith("pickupDetails.address.")) {
//           const addressField = name.split("pickupDetails.address.")[1];
//           updatedConsignment.pickupDetails.address[addressField] = value;
//           if (
//             updatedConsignment.pickupDetails.address.addressType === "Address"
//           ) {
//             if (updatedConsignment.pickupDetails.address.geofenceId != null) {
//               updatedConsignment.pickupDetails.address.geofenceId = null; // Reset geofenceId
//             }
//           }
//           if (
//             updatedConsignment.pickupDetails.address.addressType === "Geofence"
//           ) {
//             [
//               "streetOne",
//               "streetTwo",
//               "city",
//               "state",
//               "country",
//               "postalCode",
//             ].forEach((field) => {
//               if (updatedConsignment.pickupDetails.address[field]) {
//                 updatedConsignment.pickupDetails.address[field] = null; // Reset fields
//               }
//             });
//           }
//         } else {
//           // Handle other pickup detail fields...
//         }
//       }

//       // Handle delivery details
//       if (name.startsWith("deliveryDetails.")) {
//         if (name === "deliveryDetails.areaCode") {
//           updatedConsignment.deliveryDetails.areaCode = value; // Update area code directly
//         } else if (name === "deliveryDetails.phone") {
//           const { phone, areaCode } = splitPhoneNumber(value);
//           updatedConsignment.deliveryDetails.phone = phone;
//           updatedConsignment.deliveryDetails.areaCode = areaCode; // Update area code as well
//         } else if (name.startsWith("deliveryDetails.address.")) {
//           const addressField = name.split("deliveryDetails.address.")[1];
//           updatedConsignment.deliveryDetails.address[addressField] = value;
//           if (
//             updatedConsignment.deliveryDetails.address.addressType === "Address"
//           ) {
//             if (updatedConsignment.deliveryDetails.address.geofenceId != null) {
//               updatedConsignment.deliveryDetails.address.geofenceId = null; // Reset geofenceId
//             }
//           }
//           if (
//             updatedConsignment.deliveryDetails.address.addressType ===
//             "Geofence"
//           ) {
//             [
//               "streetOne",
//               "streetTwo",
//               "city",
//               "state",
//               "country",
//               "postalCode",
//             ].forEach((field) => {
//               if (updatedConsignment.deliveryDetails.address[field]) {
//                 updatedConsignment.deliveryDetails.address[field] = null; // Reset fields
//               }
//             });
//           }
//         } else {
//           // Handle other delivery detail fields...
//         }
//       }

//       // Call onFormValueChange to update state
//       onFormValueChange(updatedConsignment, name, value, 0); // Pass step index here
//     } catch (error) {
//       console.error("Error in handleFieldChange:", error);
//     }
//   };

//   const getAccountId = async () => {
//     try {
//       if (!defaultAccountId) {
//         console.warn("defaultAccountId is not set");
//         return;
//       }

//       const res = await getAccountById(defaultAccountId);
//       console.log("Response from getAccountById:", res);

//       if (res) {
//         setAccount(res);

//         // Update consignment's billing information with the countryCode
//         if (res.countryCode) {
//           handleFieldChange("pickupDetails.areaCode", res.countryCode);
//           handleFieldChange("deliveryDetails.areaCode", res.countryCode);
//         } else {
//           console.warn("Country code is undefined or null");
//         }
//       } else {
//         console.warn("No account details found for the given defaultAccountId");
//       }
//     } catch (error) {
//       console.error("Error fetching account details:", error);
//     }
//   };

//   return (
//     <StyledFormContainer1>
//       <StyledFormSection1>
//         {!accountDetails?.autoAssignConsignmentNumber && (
//           <Grid item xs={6}>
//             <FormField
//               label="Consignment Number"
//               required
//               placeholder="Enter Consignment Number"
//               value={consignment.orderNumber}
//               onChange={(e) => handleFieldChange("orderNumber", e.target.value)}
//               error={!!validationErrors["orderNumber"]}
//               errorMessage={validationErrors["orderNumber"]}
//             />
//           </Grid>
//         )}
//         <Grid>
// <FormSelect
// label="Customer Name"
// label2="Add Consignee"
// onLabel2Click={handleConsigneesFormModalOpen}
// disabled={consignment.id !== undefined}
// value={consignment.customerId}
// required
// onChange={(selected) => {
// handleFieldChange("customerId", selected);
// }}
// options={customers
// .filter((item) => item.id) // Filter out items without an id
// .map((item) => ({
// value: item.id,
// label: item.name,
// item,
// }))}
// error={!!validationErrors.customerId}
// errorMessage={validationErrors.customerId}
// />
// </Grid>
//         {/* <Grid>
//           <FormSelect
//             label="Customer Name"
//             label2="Add Consignee"
//             onLabel2Click={handleConsigneesFormModalOpen}
//             disabled={consignment.id !== undefined}
//             value={consignment.customerId}
//             required
//             onChange={(selected) => {
//               handleFieldChange("customerId", selected);
//             }}
//             options={customers.map((item) => ({
//               value: item.id,
//               label: item.name,
//               item,
//             }))}
//             error={!!validationErrors.customerId}
//             errorMessage={validationErrors.customerId}
//           />
//         </Grid> */}
//         <StyledSectionHeader>
//           <Typography variant="heading">Pickup Address</Typography>
//         </StyledSectionHeader>
//         <Grid>
//           {((consignment?.id !== undefined && consignment?.accountId) ||
//             consignment?.id == undefined) && (
//             <FormAddress2
//               address={consignment?.pickupDetails?.address}
//               addressKey={parseInt("-1")}
//               handleFieldChange={(name, value) =>
//                 handleFieldChange("pickupDetails.address." + name, value)
//               }
//               validationErrors={validationErrors}
//             />
//           )}
//           <Grid container spacing={1}>
//             <Grid item xs={6}>
//               <FormField
//                 label="Pickup Person Name"
//                 required
//                 placeholder="Enter Pickup Person Name"
//                 value={consignment?.pickupDetails?.name}
//                 onChange={(e) =>
//                   handleFieldChange("pickupDetails.name", e.target.value)
//                 }
//                 error={!!validationErrors["pickupDetails.name"]}
//                 errorMessage={validationErrors["pickupDetails.name"]}
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <FormPhone
//                 label="Phone"
//                 value={
//                   consignment.pickupDetails.areaCode +
//                   " " +
//                   consignment.pickupDetails.phone
//                 }
//                 onChange={(newValue) =>
//                   handleFieldChange("pickupDetails.phone", newValue)
//                 }
//                 required
//                 error={!!validationErrors["pickupDetails.phone"]}
//                 errorMessage={validationErrors["pickupDetails.phone"]}
//               />
//             </Grid>
//           </Grid>
//         </Grid>
//       </StyledFormSection1>
//       <Grid>
//         <StyledFormSection1>
//           <StyledSectionHeader>
//             <Typography variant="heading">Delivery Address</Typography>
//           </StyledSectionHeader>
//           <Grid>
//             <FormSelect
//               label="Delivery Address"
//               value={consignment?.deliveryDetails?.addressId}
//               onChange={(selected) => {
//                 handleFieldChange("deliveryDetails.addressId", selected);
//               }}
//               options={
//                 selectedCustomer
//                   ? selectedCustomer?.address?.map((item) => ({
//                       value: item._id,
//                       label:
//                         item.geofenceId ||
//                         `${item.streetOne}, ${item.streetTwo}, ${item.city}, ${item.state}, ${item.country}, ${item.postalCode}`,
//                       item,
//                     }))
//                   : []
//               }
//               error={!!validationErrors["deliveryDetails.addressId"]}
//               errorMessage={validationErrors["deliveryDetails.addressId"]}
//             />
//             {((consignment?.id !== undefined && consignment?.accountId) ||
//               consignment?.id === undefined) && (
//               <FormAddress3
//                 address={consignment?.deliveryDetails?.address}
//                 addressKey={parseInt("-1")}
//                 handleFieldChange={(name, value) =>
//                   handleFieldChange("deliveryDetails.address." + name, value)
//                 }
//                 validationErrors={validationErrors}
//               />
//             )}
//             <Grid container spacing={1}>
//               <Grid item xs={6}>
//                 <FormField
//                   label="Delivery Person Name"
//                   required
//                   placeholder="Enter Delivery Person Name"
//                   value={consignment?.deliveryDetails?.name}
//                   onChange={(e) =>
//                     handleFieldChange("deliveryDetails.name", e.target.value)
//                   }
//                   error={!!validationErrors["deliveryDetails.name"]}
//                   errorMessage={validationErrors["deliveryDetails.name"]}
//                 />
//               </Grid>
//               <Grid item xs={6}>
//                 <FormPhone
//                   label="Phone"
//                   value={
//                     consignment?.deliveryDetails?.areaCode +
//                     " " +
//                     consignment?.deliveryDetails?.phone
//                   }
//                   onChange={(newValue) =>
//                     handleFieldChange("deliveryDetails.phone", newValue)
//                   }
//                   required
//                   error={!!validationErrors["deliveryDetails.phone"]}
//                   errorMessage={validationErrors["deliveryDetails.phone"]}
//                 />
//               </Grid>
//             </Grid>
//           </Grid>
//         </StyledFormSection1>
//       </Grid>

//       <ConsigneesFormModal
//         open={consigneesFormModal}
//         handleConsigneesFormModalClose={handleConsigneesFormModalClose}
//       />
//     </StyledFormContainer1>
//   );
// };

// ConsignmentsForm1.propTypes = {
//   consignment: PropTypes.object.isRequired,
//   onFormValueChange: PropTypes.func.isRequired,
//   validationErrors: PropTypes.object.isRequired,
//   accountDetails: PropTypes.object.isRequired,
// };

// export default ConsignmentsForm1;
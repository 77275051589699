/* eslint-disable no-unused-vars */
import { Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  StyledFormSection,
  StyledSectionLeft,
  StyledSectionRight,
  StyledSwitch2,
  StyledTable2,
  StyledTableBody2,
  StyledTableCell2,
  StyledTableHead2,
  StyledTableRow2,
  StyledUploadContainer,
  StyledUploadIconContainer,
  StyledUploadText,
} from "../../../../styledComponents.js";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { iconMap } from "../../../../constants/iconsList.js";
import { StyledFormContainer } from "../../../management/billingPlans/styles/BillingPlansStyles.js";
import { checkboxTypes, documentColumns } from "../../../../utils/constant.js";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import FormOptionSelector from "../../../common/FormOptionSelector.js";
import FormField from "../../../common/FormField.js";
import FormDatepicker from "../../../common/FormDatepicker.js";
import FileLink from "../../../common/FileLink.js";
import FormSelect from "../../../common/FormSelect.js";
dayjs.extend(utc);

const getDocumentTypeOptions = (isFront) => {
  return isFront ? ["Driver's License Front"] : ["Driver's License Back"];
};

const DriversForm2 = ({
  driver,
  onFormValueChange,
  validationErrors,
  setValidationErrors,
}) => {
  const today = dayjs();
  const [accounts, setAccounts] = React.useState([{ name: "", value: "" }]);
  const [roles, setRoles] = React.useState([]);
  const { id } = useParams();
  const userId = id;
  const [openLicenseDatePicker, setOpenLicenseDatePicker] = useState(false);
  const [selectedLicenceDate, setSelectedLicenceDate] = useState(
    driver?.licenseExpiryDate ? dayjs(driver.licenseExpiryDate) : today
  );
  let licenseFrontURL = "";
  let licenseBackURL = "";

  // Set default passport and license expiry date
  useEffect(() => {
    onFormValueChange({
      ...driver,
      licenseExpiryDate: today,
    });
  }, []);

  const onDrop = (documentType) => (files) => {
    // Check if there are any files to process
    if (files.length === 0) return;

    //   // Create a FileReader to read the file
    //   const reader = new FileReader();
    //   reader.onload = (event) => {

    //  if(documentType == "Driver's License Front") {
    //   licenseFrontURL = event.target.result;
    //  }

    //  else if(documentType == "Driver's License Back") {
    //   licenseBackURL = event.target.result;
    //  }
    // }

    // Create an object for the new file with the necessary properties
    const updatedFile = {
      fileURL: "",
      file: files[0], // Only allow one file to be uploaded
      isDelete: false,
      documentType,
      driverView: false,
      customerView: false,
    };

    // Filter out any existing documents of the same type and add the new file
    const newDocuments = [
      ...driver.documentsURL.filter((doc) => doc.documentType !== documentType),
      updatedFile,
    ];

    // Create a new driver object with the updated documents
    const updatedDriver = {
      ...driver,
      documentsURL: newDocuments,
    };

    // Call the onFormValueChange function with the updated driver object
    onFormValueChange(updatedDriver, "", "", 2);
  };


  // const ImagePreview = ({ url }) => {
  //   return (
  //   <div>
  //   {url && <img src={url} alt={url} style={{ width: '100px', height: '100px' }} />}
  //   </div>
  //   );
  //   };

  // Handle file removal by filtering out the deleted file
  const handleRemoveFile = (index) => {
    if (index >= 0 && index < driver.documentsURL.length) {
      driver.documentsURL.splice(index, 1);
    }
    onFormValueChange(driver, "", "", 3);
  };

  const handleLicenseDateChange = (date) => {
    const formattedDate = date
      ? dayjs(date).startOf("day").add(18, "hours").toISOString()
      : dayjs().startOf("day").add(18, "hours").toISOString();

    setSelectedLicenceDate(date || dayjs().startOf("day").add(18, "hours"));
    handleFieldChange("licenseExpiryDate", formattedDate);
  };

  // Function to handle user document typee
  // const handleDocumentTypeChange = (type, index, fieldName, value) => {
  //   const updatedDocuments = driver[type].map((doc, i) =>
  //     i === index ? { ...doc, [fieldName]: value } : doc
  //   );
  //   const newDrivers = { ...driver, [type]: updatedDocuments };
  //   // setDrivers(newDrivers);
  //   // onFormValueChange(newDrivers, "", "", 3);
  // };

  const handleDocumentsChange = (index, key, value) => {
    const updatedDocuments = [...driver.documentsURL];
    updatedDocuments[index] = {
      ...updatedDocuments[index],
      [key]: value,
    };
    const updatedDriver = {
      ...driver,
      documentsURL: updatedDocuments,
    };
    onFormValueChange(updatedDriver, name, value, 3);
  };


  // Assuming you have a setState function to update the driver state
  // setDriver({
  // ...driver,
  // documentsURL: updatedDocuments,
  // });
  // };

  // Switch for customer/driver  switch
  // const handleSwitchChange = (type, index, fieldName, value) => {
  //   const updatedDocuments = drivers[type].map((doc, i) =>
  //     i === index ? { ...doc, [fieldName]: value } : doc
  //   )
  //   const newDrivers = { ...drivers, [type]: updatedDocuments }
  //   setDrivers(newDrivers)
  //   onFormValueChange(newDrivers)
  // }

  //   const renderDocuments = (type, isFront) => {
  //     const documentTypeOptions = getDocumentTypeOptions(isFront);

  //     // Filter the documents based on the document type
  //     const filteredDocuments = driver?.documentsURL?.filter(
  //       (doc) => doc.documentType === documentTypeOptions[0]
  //     );
  //     // Only render the table if there are filtered documents
  //     if (filteredDocuments?.length === 0) {
  //       return null; // No documents to display
  //     }

  //     return (
  //       <StyledTable2>
  //         <StyledTableHead2>
  //           <StyledTableRow2>
  //             {documentColumns.map((column, index) => (
  //               <StyledTableCell2 key={index}>{column.label}</StyledTableCell2>
  //             ))}
  //           </StyledTableRow2>
  //         </StyledTableHead2>
  //         <StyledTableBody2>
  //         {filteredDocuments?.map((item) => {
  // const actualIndex = driver.documentsURL.findIndex(
  // (doc) => doc._id === item._id
  // );
  // return item.fileURL || (item.file && !item.isDelete) ? (
  //               <StyledTableRow2 key={index}>
  //                 <StyledTableCell2>
  //                   <FileLink
  //                     href={item.fileURL || item.file.name}
  //                     fileName={item.fileURL || item.file.name}
  //                   />
  //                 </StyledTableCell2>
  //                 <StyledTableCell2>
  //                   <FormSelect
  //                     sx={{
  //                       marginBottom: "0px",
  //                       marginTop: "-5px",
  //                       borderRadius: "16px",
  //                     }}
  //                     value={item.documentType || documentTypeOptions[0]}
  //                     // onChange={(e) =>
  //                     //   handleDocumentTypeChange(type, index, "documentType", e)
  //                     // }
  //                     options={documentTypeOptions.map((option) => ({
  //                       value: option,
  //                       label: option,
  //                     }))}
  //                   />
  //                 </StyledTableCell2>
  //                 <StyledTableCell2 align="center">
  //                   <StyledSwitch2
  //                     checked={item.driverView}
  //                     onChange={(e) => handleDocumentsChange(index, "driverView", e.target.checked)}
  //                     sx={{
  //                       "& .MuiSwitch-switchBase.Mui-checked": {
  //                         color: "#0772B8",
  //                       },
  //                       "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
  //                         {
  //                           backgroundColor: "#0772B8",
  //                         },
  //                     }}
  //                   />
  //                 </StyledTableCell2>
  //                 <StyledTableCell2 align="center">
  //                   <StyledSwitch2
  //                     checked={item.customerView}
  //                     onChange={(e) => handleDocumentsChange(index, "customerView", e.target.checked)}
  //                     sx={{
  //                       "& .MuiSwitch-switchBase.Mui-checked": {
  //                         color: "#0772B8",
  //                       },
  //                       "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
  //                         {
  //                           backgroundColor: "#0772B8",
  //                         },
  //                     }}
  //                   />
  //                 </StyledTableCell2>
  //                 <StyledTableCell2 align="center">
  //                   <IconButton
  //                     onClick={() => handleRemoveFile(type, index)}
  //                     sx={{ color: "red" }}
  //                   >
  //                     <img src={iconMap["DeleteIcon"]} alt="DeleteIcon" />
  //                   </IconButton>
  //                 </StyledTableCell2>
  //               </StyledTableRow2>
  //             ) : null
  //           )}
  //         </StyledTableBody2>
  //       </StyledTable2>
  //     );
  //   };

  const renderDocuments = (type, isFront) => {
    const documentTypeOptions = getDocumentTypeOptions(isFront);

    // Filter the documents based on the document type
    const filteredDocuments = driver?.documentsURL?.filter(
      (doc) => doc.documentType === documentTypeOptions[0]
    );

    // Only render the table if there are filtered documents
    if (filteredDocuments?.length === 0) {
      return null; // No documents to display
    }

    return (
      <StyledTable2>
        <StyledTableHead2>
          <StyledTableRow2>
            {documentColumns.map((column, index) => (
              <StyledTableCell2 key={index}>{column.label}</StyledTableCell2>
            ))}
          </StyledTableRow2>
        </StyledTableHead2>
        <StyledTableBody2>
          {filteredDocuments?.map((item) => {
            const actualIndex = driver.documentsURL.findIndex(
              (doc) => doc._id === item._id
            );

            return item.fileURL || (item.file && !item.isDelete) ? (
              <StyledTableRow2 key={actualIndex}>
                <StyledTableCell2>
                  <FileLink
                    href={item.fileURL || item.file.name}
                    fileName={item.fileURL || item.file.name}
                  />
                </StyledTableCell2>
                <StyledTableCell2>
                  <FormSelect
                    sx={{
                      marginBottom: "0px",
                      marginTop: "-5px",
                      borderRadius: "16px",
                    }}
                    value={item.documentType || documentTypeOptions[0]}
                    // onChange={(e) =>
                    //   handleDocumentTypeChange(type, actualIndex, "documentType", e)
                    // }
                    options={documentTypeOptions.map((option) => ({
                      value: option,
                      label: option,
                    }))}
                  />
                </StyledTableCell2>
                <StyledTableCell2 align="center">
                  <StyledSwitch2
                    checked={item.driverView}
                    onChange={(e) =>
                      handleDocumentsChange(actualIndex, "driverView", e.target.checked)
                    }
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#0772B8",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                        backgroundColor: "#0772B8",
                      },
                    }}
                  />
                </StyledTableCell2>
                <StyledTableCell2 align="center">
                  <StyledSwitch2
                    checked={item.customerView}
                    onChange={(e) =>
                      handleDocumentsChange(actualIndex, "customerView", e.target.checked)
                    }
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#0772B8",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                        backgroundColor: "#0772B8",
                      },
                    }}
                  />
                </StyledTableCell2>
                <StyledTableCell2 align="center">
                  <IconButton
                    onClick={() => handleRemoveFile(actualIndex)}
                    sx={{ color: "red" }}
                  >
                    <img src={iconMap["DeleteIcon"]} alt="DeleteIcon" />
                  </IconButton>
                </StyledTableCell2>
              </StyledTableRow2>
            ) : null;
          })}
        </StyledTableBody2>
      </StyledTable2>
    );
  };

  const {
    getRootProps: getRootPropsLicenseFront,
    getInputProps: getInputPropsLicenseFront,
  } = useDropzone({
    onDrop: onDrop("Driver's License Front"),
    accept: "image/*,.pdf,.doc,.xls,.xlsx,.ppt,.pptx",
    multiple: true,
  });

  const {
    getRootProps: getRootPropsLicenseBack,
    getInputProps: getInputPropsLicenseBack,
  } = useDropzone({
    onDrop: onDrop("Driver's License Back"),
    accept: "image/*,.pdf,.doc,.xls,.xlsx,.ppt,.pptx",
    multiple: true,
  });

  const handleFieldChange = async (name, value) => {
    try {
      let updatedDriver = { ...driver };
      if (name === "phone") {
        const phoneNumberParts = value.split(" ");
        updatedDriver.areaCode = phoneNumberParts[0]; // +255
        updatedDriver.phone = phoneNumberParts.slice(1).join(" "); // "21 233 44"
      }
      else {
        updatedDriver[name] = value;
      }
      onFormValueChange(updatedDriver, name, value, 3); // Pass step index here
    } catch (error) {
      console.error("Error in handleFieldChange:", error);
    }
  };

  return (
    <StyledFormContainer>
      <StyledFormSection>
        <StyledSectionLeft>
          <Typography variant="heading">License Details</Typography>
        </StyledSectionLeft>
        <StyledSectionRight>
          <FormField
            label="License Number"
            placeholder="Enter license number"
            value={driver.licenseNumber}
            onChange={(e) => handleFieldChange("licenseNumber", e.target.value)}
            error={!!validationErrors.licenseNumber}
            errorMessage={validationErrors.licenseNumber}
            required
          />
          <FormDatepicker
            label="License Expiry Date"
            value={selectedLicenceDate}
            onChange={handleLicenseDateChange}
            open={openLicenseDatePicker}
            onOpen={() => setOpenLicenseDatePicker(true)}
            onClose={() => setOpenLicenseDatePicker(false)}
            error={!!validationErrors.licenseExpiryDate}
            errorMessage={validationErrors.licenseExpiryDate}
          />
          <Grid>
            <Typography variant="label">Upload License Front</Typography>
            <Grid {...getRootPropsLicenseFront()}>
              <input {...getInputPropsLicenseFront()} multiple={false} type="file" />
              <StyledUploadContainer>
                <StyledUploadIconContainer>
                  <IconButton>
                    <img src={iconMap["DocUploadIcon"]} alt="DocUploadIcon" />
                  </IconButton>
                </StyledUploadIconContainer>
                <StyledUploadText>
                  Drag and Drop or Browse files
                </StyledUploadText>
              </StyledUploadContainer>
            </Grid>

            <div>
              {/* <ImagePreview url={licenseFrontURL}></ImagePreview> */}
              {renderDocuments("documentsURL", true)}{" "}
              {/* For Driver's License Front */}
            </div>
          </Grid>
          <Grid>
            <Typography variant="label" sx={{ marginTop: "10px" }}>
              Upload License Back
            </Typography>
            <Grid {...getRootPropsLicenseBack()}>
              <input {...getInputPropsLicenseBack()} multiple={false} type="file" />
              <StyledUploadContainer>
                <StyledUploadIconContainer>
                  <IconButton>
                    <img src={iconMap["DocUploadIcon"]} alt="DocUploadIcon" />
                  </IconButton>
                </StyledUploadIconContainer>
                <StyledUploadText>
                  Drag and Drop or Browse files
                </StyledUploadText>
              </StyledUploadContainer>
            </Grid>
            <div>
              {renderDocuments("documentsURL", false)}{" "}
              {/* For Driver's License Back */}
            </div>
          </Grid>
        </StyledSectionRight>
      </StyledFormSection>
    </StyledFormContainer>
  );
};

DriversForm2.propTypes = {
  driver: PropTypes.object.isRequired,
  onFormValueChange: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  setValidationErrors: PropTypes.func.isRequired,
};

export default DriversForm2;

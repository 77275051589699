/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { Button, Typography, Box, Grid, IconButton } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Title } from "../../../management/billingPlans/styles/BillingPlansStyles.js";
import {
  createConsignment,
  getConsignmentById,
  updateConsignment,
} from "../../../../services/consignmentNew.service.js";
import ErrorMessage1 from "../../../common/ErrorMessage1.js";
import SuccessMessage from "../../../common/SuccessMessage.js";
import { ConsignmentModel } from "../../../../model/consignment.model.js";
import ConsignmentsForm1 from "./ConsignmentsForm1.js";
import ConsignmentsForm2 from "./ConsignmentsForm2.js";
import ConsignmentsForm3 from "./ConsignmentsForm3.js";
import ConsignmentsForm4 from "./ConsignmentsForm4.js";
import {
  validateForm,
  validateForm2,
} from "../../../../utils/yupValidations.js";
import {
  consignmentSchemaStep,
  // consignmentSchemaStep1,
  // consignmentSchemaStep2,
  // consignmentSchemaStep3,
  // consignmentSchemaStep4,
} from "../../../../utils/validation-schemas/consignmentsSchema.js";
import dayjs from "dayjs";
import { NextButton } from "../../../../styledComponents.js";
import { getRoute } from "../../../../constants/routes.js";
import Loader from "../../../common/Loader.js";
import { iconMap } from "../../../../constants/iconsList.js";
import { paymentTerms } from "../../../../utils/constant.js";
// import { getAccountById } from "../../../../services/accountNew.service.js";

const steps = [
  "Consignment Details",
  "Cargo",
  "Insurance & Customs",
  "Additional",
];

const ConsignmentsForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [consignment, setConsignment] = useState(ConsignmentModel);
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [defaultsSet, setDefaultsSet] = useState(false);

  const getLocalStorageItem = (key, defaultValue) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  };

  const defaultAccountId = getLocalStorageItem("defaultAccountId", "");
  const accountDetails = getLocalStorageItem("accountDetails", "");

  const dataFromNavigation = location.state?.data;

  useEffect(() => {
    if (dataFromNavigation) {
      setConsignment(dataFromNavigation);
      setDefaultsSet(true); // Set defaultsSet to true when data is received
    } else if (id) {
      _getConsignmentById(id); // Fetch existing consignment if editing
    } else {
      setDefaults(); // Set defaults for new consignment
    }
  }, [dataFromNavigation, id]);

  const _getConsignmentById = async (id) => {
    setIsLoading(true);
    try {
      const data = await getConsignmentById(id);
      setConsignment(data);
      handleResponse(data, "Consignment fetched successfully!", "Get");
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // const _fetchCustomersByMultipleCriteria = async (id = "", page = 1, limit = 15) => {
  //   if (isLoading) return;
  //   // setIsLoadingCustomers(true);
  //   try {
  //     let allCustomers = [];
  //     let currentPage = page;
  //     let totalFetched = 0;
  //     let response;
  //     do {
  //       response = await fetchCustomersByMultipleCriteria(id, currentPage, limit);
  //       allCustomers = [...allCustomers, ...response.rows];
  //       totalFetched += response.rows.length;
  //       currentPage++;
  //     } while (totalFetched < response.count);

  //     setCustomers(allCustomers);
  //   } catch (error) {
  //     console.error("Error fetching customers:", error?.message || "An unknown error occurred");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const getCurrentUTCDate = () =>
    dayjs().utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
  const getCurrentUTCTime = () => dayjs().utc().format("HH:mm:ss[Z]");

  const setDefaults = () => {
    const defaultUTCDate = getCurrentUTCDate();
    const defaultUTCTime = getCurrentUTCTime();
    const defaultUTCTimePlus4 = dayjs(defaultUTCTime, "HH:mm:ss[Z]")
      .add(4, "hour")
      .format("HH:mm:ss[Z]");

    const updatedConsignment = {
      ...consignment,
      accountId: defaultAccountId,
      billingInformation: {
        ...consignment.billingInformation,
        VAT: accountDetails.taxRate,
        currency: accountDetails.currency,
        paymentTerms: 1
      },
      tripStatus: "RECEIVED",
      receivedDateTime: defaultUTCDate,
      deliverySchedule: {
        ...consignment.deliverySchedule,
        expectedPickupDate: null,
        expectedDeliveryDate: null,
        pickupTime: {
          ...consignment.deliverySchedule?.pickupTime,
          from: null,
          to: null,
        },
        deliveryTime: {
          ...consignment.deliverySchedule?.deliveryTime,
          from: null,
          to: null,
        },
      },
      palletDetails: {
        ...consignment.palletDetails,
        expectedReturnDate: defaultUTCDate,
      },
      pickupDetails: {
        ...consignment.pickupDetails,
        areaCode: accountDetails.countryCode,
      },
      deliveryDetails: {
        ...consignment.deliveryDetails,
        areaCode: accountDetails.countryCode,
      },
    };
    setConsignment(updatedConsignment);
    setDefaultsSet(true);
  };

  const handleValueChange = useCallback(
    async (updatedConsignment, fieldName, fieldValue, step) => {
      setConsignment((prevConsignment) => ({
        ...prevConsignment,
        ...updatedConsignment,
      }));
      const schema = consignmentSchemaStep;
      const context = {
        autoAssignConsignmentNumber: Array.isArray(
          accountDetails?.autoAssignConsignmentNumber
        )
          ? accountDetails.autoAssignConsignmentNumber[0]
          : accountDetails?.autoAssignConsignmentNumber,
        ...updatedConsignment,
      };

      const validateRelatedFields = async (fields) => {
        for (const field of fields) {
          await validateForm2(
            updatedConsignment,
            field,
            setValidationErrors,
            schema,
            context
          );
        }
      };
      try {
        switch (fieldName) {
          case "deliverySchedule.expectedPickupDate":
          case "deliverySchedule.pickupTime.to":
          case "deliverySchedule.pickupTime.from":
            await validateRelatedFields([
              "deliverySchedule.pickupTime.from",
              "deliverySchedule.pickupTime.to",
              "deliverySchedule.expectedPickupDate",
              "deliverySchedule.expectedDeliveryDate",
            ]);
            break;
          case "deliverySchedule.expectedDeliveryDate":
          case "deliverySchedule.deliveryTime.from":
          case "deliverySchedule.deliveryTime.to":
            await validateRelatedFields([
              "deliverySchedule.deliveryTime.from",
              "deliverySchedule.deliveryTime.to",
              "deliverySchedule.expectedDeliveryDate",
              "deliverySchedule.expectedPickupDate",
            ]);
            break;
          default:
            await validateForm2(
              updatedConsignment,
              fieldName,
              setValidationErrors,
              schema,
              context
            );
            break;
        }
      } catch (error) {
        console.error("Field validation error:", error);
      }
    },
    [accountDetails, consignment]
  );

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const schema = consignmentSchemaStep;
      const context = {
        autoAssignConsignmentNumber: Array.isArray(
          accountDetails?.autoAssignConsignmentNumber
        )
          ? accountDetails?.autoAssignConsignmentNumber[0]
          : accountDetails?.autoAssignConsignmentNumber,
        ...consignment,
      };
      const isValid = await validateForm(
        consignment,
        setValidationErrors,
        schema,
        context
      );
      if (!isValid) {
        console.error("Form validation failed");
        setIsLoading(false);
        return;
      }
      let response;
      if (id === undefined) {
        response = await createConsignment(consignment);
        clearSelectedCustomer();
        handleResponse(response, "Consignment created successfully!", "Card");
      } else {
        response = await updateConsignment(consignment);
        clearSelectedCustomer();
        handleResponse(response, "Consignment updated successfully!", "Card");
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const clearSelectedCustomer = () => {
    localStorage.removeItem("selectedCustomer");
  };

  const appendFormData = (formData, data, parentKey = "") => {
    if (data && typeof data === "object" && !(data instanceof File)) {
      Object.keys(data).forEach((key) => {
        appendFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      let value = data;
      if (typeof data === "number") {
        value = `${data}#number`;
      } else if (typeof data === "boolean") {
        value = `${data}#boolean`;
      }
      formData.append(parentKey, value);
    }
  };

  const handleResponse = (response, successMessage, mode) => {
    window.scrollTo(0, 0);
    if (response) {
      setSuccessMessage(successMessage);
      setTimeout(() => {
        if (mode === "Card") {
          navigate(getRoute("Consignment", mode), {
            state: { response }, // Only pass the response
          });
        }
      }, 3000); // Closing parentheses and braces for setTimeout
    }
  };

  const handleError = (error) => {
    window.scrollTo(0, 0);
    if (error.name === "ValidationError") {
      handleValidationErrors(error);
    } else {
      setErrors(error.error || "An unexpected error occurred.");
    }
  };

  const handleValidationErrors = (error) => {
    const errors = error?.inner?.reduce((acc, err) => {
      acc[err.path] = err.message;
      return acc;
    }, {});
    setValidationErrors(errors);
  };

  const getStepContent = (stepIndex) => {
    if (!defaultsSet && id === undefined) {
      return <Typography>Loading...</Typography>;
    }
    switch (stepIndex) {
      case 0:
        return (
          <ConsignmentsForm1
            consignment={consignment}
            onFormValueChange={handleValueChange}
            validationErrors={validationErrors}
            accountDetails={accountDetails}
          />
        );
      case 1:
        return (
          <ConsignmentsForm2
            consignment={consignment}
            onFormValueChange={handleValueChange}
            validationErrors={validationErrors}
          />
        );
      case 2:
        return (
          <ConsignmentsForm3
            consignment={consignment}
            onFormValueChange={handleValueChange}
            validationErrors={validationErrors}
          />
        );
      case 3:
        return (
          <ConsignmentsForm4
            consignment={consignment}
            onFormValueChange={handleValueChange}
            validationErrors={validationErrors}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  };

  const handleClose = () => {
    setErrors([]);
    setSuccessMessage("");
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Grid>
      <ErrorMessage1 errors={errors} onClose={handleClose} />
      <SuccessMessage message={successMessage} onClose={handleClose} />
      {isLoading && <Loader />}
      <Grid sx={{ width: "100%", padding: "Calc(2.3%-50px) Calc(10.8%-50px)" }}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #d8d8df",
            paddingBottom: "26.5px",
          }}
        >
          <Grid item xs={12} md={5}>
            <Title>
              <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
                <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
              </IconButton>
              {id ? "Update" : "Create"} Consignment
            </Title>
          </Grid>
        </Grid>
        <Grid sx={{ marginTop: 3, marginBottom: 3 }}>
          <Grid>
            {getStepContent(0)}
         {getStepContent(1)}
               {getStepContent(2)}
         {/*    {getStepContent(3)} */}
            <Grid
              item
              xs={12}
              sx={{
                mt: 3,
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
              }}
            >
              <NextButton onClick={handleSubmit}>FINISH</NextButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsignmentsForm;

// const ConsignmentsForm = () => {
//   const { id } = useParams();
//   const [errors, setErrors] = useState([]);
//   const [successMessage, setSuccessMessage] = useState("");
//   const [activeStep, setActiveStep] = useState(0);
//   const [consignment, setConsignment] = useState(ConsignmentModel);
//   const navigate = useNavigate();
//   const [validationErrors, setValidationErrors] = useState({});
//   const [isLoading, setIsLoading] = useState(false);
//   const [defaultsSet, setDefaultsSet] = useState(false);
//   // const [accountDetails, setAccountDetails] = useState()
//   const defaultAccountId =
//     localStorage.getItem("defaultAccountId") !== ""
//       ? JSON.parse(localStorage.getItem("defaultAccountId"))
//       : "";
//   const accountDetails = localStorage.getItem("accountDetails") !== ""
//     ? JSON.parse(localStorage.getItem("accountDetails"))
//     : "";

//   useEffect(() => {
//     if (id) {
//       _getConsignmentById(id);
//     } else {
//       setDefaults();
//     }
//   }, [id]);

//   //  const _getAccountByAccountId = async () => {
//   //     try {
//   //       const defaultAccountId =
//   //         localStorage.getItem("defaultAccountId") !== ""
//   //           ? JSON.parse(localStorage.getItem("defaultAccountId"))
//   //           : "";
//   //       const res = await getAccountById(defaultAccountId)
//   //       setAccountDetails(res)
//   //     } catch (error) {
//   //       console.error('Error fetching account details:', error)
//   //       // Handle the error, such as displaying an error message to the user
//   //     }
//   //   }

//   const _getConsignmentById = async (id) => {
//     setIsLoading(true);
//     try {
//       const data = await getConsignmentById(id);
//       setConsignment(data);
//       handleResponse(data, "Consignment fetched successfully!", "Get");
//     } catch (error) {
//       handleError(error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const getCurrentUTCDate = () =>
//     dayjs().utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
//   const getCurrentUTCTime = () => dayjs().utc().format("HH:mm:ss[Z]");

//   const setDefaults = () => {
//     const defaultUTCDate = getCurrentUTCDate();
//     const defaultUTCTime = getCurrentUTCTime();
//     const defaultUTCTimePlus4 = dayjs(defaultUTCTime, "HH:mm:ss[Z]")
//       .add(4, "hour")
//       .format("HH:mm:ss[Z]");

//     const updatedConsignment = {
//       ...consignment,
//       accountId: defaultAccountId,
//       tripStatus: "RECEIVED",
//       receivedDateTime: defaultUTCDate,
//       deliverySchedule: {
//         ...consignment.deliverySchedule,
//         expectedPickupDate: null,
//         expectedDeliveryDate: null,
//         pickupTime: {
//           ...consignment.deliverySchedule?.pickupTime,
//           from: null,
//           to: null,
//         },
//         deliveryTime: {
//           ...consignment.deliverySchedule?.deliveryTime,
//           from: null,
//           to: null,
//         },
//       },
//       palletDetails: {
//         ...consignment.palletDetails,
//         expectedReturnDate: defaultUTCDate,
//       },
//     };
//     setConsignment(updatedConsignment);
//     setDefaultsSet(true);
//   };

//   const handleValueChange = async (
//     updatedConsignment,
//     fieldName,
//     fieldValue,
//     step
//   ) => {
//     setConsignment((prevConsignment) => ({
//       ...prevConsignment,
//       ...updatedConsignment,
//     }));
//     const schema = consignmentSchemaStep;
//     const context = {
//       autoAssignConsignmentNumber: Array.isArray(accountDetails?.autoAssignConsignmentNumber)
//         ? accountDetails.autoAssignConsignmentNumber[0]
//         : accountDetails?.autoAssignConsignmentNumber,
//       ...updatedConsignment,
//     };

//     const validateRelatedFields = async (fields) => {
//       for (const field of fields) {
//         await validateForm2(
//           updatedConsignment,
//           field,
//           setValidationErrors,
//           schema,
//           context
//         );
//       }
//     };
//     try {
//       switch (fieldName) {
//         case "deliverySchedule.expectedPickupDate":
//         case "deliverySchedule.pickupTime.to":
//         case "deliverySchedule.pickupTime.from":
//           await validateRelatedFields([
//             "deliverySchedule.pickupTime.from",
//             "deliverySchedule.pickupTime.to",
//             "deliverySchedule.expectedPickupDate",
//             "deliverySchedule.expectedDeliveryDate",
//           ]);
//           break;
//         case "deliverySchedule.expectedDeliveryDate":
//         case "deliverySchedule.deliveryTime.from":
//         case "deliverySchedule.deliveryTime.to":
//           await validateRelatedFields([
//             "deliverySchedule.deliveryTime.from",
//             "deliverySchedule.deliveryTime.to",
//             "deliverySchedule.expectedDeliveryDate",
//             "deliverySchedule.expectedPickupDate",
//           ]);
//           break;
//         default:
//           await validateForm2(
//             updatedConsignment,
//             fieldName,
//             setValidationErrors,
//             schema,
//             context
//           );
//           break;
//       }
//     } catch (error) {
//       console.error("Field validation error:", error);
//     }
//   };

//   const handleSubmit = async () => {
//     setIsLoading(true);
//     try {
//       const schema = consignmentSchemaStep;
//       const context = {
//         autoAssignConsignmentNumber: Array.isArray(accountDetails?.autoAssignConsignmentNumber)
//           ? accountDetails.autoAssignConsignmentNumber[0]
//           : accountDetails?.autoAssignConsignmentNumber,
//         ...consignment,
//       };
//       const isValid = await validateForm(
//         consignment,
//         setValidationErrors,
//         schema,
//         context
//       );
//       if (!isValid) {
//         console.error("Form validation failed");
//         setIsLoading(false);
//         return;
//       }
//       const formData = new FormData();
//       appendFormData(formData, consignment);
//       let response;
//       if (id === undefined) {
//         response = await createConsignment(formData);
//         clearSelectedCustomer();
//         handleResponse(response, "Consignment created successfully!", "Card");
//       } else {
//         response = await updateConsignment(formData);
//         clearSelectedCustomer();
//         handleResponse(response, "Consignment updated successfully!", "Card");
//       }
//     } catch (error) {
//       handleError(error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const clearSelectedCustomer = () => {
//     localStorage.removeItem("selectedCustomer");
//   };

//   const appendFormData = (formData, data, parentKey = "") => {
//     if (data && typeof data === "object" && !(data instanceof File)) {
//       Object.keys(data).forEach((key) => {
//         appendFormData(
//           formData,
//           data[key],
//           parentKey ? `${parentKey}[${key}]` : key
//         );
//       });
//     } else {
//       let value = data;
//       if (typeof data === "number") {
//         value = `${data}#number`;
//       } else if (typeof data === "boolean") {
//         value = `${data}#boolean`;
//       }
//       formData.append(parentKey, value);
//     }
//   };

//   const handleResponse = (response, successMessage, mode) => {
//     window.scrollTo(0, 0);
//     if (response) {
//       setSuccessMessage(successMessage);
//       setTimeout(() => {
//         if (mode === "Card") {
//           navigate(getRoute("Consignment", mode), {
//             state: { response }, // Only pass the response
//           });
//         }
//       }, 3000); // Closing parentheses and braces for setTimeout
//     }
//   };

//   const handleError = (error) => {
//     window.scrollTo(0, 0);
//     if (error.name === "ValidationError") {
//       handleValidationErrors(error);
//     } else {
//       setErrors(error.error);
//     }
//   };

//   const handleValidationErrors = (error) => {
//     const errors = error?.inner?.reduce((acc, err) => {
//       acc[err.path] = err.message;
//       return acc;
//     }, {});
//     setValidationErrors(errors);
//   };

//   const getStepContent = (stepIndex) => {
//     if (!defaultsSet && id === undefined) {
//       return <Typography>Loading...</Typography>;
//     }
//     switch (stepIndex) {
//       case 0:
//         return (
//           <ConsignmentsForm1
//             consignment={consignment}
//             onFormValueChange={handleValueChange}
//             validationErrors={validationErrors}
//             accountDetails={accountDetails}
//           />
//         );
//       case 1:
//         return (
//           <ConsignmentsForm2
//             consignment={consignment}
//             onFormValueChange={handleValueChange}
//             validationErrors={validationErrors}
//           />
//         );
//       case 2:
//         return (
//           <ConsignmentsForm3
//             consignment={consignment}
//             onFormValueChange={handleValueChange}
//             validationErrors={validationErrors}
//           />
//         );
//       case 3:
//         return (
//           <ConsignmentsForm4
//             consignment={consignment}
//             onFormValueChange={handleValueChange}
//             validationErrors={validationErrors}
//           />
//         );
//       default:
//         return "Unknown stepIndex";
//     }
//   };

//   const handleClose = () => {
//     setErrors([]);
//     setSuccessMessage("");
//   };

//   const handleBackClick = () => {
//     navigate(-1);
//   };

//   return (
//     <Box>
//       <ErrorMessage1 errors={errors} onClose={handleClose} />
//       <SuccessMessage message={successMessage} onClose={handleClose} />
//       {isLoading && <Loader />}
//       <Box sx={{ width: "100%", padding: "Calc(2.3%-50px) Calc(10.8%-50px)" }}>
//         <Grid
//           container
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             borderBottom: "1px solid #d8d8df",
//             paddingBottom: "26.5px",
//           }}
//         >
//           <Grid xs={12} md={5} item>
//             <Title>
//               {" "}
//               <IconButton onClick={handleBackClick} sx={{ mr: 2 }}>
//                 <img src={iconMap["LeftArrow"]} alt="LeftArrow" />
//               </IconButton>
//               {id ? "Update" : "Create"} Consignment
//             </Title>
//           </Grid>
//         </Grid>
//         <Box sx={{ marginTop: 3, marginBottom: 3 }}>
//           <Box>
//             {getStepContent(0)}
//             {getStepContent(1)}
//             {getStepContent(2)}
//             {getStepContent(3)}
//             <Grid
//               xs={12}
//               sx={{
//                 mt: 3,
//                 display: "flex",
//                 flexDirection: "row",
//                 justifyContent: "end",
//               }}
//             >

//               <NextButton
//                 onClick={
//                   handleSubmit
//                 }
//               >
//                 FINISH
//               </NextButton>
//               {/* <BackButton disabled={activeStep === 0} onClick={handleBack}>
//                   Back
//                 </BackButton>
//                 <NextButton
//                   onClick={
//                     activeStep === steps.length - 1 ? handleSubmit : handleNext
//                   }
//                 >
//                   {activeStep === steps.length - 1
//                     ? "FINISH"
//                     : "SAVE & CONTINUE"}
//                 </NextButton> */}
//             </Grid>
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default ConsignmentsForm;

/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useCallback, useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PropTypes from "prop-types";
import { Typography, Grid, Modal, Button } from "@mui/material";
import {
  StyledModal,
  StyledModalContent,
  StyledModalHr,
  StyledCancelButton,
  StyledAllocateButton,
  FlexContainer,
  StyledModalListCard,
  StyledImage24,
  ModalButton,
} from "../../styledComponents";
import SearchField from "../common/SearchField";
import { iconMap } from "../../constants/iconsList";
import {
  fetchUsersByMultipleCriteria,
  fetchUsersByRoleNameAndAccountId,
} from "../../services/userNew.service";
import SearchFormSelect from "../common/SearchFormSelect";

const DriverAllocationModal = ({
  driverOpen,
  handleDriverAllocationModalClose,
  allocateDriver,
  handleDriverAllocationModalBack,
}) => {
  const [drivers, setDrivers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(1);
  const [limit] = useState(100);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [filters, setFilters] = useState({
    id: "",
    userName: "",
  });

  const defaultAccountId =
    localStorage.getItem("defaultAccountId") !== ""
      ? JSON.parse(localStorage.getItem("defaultAccountId"))
      : "";

  // Handle scroll event
  const handleScroll = useCallback(
    (e) => {
      if (page * limit <= count) {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (
          scrollTop + clientHeight >= scrollHeight &&
          !isLoading &&
          !isEmpty
        ) {
          setPage((prevPage) => prevPage + 1); // Load the next page
        }
      }
    },
    [isLoading, isEmpty]
  );

  useEffect(() => {
    if (driverOpen) {
      _fetchUsersByMultipleCriteria(
        filters.accountId,
        filters.id,
        filters.userName,
        "Driver",
        page,
        limit
      );
    }
  }, [driverOpen, page, filters]);

  const _fetchUsersByMultipleCriteria = async (
    accountId = defaultAccountId,
    id,
    userName,
    roleName
  ) => {
    if (isLoading) return; // Prevent multiple calls
    setIsLoading(true);

    try {
      let allDrivers = [];
      let currentPage = 1;
      let totalFetched = 0;

      let data;
      do {
        data = await fetchUsersByMultipleCriteria(
          accountId,
          id,
          userName,
          roleName,
          currentPage,
          limit
        );
        allDrivers = [...allDrivers, ...data.rows];
        totalFetched += data.rows.length;
        currentPage++; // Move to the next page
      } while (totalFetched < data.count);

      setDrivers(allDrivers);
    } catch (error) {
      const errorMessage = error?.message || "An unknown error occurred";
      console.error("Error fetching drivers:", errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = async (value) => {
    console.log();
    setFilters({ ...filters, id: value });
    setDrivers([]);
  };

  return (
    <Modal
      open={driverOpen}
      onClose={handleDriverAllocationModalClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <StyledModal>
        <Typography variant="modalTitle" id="child-modal-title">
          Allocate Driver
        </Typography>
        <Typography variant="modalSubTitle" id="child-modal-subtitle">
          Allocate a staff member to the selected consignment.
        </Typography>
        <Grid item xs={12} sm={2.5} md={12}>
          <SearchFormSelect
            placeholder="Search Driver"
            value={filters.id || ""}
            onChange={(e) => handleSearchChange(e)}
            options={drivers.map((item) => ({
              value: item.id,
              label: item.firstName + " " + item.lastName,
            }))}
          />
        </Grid>
        {/* <SearchField name="Driver" onSearchChange={handleSearchChange} /> */}
        {errorMessage && <p>{errorMessage}</p>}
        <StyledModalContent
          onScroll={handleScroll}
          style={{ maxHeight: "200px", overflowX: "auto" }}
        >
          {drivers.map((driver) => (
            <StyledModalListCard key={driver}>
              <FlexContainer item>
                <Grid>
                  <StyledImage24 src={iconMap["DeliveryTruck2"]} alt="icon" />
                </Grid>
                <Grid>{driver.userName}</Grid>
              </FlexContainer>
              <Grid item>
                <StyledAllocateButton
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => allocateDriver(driver)}
                >
                  ALLOCATE
                </StyledAllocateButton>
              </Grid>
            </StyledModalListCard>
          ))}
        </StyledModalContent>
        <StyledModalHr />
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <ModalButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleDriverAllocationModalBack}
            >
              BACK
            </ModalButton>
          </Grid>
          <Grid item>
            <StyledCancelButton
              fullWidth
              onClick={handleDriverAllocationModalClose}
            >
              CANCEL
            </StyledCancelButton>
          </Grid>
        </Grid>
      </StyledModal>
    </Modal>
  );
};

DriverAllocationModal.propTypes = {
  driverOpen: PropTypes.bool.isRequired,
  handleDriverAllocationModalClose: PropTypes.func.isRequired,
  selectedAccount: PropTypes.object,
  allocateDriver: PropTypes.func.isRequired,
  handleDriverAllocationModalBack: PropTypes.func.isRequired,
};

export default DriverAllocationModal;

import React, { useEffect, useState } from "react";
import { Country } from "country-state-city";
import {
  StyledInputControlContainer,
  StyledSelect3,
} from "../../styledComponents";
import { MenuItem, Typography } from "@mui/material";
import PropTypes from "prop-types";
import FormError from "./FormError";

const FormCurrencySelect = ({
  label,
  value, // This should be the selected currency
  onChange,
  error,
  errorMessage,
  required = false,
}) => {
  const [enhancedCountries, setEnhancedCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    const fetchCountries = async () => {
      const allCountries = Country.getAllCountries();
      const enhancedData = allCountries.map((country) => {
        const countryDetails = Country.getCountryByCode(country.isoCode);
        return {
          ...country,
          ...countryDetails,
        };
      });
      setEnhancedCountries(enhancedData);

      // Set default country
    };

    fetchCountries();
  }, [onChange]);

  useEffect(() => {
    // Update selected country based on the passed value (currency)
    if (value) {
      const country = enhancedCountries.find((c) => c.currency === value);
      setSelectedCountry(country);
    }
  }, [value, enhancedCountries]);

  const handleCurrencyChange = (isoCode) => {
    const country = enhancedCountries.find((c) => c.isoCode === isoCode);
    setSelectedCountry(country);
    onChange(country?.currency); // Pass the selected currency back to the parent
  };

  return (
    <StyledInputControlContainer>
      <Typography variant="label">
        {label}
        {required && " *"}
      </Typography>
      <StyledSelect3
        variant="standard"
        disableUnderline
        value={selectedCountry?.isoCode || ""}
        onChange={(e) => handleCurrencyChange(e.target.value)}
      >
        {enhancedCountries.map((country) => (
          <MenuItem key={country.isoCode} value={country.isoCode}>
            <Typography
              sx={{
                color: "5B5B6E",
                fontSize: "16px",
                fontWeight: "500",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {country.currency}
            </Typography>
          </MenuItem>
        ))}
      </StyledSelect3>
      {error && <FormError errorMessage={errorMessage} />}
    </StyledInputControlContainer>
  );
};

FormCurrencySelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired, // Ensure this is a string for currency
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
};

export default FormCurrencySelect;
